import * as nearAPI from "near-api-js";
const axios = require("axios")
import { ref } from "vue";
const { keyStores, connect, WalletConnection } = nearAPI;
const { utils } = nearAPI;
import PinataService from "./pinata";
import CertificatesApi from "./certificates-api"
import moment from 'moment'
import { createLogger } from "vuex";

let ADMIN_ID;
let NFT_CONTRACT_ID;
let MARKET_CONTRACT_ID
let LICENSE_CONTRACT_ID
let NET;

let GRAPH_URL

let BACKEND_URL




let IPFS_API_KEY
let IPFS_API_SECRET
let IPFS_GATEWAY

let near, wallet, account, contract

class vArtNearApi {

    initContract(adminId, nftContractId, marketContractId, licenseContractId, net, backendUrl) {
        ADMIN_ID = adminId
        NFT_CONTRACT_ID = nftContractId
        MARKET_CONTRACT_ID = marketContractId
        LICENSE_CONTRACT_ID = licenseContractId
        NET = net
        BACKEND_URL = backendUrl

        const config = {
            networkId: net,
            keyStore: new keyStores.BrowserLocalStorageKeyStore(),
            nodeUrl: "https://rpc." + NET + ".near.org",
            walletUrl: "https://wallet." + NET + ".near.org",
            helperUrl: "https://helper." + NET + ".near.org",
            explorerUrl: "https://explorer." + NET + ".near.org",
        }
        connect(config).then(
            near => {
                wallet = new WalletConnection(near)
            }
        )
    }

    initGraph(graphUrl) {
        GRAPH_URL = graphUrl
    }

    initIpfs(apiKey, keySecret, gateway) {
        IPFS_API_KEY = apiKey
        IPFS_API_SECRET = keySecret
        IPFS_GATEWAY = gateway
        PinataService.init(IPFS_API_KEY, IPFS_API_SECRET)
    }

    loadNftContract() {
        const walletAccountObj = wallet.account();
        return new nearAPI.Contract(
            walletAccountObj,
            NFT_CONTRACT_ID, {
            viewMethods: ["get_nft_token", "nft_payout", "nft_metadata", "get_minters",
                "nft_tokens_for_minter", "nft_total_supply", "nft_tokens_for_owner",],
            changeMethods: ["update_minters", "nft_mint", "nft_approve", "nft_burn", "nft_transfer",
                "nft_approve_initial"],
            sender: walletAccountObj,
        }
        );
    }

    loadMarketContract() {
        const walletAccountObj = wallet.account();
        return new nearAPI.Contract(
            walletAccountObj,
            MARKET_CONTRACT_ID, {
            viewMethods: ["get_nft_price", "get_nfts",
                "license_sales_supply_by_token_id", "license_sales_by_token_id", "license_sales_supply",
                "license_sales",],
            changeMethods: ["buy", "remove_from_market", "claim_nft", "withdraw_funds",
                "create_license_sale", "update_license_sale", "remove_license_sale", "license_buy"],
            sender: walletAccountObj,
        }
        );
    }

    loadLicenseContract() {
        const walletAccountObj = wallet.account();
        return new nearAPI.Contract(walletAccountObj, LICENSE_CONTRACT_ID, {
            viewMethods: [
                'nft_licenses_for_token'
            ],

        });
    }

    signOut() {
        wallet.signOut();
    }

    signIn(accountId) {
        this.signOut()
        wallet.requestSignIn(
            ADMIN_ID,
        );
    }

    getAccount() {
        if (wallet) {
            return wallet.getAccountId();
        } else {
            return null
        }

    }

    //  ----------- README ----------- //
    async nft_metadata() {
        let nftContract
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            nftContract = this.loadNftContract()
        } catch (err) {
            txReject(err)
        }
        await nftContract.nft_metadata()
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }

    async fullMint(
        tokenId,
        media,
        preview,
        title,
        medium,
        genre,
        bio,
        description,
        artist,
        createdBy,
        year,
        price,
        artists,
        royalties,
        quantity,
        edition,
        copies,
        deposit,
        gas
    ) {

        let previewBlob = await this.createBlob(preview)
        let mediaBlob = await this.createBlob(media)

        let previewUrl, previewHash
        let mediaUrl, mediaHash

        await PinataService.pinFile(previewBlob, 'PREVIEW_' + tokenId)
            .then(resp => {
                previewUrl = IPFS_GATEWAY + resp.ipfsHash
                previewHash = resp.hexB64
            })

        await PinataService.pinFile(mediaBlob, 'MEDIA_' + tokenId)
            .then(resp => {
                mediaUrl = IPFS_GATEWAY + resp.ipfsHash
                mediaHash = resp.hexB64
            })

        const date = new Date()
        const strDate = date.toISOString().slice(0, 10) + ' 00:00:00.000'

        let pinnedCertificate, certificateUrl, certificateHash, certificate;

        try {

            certificate = await CertificatesApi.createCertificate(
                tokenId,
                previewBlob,
                title,
                createdBy,
                year,
                edition,
                quantity,
                mediaUrl,
                'near',
                NFT_CONTRACT_ID,
                genre,
                'x',
                tokenId,
                [
                    "adaption",
                    "storage",
                    "placement",
                    "publication",
                    "metadata",
                    "demonstration",
                    "personal_use",
                    "advertising",
                ],
                moment(new Date()).format("DD/MM/YYYY HH:mm:ss"),
            )

            const certificateBlob = await CertificatesApi.downloadCertificate(
                certificate.certificate_download_url.split("/").at(-1)
            );

            const fixedBlob = await this.fixPdf(certificateBlob.data);

            await PinataService.pinFile(fixedBlob, "CERT " + title).then(
                (resp) => {
                    certificateUrl =
                        IPFS_GATEWAY + resp.ipfsHash;
                    certificateHash = resp.hexB64;
                }
            );

        }catch (e) {
            console.log("Impossible to create certificate", e)
         }

        const referenceData = {
            name: tokenId + ".json",
            product: {
                id: tokenId,
                picture: title + medium,
                name: title,
                medium: medium,
                genre: genre,
                bio: bio,
                description: description,
                artist: artist,
                createdBy: createdBy,
                ownedBy: null,
                year: year,
                price: price,
                blockchain: "Near",
                artistsWallets: artists,
                royaltiesWallets: royalties,
                quantiy: quantity,
                edition: edition,
                media_preview: previewUrl,
                media_preview_hash: previewHash,
                media: mediaUrl,
                media_hash: mediaHash,
                metadata: null,
                metadata_hash: null,
                certificate: certificateUrl,
                certificate_hash: certificateHash,
                art_size: null
            },
            certificate: certificate
        }


        let referenceUrl, referenceHash
        await PinataService.pinJson(referenceData, "METADATA_" + "title")
        await PinataService.pinJson(referenceData, "METADATA_" + title)
            .then(resp => {
                referenceUrl = IPFS_GATEWAY + resp.ipfsHash
                referenceHash = resp.hex864
            })

        let repackRoyalties = {}
        for (var k in royalties) {
            repackRoyalties[royalties[k].id] = royalties[k].value
        }
        let repackArtists = {}
        for (var j in artists) {
            repackArtists[artists[j].id] = artists[j].value
        }

        let nftContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })

        let tokenMetadata = {
            "title": title,
            "description": description,
            "media": mediaUrl,
            "media_hash": mediaHash,
            "copies": copies,
            "issued_at": strDate,
            "expires_at": strDate,
            "starts_at": strDate,
            "updated_at": strDate,
            "reference": referenceUrl,
            "reference_hash": referenceHash
        }


        try {
            nftContract = this.loadNftContract()
            accId = nftContract.account.accountId
        } catch (err) {
            txReject(err)
        }
        await nftContract.nft_mint(
            {
                args: {
                    token_id: tokenId,
                    token_owner_id: accId,
                    token_metadata: tokenMetadata,
                    token_royalties: repackRoyalties,
                },
                gas: gas,
                amount: utils.format.parseNearAmount(deposit),
                accountId: accId
            }
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise

    }

    async simpleMint(
        tokenId,
        title,
        description,
        media,
        mediaHash,
        copies,
        reference,
        referenceHash,
        royalties,
        deposit,
        gas
    ) {
        let repackRoyalties = {}
        for (var k in royalties) {
            repackRoyalties[royalties[k].id] = royalties[k].value
        }


        const date = new Date()
        const strDate = date.toISOString().slice(0, 10) + ' 00:00:00.000'

        let nftContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })

        let tokenMetadata = {
            "title": title,
            "description": description,
            "media": media,
            "media_hash": mediaHash,
            "copies": copies,
            "issued_at": strDate,
            "expires_at": strDate,
            "starts_at": strDate,
            "updated_at": strDate,
            "reference": reference,
            "reference_hash": referenceHash
        }
        try {
            nftContract = this.loadNftContract()
            accId = nftContract.account.accountId
        } catch (err) {
            txReject(err)
        }
        await nftContract.nft_mint(
            {
                args: {
                    token_id: tokenId,
                    token_owner_id: accId,
                    token_metadata: tokenMetadata,
                    token_royalties: repackRoyalties,
                },
                gas: gas,
                amount: utils.format.parseNearAmount(deposit),
                accountId: accId
            }
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise

    }


    async getNftToken(tokenId) {
        let nftContract
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            nftContract = this.loadNftContract()
        } catch (err) {
            txReject(err)
        }
        await nftContract.get_nft_token({
            "token_id": tokenId
        }
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }

    async getNftTokenFromGraph(tokenId) {

        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })

        let q = "{ token(id: \"" + NFT_CONTRACT_ID + ":" + tokenId + "\")"
        q = q + "{ id tokenId ownerId title description media mintTimestamp sales { id price auctionData } licenseSales { id sellerId goal type allowedRegions duration price maxSales totalSales isActive } licenses { id ownerId assetTokenId goal type region duration issuedAt startsAt expiresAt } purchases { id price buyerId timestamp}}}"


        console.log('q')
        console.log(q)

        var axios = require('axios');
        var data = JSON.stringify({
            "query": q
        });

        var config = {
            method: 'post',
            url: GRAPH_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                txResolve(response.data)
            })
            .catch(function (error) {
                txReject(error)
            });
        await txPromise
        return txPromise
    }


    async calculateTokenRoyalties(tokenId) {
        let nftContract
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            nftContract = this.loadNftContract()
        } catch (err) {
            txReject(err)
        }
        await nftContract.nft_payout({

            token_id: tokenId,
            balance: "100000",
            max_len_payout: 5
        }

        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }


    async getTokensByMinter(accountId) {
        let nftContract
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            nftContract = this.loadNftContract()
        } catch (err) {
            txReject(err)
        }
        await nftContract.nft_tokens_for_minter({
            account_id: accountId
        }
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }


    async getCountAllMintedTokens() {
        let nftContract
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            nftContract = this.loadNftContract()
        } catch (err) {
            txReject(err)
        }
        await nftContract.nft_total_supply()
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }


    async transferNft(tokenId, newOwnerId, ownerId, deposit, gas) {
        let nftContract
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            nftContract = this.loadNftContract()
        } catch (err) {
            txReject(err)
        }
        await nftContract.nft_transfer(
            {
                args: {
                    token_id: tokenId,
                    receiver_id: newOwnerId,
                },
                gas: gas,
                amount: Number(deposit),
                ownerId: ownerId
            }

        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }


    async getAllTokens() {
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        const q = "{tokens{ id title description ownerId media contractId tokenId mintTimestamp}}";
        var axios = require('axios');
        var data = JSON.stringify({
            "query": q
        });
        var config = {
            method: 'post',
            url: GRAPH_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                txResolve(response.data.data.tokens)
            })
            .catch(function (error) {
                txReject(error)
            });
        await txPromise
        return txPromise
    }

    // GRAPH

    async getTokenById(tokenId) {
        let nftContract
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            nftContract = this.loadNftContract()
        } catch (err) {
            txReject(err)
        }
        await nftContract.get_nft_token(
            {
                args: {
                    token_id: tokenId,
                },
            }

        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }

    /**
     *
     * @param {string} orderBy - Ordering by field. Accept [id ownerId title description media contractId tokenId mintTimestamp]. Default - id
     * @param {string} orderDirection  - Order direction. Accept [asc desc]. Default - asc
     * @param {string} filterBy  - Filtering by field. Accept [id ownerId title description media contractId tokenId mintTimestamp]. Default - id
     * @param {string} filterCriteria - Value of filter criteria
     * @param {boolean} isMyOwned  - Show only licenses owned by me. Default - false
     * @returns
     */
    async getTokens(orderBy = "id", orderDirection = "asc", filterBy, filterCriteria, isMyOwned = false) {
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })

        let q = "{ tokens("

        if (filterBy || isMyOwned) {
            q = q + "where:{"
        }

        if (isMyOwned) {
            q = q + "ownerId: \"" + this.getAccount + "\" "
        }

        if (filterBy) {
            q = q + filterBy + ":\"" + filterCriteria + "\""
        }


        if (filterBy || isMyOwned) {
            q = q + "}"
        }

        q = q + " orderBy: " + orderBy + ", orderDirection:" + orderDirection


        q = q + "){ id ownerId title description media contractId tokenId mintTimestamp}"


        var axios = require('axios');
        var data = JSON.stringify({
            "query": q
        });

        var config = {
            method: 'post',
            url: GRAPH_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                txResolve(response.data)
            })
            .catch(function (error) {
                txReject(error)
            });
        await txPromise
        return txPromise

    }


    async getTokensByOwnerId(accountId) {
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        const q = "{tokens(where: {ownerId: \"" + accountId + "\"}){ id title description ownerId media contractId tokenId mintTimestamp}}";
        var axios = require('axios');
        var data = JSON.stringify({
            "query": q
        });
        var config = {
            method: 'post',
            url: GRAPH_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                txResolve(response.data.data.tokens)
            })
            .catch(function (error) {
                txReject(error)
            });
        await txPromise
        return txPromise
    }


    async burnToken(tokenId) {
        let nftContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            nftContract = this.loadNftContract()
            accId = nftContract.account.accountId
        } catch (err) {
            txReject(err)
        }
        await nftContract.nft_burn(
            {
                args: {
                    token_id: tokenId
                },
                amount: 1
            }
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }


    // Admin Contract methods


    async getMinters() {
        let nftContract
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            nftContract = this.loadNftContract()
        } catch (err) {
            txReject(err)
        }
        await nftContract.get_minters()
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }


    async updateMinters(minters) {
        let nftContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            nftContract = this.loadNftContract()
            accId = nftContract.account.accountId
        } catch (err) {
            txReject(err)
        }
        await nftContract.update_minters({
            args: {
                "minters": minters
            },
            amount: 1,
            accountId: accId
        }

        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }


    async addMinter(minterID){
        let nftContract, accId
        let txResolve, txReject
        let minters
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            nftContract = this.loadNftContract()
            accId = nftContract.account.accountId
            minters = await this.getMinters()
            if(!minters.includes(minterID)){
                minters.push(minterID)
            }
        } catch (err) {
            txReject(err)
        }
        await nftContract.update_minters({
            args: {
                "minters": minters
            },
            amount: 1,
            accountId: accId
        }
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }

    async removeMinter(minterID){
        let nftContract, accId
        let txResolve, txReject
        let minters
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            nftContract = this.loadNftContract()
            accId = nftContract.account.accountId
            minters = await this.getMinters()
            let index = minters.indexOf(minterID)
            if(index !== -1){
                minters.splice(index, 1)
            }
        } catch (err) {
            txReject(err)
        }
        await nftContract.update_minters({
            args: {
                "minters": minters
            },
            amount: 1,
            accountId: accId
        }
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }




    async checkOffers(from, limit) {
        let marketContract
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            marketContract = this.loadMarketContract()
        } catch (err) {
            txReject(err)
        }
        await marketContract.get_nfts({
            from: Number(from),
            limit: Number(limit)
        },
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }


    async checkPriceOffer(tokenId) {
        let marketContract
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            marketContract = this.loadMarketContract()
        } catch (err) {
            txReject(err)
        }
        await marketContract.get_nft_price({
            token_uid: NFT_CONTRACT_ID + ":" + tokenId
        },
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }


    async initialListOnMarket(
        tokenId,
        price,
        payout,
        additionalMetadata,
        deposit,
        gas
    ) {
        let nftContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        let msg = {
            additionalMetadata,
            sale_conditions: {
                price: utils.format.parseNearAmount(price)
            }
        }
        try {
            nftContract = this.loadNftContract()
        } catch (err) {
            txReject(err)
        }
        await nftContract.nft_approve_initial({
            args: {
                token_id: tokenId,
                account_id: MARKET_CONTRACT_ID,
                msg: JSON.stringify(msg),
                payout: payout
            },
            amount: utils.format.parseNearAmount(deposit),
            gas: gas,
            accountId: accId



        }
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }



    async removeFromMarket(tokenId, gas) {
        let marketContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            marketContract = this.loadMarketContract()
            accId = marketContract.account.accountId
        } catch (err) {
            txReject(err)
        }
        await marketContract.remove_from_market({
            args: {
                nft_contract_id: NFT_CONTRACT_ID,
                token_id: tokenId
            },
            gas: gas,
            accountId: accId
        }
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }



    async buyNft(tokenId, deposit, gas) {
        let marketContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            marketContract = this.loadMarketContract()
        } catch (err) {
            txReject(err)
        }
        await marketContract.buy({
            args: {
                nft_contract_id: NFT_CONTRACT_ID,
                token_id: tokenId
            },
            amount: utils.format.parseNearAmount(deposit),
            gas: gas,
            accountId: accId

        }
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }



    async secondaryListOnMarket(tokenId, price, additionalMetadata, deposit, gas) {
        let nftContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        let msg = {
            additionalMetadata,
            sale_conditions: {
                price: utils.format.parseNearAmount(price)
            }
        }
        try {
            nftContract = this.loadNftContract()
        } catch (err) {
            txReject(err)
        }
        await nftContract.nft_approve({
            args: {
                token_id: tokenId,
                account_id: MARKET_CONTRACT_ID,
                msg: JSON.stringify(msg)
            },
            amount: utils.format.parseNearAmount(deposit),
            gas: gas,
            accountId: accId
        }
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }



    async createLicenseSale(tokenId,
        goal, license_type, duration, allowed_regions, price, max_sales, deposit, gas
    ) {
        let marketContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            marketContract = this.loadMarketContract()
            accId = marketContract.account.accountId
        } catch (err) {
            txReject(err)
        }
        await marketContract.create_license_sale(
            {
                "contract_id": NFT_CONTRACT_ID,
                "token_id": tokenId,
                "sale": {
                    "goal": goal,
                    "license_type": license_type,
                    "duration": duration,
                    "allowed_regions": allowed_regions,
                    "price": utils.format.parseNearAmount(price),
                    "max_sales": max_sales
                }
            },
            gas,
            deposit
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }

    async buyLicenseSale(id, deposit, gas) {
        const resp = await this.getLicenseSale(id)
        const license = resp.data.licenseSale
        const validation = await this.validateLicense(license)
        let marketContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            marketContract = this.loadMarketContract()
            accId = marketContract.account.accountId
        } catch (err) {
            txReject(err)
        }

        const data = {

            args: {
                sale_id: Number(license.id),
                region: license.allowedRegions,
                license_token: {
                    payload: validation.tokens.payload,
                    signature: validation.tokens.signature
                }
            },
            accountId: accId,
            gas: gas,
            amount: utils.format.parseNearAmount(deposit),
        }
        console.log(data)
        await marketContract.license_buy(
            data
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise

    }

    async getLicenseSale(id) {
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        const q = "{licenseSale(id: \"" + id + "\"){id tokenId goal type allowedRegions duration}}";
        var axios = require('axios');
        var data = JSON.stringify({
            "query": q
        });

        var config = {
            method: 'post',
            url: GRAPH_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                txResolve(response.data)
            })
            .catch(function (error) {
                txReject(error)
            });
        await txPromise
        return txPromise
    }


    /**
     *
     * @param {string} orderBy - Ordering by field. Accept [id ownerId assetTokenId goal type region duration issuedAt startsAt expiresAt]
     * @param {string} orderDirection  - Order direction. Accept [asc desc]. Default - asc
     * @param {string} filterBy  - Filtering by field. Accept [id ownerId assetTokenId goal type region duration issuedAt startsAt expiresAt]. Default - id
     * @param {string} filterCriteria - Value of filter criteria
     * @param {boolean} isMyOwned  - Show only licenses owned by me. Default - false
     * @returns
     */
    async getLicenses(orderBy = "id", orderDirection = "asc", filterBy, filterCriteria, isMyOwned = false) {

        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })

        let q = "{ licenseTokens("

        if (filterBy || isMyOwned) {
            q = q + "where:{"
        }

        if (isMyOwned) {
            q = q + "ownerId: \"" + this.getAccount + "\" "
        }

        if (filterBy) {
            q = q + filterBy + ":\"" + filterCriteria + "\""
        }


        if (filterBy || isMyOwned) {
            q = q + "}"
        }

        q = q + " orderBy: " + orderBy + ", orderDirection:" + orderDirection


        q = q + "){id ownerId assetTokenId goal type region duration issuedAt startsAt expiresAt}"


        var axios = require('axios');
        var data = JSON.stringify({
            "query": q
        });

        var config = {
            method: 'post',
            url: GRAPH_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                txResolve(response.data)
            })
            .catch(function (error) {
                txReject(error)
            });
        await txPromise
        return txPromise

    }

    /**
     *
     * @param {string} orderBy - Ordering by field. Accept [id sellerId tokenId goal type allowedRegions duration price maxSales totalSales isActive ]
     * @param {string} orderDirection  - Order direction. Accept [asc desc]. Default - asc
     * @param {string} filterBy  - Filtering by field. Accept [id sellerId tokenId goal type allowedRegions duration price maxSales totalSales isActive ]. Default - id
     * @param {string} filterCriteria - Value of filter criteria
     * @param {boolean} isMyOwned  - Show only licenses owned by me. Default - false
     * @returns
     */

    async getLicenseSales(orderBy = "id", orderDirection = "asc", filterBy, filterCriteria, isMyOwned = false) {
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })

        let q = "{ licenseSales("

        if (filterBy || isMyOwned) {
            q = q + "where:{"
        }

        if (isMyOwned) {
            q = q + "sellerId: \"" + this.getAccount + "\" "
        }

        if (filterBy) {
            q = q + filterBy + ":\"" + filterCriteria + "\""
        }


        if (filterBy || isMyOwned) {
            q = q + "}"
        }

        q = q + " orderBy: " + orderBy + ", orderDirection:" + orderDirection


        q = q + "){ id sellerId tokenId goal type allowedRegions duration price maxSales totalSales isActive }"


        var axios = require('axios');
        var data = JSON.stringify({
            "query": q
        });

        var config = {
            method: 'post',
            url: GRAPH_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                txResolve(response.data)
            })
            .catch(function (error) {
                txReject(error)
            });
        await txPromise
        return txPromise
    }


    async validateLicense(license) {
        let nftContract = this.loadNftContract()
        let accId = nftContract.account.accountId
        console.log(license);

        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })


        const ar = license.allowedRegions.split(',')

        let firstRegion


        if (Array.isArray(ar)) {
            firstRegion = ar[0]
        } else {
            firstRegion = ar
        }

        const data = {
            "token_id": license.tokenId,
            "contract_id": NFT_CONTRACT_ID,
            "receiver_id": accId,
            "license": {
                "goal": Number(license.goal),
                "license_type": Number(license.type),
                "region": firstRegion,
                "duration": license.duration
            }
        }


        var axios = require('axios');


        var config = {
            method: 'post',
            url: BACKEND_URL + '/testing/api/v1/validation/license',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                txResolve(response.data)
            })
            .catch(function (error) {
                txReject(error)
            });
        await txPromise
        return txPromise
    }

    async updateLicenseSale(saleId, price, allowedRegions, max_sales, deposit, gas) {
        let marketContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        let sale = {
            sale_id: saleId,
            price: price,
            allowed_regions: allowedRegions,
            max_sales: max_sales

        }
        try {
            marketContract = this.loadMarketContract()
        } catch (err) {
            txReject(err)
        }
        await marketContract.update_license_sale(
            sale,
            accId,
            deposit,
            gas,
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }


    async removeLicenseSale(saleId, deposit) {
        let marketContract, accId
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            marketContract = this.loadMarketContract()
        } catch (err) {
            txReject(err)
        }
        await marketContract.remove_license_sale(
            {
                sale_id: saleId
            },
            accId,
            deposit
        )
            .then(resp => { txResolve(resp) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }




    async getLicenseByIdFromGraph(id) {
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        const q = "{licenseToken(id: \"" + id + "\"){ id ownerId assetTokenId goal type region duration issuedAt startsAt expiresAt}}";
        var axios = require('axios');
        var data = JSON.stringify({
            "query": q
        });

        var config = {
            method: 'post',
            url: GRAPH_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                txResolve(response.data)
            })
            .catch(function (error) {
                txReject(error)
            });
        await txPromise
        return txPromise
    }


    async getLicensesById(licenseId) {
        let licenseContract
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        try {
            licenseContract = this.loadLicenseContract()
        } catch (err) {
            txReject(err)
        }
        const tokenId = licenseId.replace(NFT_CONTRACT_ID, '').split(':')[1]
        await licenseContract.nft_licenses_for_token({
            "contract_id": NFT_CONTRACT_ID,
            "token_id": tokenId
        }
        )
            .then(resp => { txResolve(resp.filter(l => l.token_id == licenseId)) })
            .catch(err => { txReject(err) })
        await txPromise
        return txPromise
    }

    // eslint-disable-next-line
    async getLicenses() {
        let txResolve, txReject
        let txPromise = new Promise((resolve, reject) => {
            txResolve = resolve
            txReject = reject
        })
        const q = "{licenseTokens { id ownerId assetTokenId goal type region duration issuedAt expiresAt}}";
        var axios = require('axios');
        var data = JSON.stringify({
            "query": q
        });

        var config = {
            method: 'post',
            url: GRAPH_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                txResolve(response.data)
            })
            .catch(function (error) {
                txReject(error)
            });
        await txPromise
        return txPromise
    }


    // -------------------- //

    async createBlob(file) {
        let blobResolve, blobReject;
        let blobDone = new Promise((resolve, reject) => {
            blobResolve = resolve;
            blobReject = reject;
        });
        let reader = new FileReader();
        reader.onload = function (e) {
            let blob = new Blob([new Uint8Array(e.target.result)], {
                type: "image/jpeg",
            });
            blobResolve(blob)
        };
        reader.readAsArrayBuffer(file);
        await blobDone
        return blobDone
    }








    async fixPdf(blob) {

        console.log(blob)
        let p = new Promise((resolve, reject) => {
            var fileReader = new FileReader();
            fileReader.onload = function (event) {
                resolve(event.target.result);
            };
            fileReader.onerror = reject;
            fileReader.readAsArrayBuffer(blob);
        });
        const arr = await p;
        const uint8array = new Uint8Array(arr);
        const pdfTriggerIndex = this.findPdfTrigger(uint8array);
        const arr2 = arr.slice(pdfTriggerIndex);
        return new Blob([arr2], { type: "application/pdf" });
    }

    findPdfTrigger(array) {
        var result = -1;
        const pdfTrigger = new Uint8Array([37, 80, 68, 70, 45, 49, 46, 55]);
        const arrEquals = (a, b) =>
            a.length === b.length && a.every((v, i) => v === b[i]);
        array.forEach((e, index) => {
            if (e == pdfTrigger[0] && result == -1) {
                const tmpArray = array.slice(index, index + pdfTrigger.length);
                if (arrEquals(pdfTrigger, tmpArray)) {
                    result = index;
                }
            }
        });
        return result;
    }


}

export default new vArtNearApi()
