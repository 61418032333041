import { render, staticRenderFns } from "./RoyaltyReport.vue?vue&type=template&id=3101d5f1&scoped=true&"
import script from "./RoyaltyReport.vue?vue&type=script&lang=js&"
export * from "./RoyaltyReport.vue?vue&type=script&lang=js&"
import style0 from "./RoyaltyReport.vue?vue&type=style&index=0&id=3101d5f1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3101d5f1",
  null
  
)

export default component.exports