import vArtNearApi from "./../helpers/v-art-protocol-near-sdk";
import axios from "axios";
import concordiumAPI from "./../helpers/concordium"
import {CIS_CONTRACT_ADDRESS} from "../Constants";
import router from "../router";

const ROUTES_NAMES = [
  {
    route_name: "nfts",
    iframe_name: "minter-nfts",
  },
  {
    route_name: "profile/license-preview",
    iframe_name: "minter-license-preview",
  },
  {
    route_name: "licenses",
    iframe_name: "buyer-licenses",
  },
  {
    route_name: "offers",
    iframe_name: "minter-offers",
  },
  {
    route_name: "profile/nft",
    iframe_name: "minter-nft",
  },
  {
    route_name: "profile/license",
    iframe_name: "buyer-license",
  },
  {
    route_name: "profile/my-license",
    iframe_name: "buyer-my-license",
  },
  {
    route_name: "profile/create-offer",
    iframe_name: "minter-create-offer",
  },
  {
    route_name: "profile/license-preview",
    iframe_name: "minter-create-offer",
  },
  {
    route_name: "explorer",
    iframe_name: "buyer-nft",
  },
  {
    route_name: "nft-marketplace",
    iframe_name: "buyer-explorers",
  },
];

export default {
  data() {
    return {
      ROUTES_NAMES,
    };
  },
  created() {
    window.addEventListener("message", this.messageListener);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.messageListener);
  },
  methods: {
    messageListener(event) {
      if (event.data.name == "changeRoute")
        return this.changeRoute(event.data.params);
      if (event.data.name == "checkRoute")
        return this.checkRoute(event.data.params);

      if (event.data.name && !event.data.name.includes("Request")) {
        this[event.data.name](event.data.params);
      }
    },
    postRequest(requestName, params) {
      const name = requestName + "Request";
      console.log("!!!!!!!!!!!! will post request", name);

      window.frames[0].postMessage(
        {
          name,
          params,
        },
        "*"
      );
    },
    checkRoute(params) {
      if (!params.name) return;

      const routeIframe = params.name;
      const activeRoute = this.$router.currentRoute.name;

      for (let i = 0; i < this.ROUTES_NAMES.length; i++) {
        if (
          routeIframe == this.ROUTES_NAMES[i].iframe_name &&
          this.ROUTES_NAMES[i].route_name != activeRoute
        ) {
          this.$router.go(-1);
          break;
        }
      }
    },
    changeRoute(params) {
      console.log(params, "params changeRoute");

      if (
        params.name == "NFTMarketplaceLicense" ||
        params.name == "NFTMarketplaceMyLicense"
      ) {
        this.$router.push({
          name: params.name,
          params: {
            licenseId: params.id,
          },
        });
      } else {
        this.$router.push({
          name: params.name,
          params: {
            tokenId: params.id,
          },
        });
      }
    },
    signIn(params) {
      localStorage.web3_network = params && params.network? params.network : 'near';
      console.log("signIn params", params);
      if(params && params.network == 'concordium'){
        concordiumAPI.concordConnect()
              .then(async (res) => {
              if(res){
                console.log(res);
                let account = await concordiumAPI.concordGetConnectedAccount();
                console.log(account);
                if(account) {
                  localStorage.web3_account = account;
                  localStorage.web3_network = 'concordium';
                  window.frames[0].postMessage(
                      {
                        name: "signInConfirm",
                        params: {
                          account: localStorage.web3_account,
                          network: localStorage.web3_network,
                          contract: CIS_CONTRACT_ADDRESS.index.toString(),
                        }
                      },
                      "*"
                  );

                  window.frames[0].postMessage(
                      {
                        name: "changeRouteIframe",
                        params: {name: "buyer-explorers"},
                      },
                      "*"
                  );


                }
              }

          });
      }else{
        vArtNearApi.signOut();
        vArtNearApi.signIn();
      }

    },
    signOut() {
      console.log("signOut from  parent");

      concordiumAPI.concordDisconnect();
      vArtNearApi.signOut();

      localStorage.web3_network = "";
      localStorage.web3_account = "";

      router.push({
        name: "NFTMarketplace",
      })
      .catch(e => {e;})


      window.frames[0].postMessage(
          {
            name: "changeRouteIframe",
            params: {name: "auth"},
          },
          "*"
      );



    },
    async getNftToken(tokenId) {
      let nft;
      if(localStorage.web3_network == "concordium"){
        nft = await concordiumAPI.getNftToken(tokenId);
      }else {
        nft = await vArtNearApi.getNftToken(tokenId);
      }
      this.postRequest("getNftToken", nft);
    },
    async getLicenseByIdFromGraph(id) {
      const license = await vArtNearApi.getLicenseByIdFromGraph(id);
      this.postRequest("getLicenseByIdFromGraph", license);
    },
    async removeLicenseSale(licenseId, gas) {
      await vArtNearApi.removeLicenseSale(licenseId, gas);
    },
    async getNftTokens(data) {
      const offers = JSON.parse(data);
      let count = JSON.parse(data).length;

      offers.forEach((offer, i) => {
        vArtNearApi.getNftToken(offer.assetTokenId).then((resp) => {
          let title = resp.metadata.title;

          axios.get(resp.metadata.reference).then((response) => {
            let preview_image = response.data.product.media_preview;
            let ms_lost = offer.expiresAt - +new Date();
            let hours_lost = ms_lost / (1000 * 3600);
            let days_lost = hours_lost / 24;
            hours_lost = hours_lost % 24;

            Object.assign(offer, {
              preview_image,
              title,
              days_lost,
            });

            if (i == count - 1)
              this.postRequest("getNftTokens", offers);
          });
        });
      });
    },
    async getNftTokenFromGraph(tokenId) {
      let nftFromGraph = {
        data: {
          token: {
            licenseSales: [],
            licenses: [],
            purchases: [],
            sales: [],
            tokenId
          }
        }
      }
      if(localStorage.web3_network !== "concordium") {
        nftFromGraph = await vArtNearApi.getNftTokenFromGraph(
            tokenId
        );
      }else{
        let tmp = await this.getSalePositions(tokenId, true);
        console.log("getNftTokenFromGraph", tmp[tokenId], tokenId);
        if(tmp[tokenId]){
          tmp[tokenId].price = parseInt(tmp[tokenId].price.toString());
          tmp[tokenId].quantity = parseInt(tmp[tokenId].quantity.toString());
          tmp[tokenId].id = tokenId;
          tmp[tokenId].allowedRegions = "Worldwide";
          tmp[tokenId].goal = 4;
          tmp[tokenId].type = 4;
          tmp[tokenId].duration = "∞";
          tmp[tokenId].maxSales = parseInt(tmp[tokenId].quantity.toString());
          nftFromGraph.data.token.licenses = [tmp[tokenId]];
          nftFromGraph.data.token.licenseSales = [tmp[tokenId]];
        }
      }
      console.log("getNftTokenFromGraph", nftFromGraph);
      this.postRequest("getNftTokenFromGraph", nftFromGraph);
    },
    async getSalePositions(params, inner = false){
      let nft;
      if(localStorage.web3_network == "concordium"){
        nft = await concordiumAPI.getSalePositions();
      }else {
        nft = {};
      }
      // console.log("getSalePositions", nft);
      if(!inner)
        this.postRequest("getSalePositions", nft);
      else
        return nft;
    },
    async removeFromMarket(tokenId) {
      await vArtNearApi.removeFromMarket(tokenId);
    },
    async createInitialTokenSale(params) {

      if(localStorage.web3_network == "concordium"){

        console.log("createInitialTokenSale", params)
        await concordiumAPI.createInitialTokenSale(
            params.tokenId,
            params.priceList,
            params.payout,
            params.deposit,
            params.gas
        );

      }else{

        await vArtNearApi.createInitialTokenSale(
            params.tokenId,
            params.priceList,
            params.payout,
            params.deposit,
            params.gas
        );

      }


    },
    async fullMint(params) {
      try {

        if(localStorage.web3_network == "concordium"){

          console.log("fullMint", params);

          await concordiumAPI.fullMint(
              params.tokenId,
              params.media,
              params.preview,
              params.title,
              params.medium,
              params.genre,
              params.bio,
              params.description,
              params.artist,
              params.createdBy,
              params.year,
              params.price,
              JSON.parse(params.artists),
              JSON.parse(params.royalties),
              params.quantity,
              params.edition,
              params.copies,
              params.deposit,
              params.gas
          );

        }else {

          await vArtNearApi.fullMint(
              params.tokenId,
              params.media,
              params.preview,
              params.title,
              params.medium,
              params.genre,
              params.bio,
              params.description,
              params.artist,
              params.createdBy,
              params.year,
              params.price,
              JSON.parse(params.artists),
              JSON.parse(params.royalties),
              params.quantity,
              params.edition,
              params.copies,
              params.deposit,
              params.gas
          );
        }

        this.postRequest("fullMint", "success");
      } catch (e) {
        console.log("ERROR:", e);
        this.postRequest("fullMint", "error");
      }
    },
    async createLicenseSale(params) {
      await vArtNearApi.createLicenseSale(
        params.tokenId,
        params.goal,
        params.license_type,
        params.duration,
        JSON.parse(params.allowed_regions),
        params.price,
        params.max_sales,
        params.deposit,
        params.gas
      );
    },
    async buyLicenseSale(params) {
      console.log("params buyLicenseSale", params);
      try {

        if(localStorage.web3_network == "concordium"){
          await concordiumAPI.buyLicenseSale(
              params.id,
              params.deposit,
              params.gas,
              params.owner,
              params.contract,
          );
        }
        else {
          await vArtNearApi.buyLicenseSale(
              params.id,
              params.deposit,
              params.gas,
          );
        }
        console.log("postRequest");
        this.postRequest("buyLicenseSale");
      } catch (error) {
        console.log(error, "error.response buyLicenseSale");
        this.postRequest("buyLicenseSaleError", error);
      }
    },
    async getLicensesById(params) {
      const offer = await vArtNearApi.getLicensesById(params);
      this.postRequest("getLicensesById", offer);
    },
  },
};
