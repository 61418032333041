<template>
  <div id="my-page">
    <div id="my-content">
      <Header ref="headerPanel" />
      <main id="root" class="trading section">
        <div class="preloader-plug" v-if="loadFlag"></div>

        <div class="preloader-wrapper" id="preloader" v-if="loadFlag">
          <v-progress-circular
            :size="100"
            :width="10"
            color="#FF0090"
            indeterminate
          ></v-progress-circular>
        </div>

        <div class="container-small market-main-wrap">
          <div class="trading-header">
            <button @click="btnBack" class="back-btn">
              <svg
                width="28"
                height="10"
                viewBox="0 0 28 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.1699 5.51286H2.41621L5.99017 9.08683L5.18712 9.88988L0.242188 4.94494L5.18712 0L5.99017 0.803047L2.41621 4.37701H27.1699V5.51286Z"
                />
              </svg>
              <span class="back-btn-text">Back to market</span>
            </button>
            <h1 class="title page-title separator page-title__second-style">
              Trading
            </h1>
          </div>

          <div class="market-coin-info" v-if="!fullChartFlag">
            <div class="market__song-block">
              <div class="market__song-block-play-btn">
                <button
                  :class="
                    togglePlayerFlag
                      ? 'song_listen-play'
                      : 'song_listen-play-active'
                  "
                  @click="toggleMusic"
                  type="button"
                ></button>
              </div>
              <div class="market__song-block-info">
                <router-link
                  :to="{ path: '/song/' + tradeSongData.songId }"
                  class="song-name"
                  v-text="tradeSongData.songTitle"
                ></router-link>
                <router-link
                  :to="{
                    path: '/artist/' + tradeSongData.singerUsername,
                  }"
                  class="song-author"
                >
                  {{ tradeSongArtistName }}
                </router-link>
                <p v-if="tradeSongData.companyName">
                  Sign by
                  <router-link
                    :to="{
                      path: `/company/${tradeSongData.companyName}`,
                    }"
                    style="color: #fb3099; text-decoration: underline"
                    >{{ tradeSongData.companyName || "DjookyX" }}</router-link
                  >
                </p>
              </div>
              <div class="audio-player-trading-wrapper">
                <AudioPlayer
                  :src="playerSrc"
                  :togglePlayerFlag="togglePlayerFlag"
                  :width="'152px'"
                  v-if="playerSrc"
                  ref="audioPlayer"
                  @audioPause="togglePlayerFlag = true"
                  :identifier="'trading'"
                  :index="1"
                />
              </div>
            </div>

            <div class="market__coin-block">
              <router-link
                :to="{
                  path: '/artist/' + tradeSongData.singerUsername,
                }"
                class="market__coin-author_img"
              >
                <img
                  v-if="tradeSongData.authorPhotoUrl"
                  :src="tradeSongData.authorPhotoUrl"
                  alt="Coin author image"
                />
                <img
                  v-else
                  src="@/assets/img/default-account-image.png"
                  alt="Default coin author image"
                />
              </router-link>
              <div class="market__coin-block-wrapper">
                <div
                  class="market__coin-name"
                  v-text="`${currentCurrencyName}/${moneyCurrency.symbol}`"
                ></div>
                <div class="market__coin-price-wrapper">
                  <div class="market__coin-price">
                    <p class="coin-title">Price</p>
                    <p
                      class="coin-value"
                      v-text="
                        `${moneyCurrency.symbol} ${songPrice.lastPrice.toFixed(
                          2
                        )}`
                      "
                    ></p>
                  </div>
                  <div class="market__coin-price price-changes">
                    <p class="coin-title">24h change</p>
                    <p
                      class="coin-value"
                      :style="{
                        color: numColorHandler(songPrice.priceChanges),
                      }"
                      v-text="numPositiveHandler(songPrice.priceChanges)"
                    ></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="market__coin-block mobile">
              <div
                class="market__coin-name"
                v-text="`${currentCurrencyName}/${moneyCurrency.symbol}`"
              ></div>
              <div class="market__coin-price">
                <p class="coin-title">Price</p>
                <p
                  class="coin-value"
                  v-text="
                    `${moneyCurrency.symbol} ${songPrice.lastPrice.toFixed(2)}`
                  "
                ></p>
              </div>
              <div class="market__coin-price price-changes">
                <p class="coin-title">24h change</p>
                <p
                  class="coin-value"
                  :style="{
                    color: numColorHandler(songPrice.priceChanges),
                  }"
                  v-text="numPositiveHandler(songPrice.priceChanges)"
                ></p>
              </div>
            </div>

            <div class="market__coin-block-separator"></div>
          </div>

          <div
            class="trading-main-container"
            v-if="!fullChartFlag && screenWidthFlag === 'desktop'"
          >
            <div class="trading-main-row top-row">
              <div class="left-row">
                <div class="order-books-main-wrap">
                  <div class="chart-item-header">
                    <span
                      class="label order-books-price-title"
                      v-text="`Price (${moneyCurrency.symbol})`"
                    ></span>
                    <span
                      class="label order-books-amount-title"
                      v-text="`Amount (${currentCurrencyName})`"
                    ></span>
                    <span
                      class="label order-books-total-title"
                      v-text="`Total (${moneyCurrency.symbol})`"
                    ></span>
                  </div>

                  <div class="chart-item-content">
                    <div class="order-books order-books-isAskTrue">
                      <TradingOrders
                        v-for="(order, index) in orderBookTrue"
                        :key="index"
                        :order="order"
                      />
                    </div>

                    <div class="last-price-item">
                      <span
                        class="market-price-value"
                        v-text="
                          `${
                            moneyCurrency.symbol
                          } ${songPrice.lastPrice.toFixed(2)}`
                        "
                      ></span>
                    </div>

                    <div class="order-books order-books-isAskFalse">
                      <TradingOrdersFalse
                        v-for="(order, index) in orderBookFalse"
                        :key="index"
                        :order="order"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="center-row">
                <div id="graph" class="block">
                  <trading-chart
                    ref="TradingChart"
                    class="js--trading-chart"
                    :full-chart-flag="fullChartFlag"
                    :currentWidth="currentWidth"
                    :currentHeight="currentHeight"
                    :candlesData="candles"
                    :defaultRange="defaultRange"
                    :timeFrame="candleTimeFrame"
                    :lastCandle="lastCandle"
                    :notEnoughDataFlag="notEnoughDataFlag"
                    :userTimezone="userTimezone"
                    @changeTimeFrame="changeTimeFrame"
                    @resizeChart="resizeChart"
                  ></trading-chart>
                </div>
              </div>

              <div class="right-row">
                <div class="pair-main-wrapper">
                  <div class="chart-item-header">
                    <div class="col-left">
                      <button
                        class="pair-header-title"
                        :class="{ active: sortByType === 'name' }"
                        type="button"
                        @click="sortPairByName"
                      >
                        <span class="label">Pair</span>

                        <svg
                          class="button-icon"
                          width="6"
                          height="11"
                          viewBox="0 0 6 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_9411_13051)">
                            <path
                              :class="{
                                'active-high':
                                  !marketPricesBody.isSortNameAsc &&
                                  sortByType === 'name',
                              }"
                              d="M2.35793 0.884691L0.41689 4.12511C0.37687 4.19441 0.355695 4.27299 0.355471 4.35302C0.355246 4.43305 0.375981 4.51174 0.415612 4.58127C0.455244 4.6508 0.512389 4.70874 0.581364 4.74932C0.65034 4.78991 0.72874 4.81173 0.808765 4.81261H4.69085C4.77087 4.81173 4.84927 4.78991 4.91825 4.74932C4.98722 4.70874 5.04437 4.6508 5.084 4.58127C5.12363 4.51174 5.14437 4.43305 5.14414 4.35302C5.14392 4.27299 5.12274 4.19441 5.08272 4.12511L3.14168 0.884691C3.10083 0.817341 3.04331 0.761656 2.97466 0.723011C2.90602 0.684365 2.82858 0.664062 2.74981 0.664062C2.67103 0.664063 2.59359 0.684365 2.52495 0.723011C2.45631 0.761656 2.39879 0.817341 2.35793 0.884691Z"
                              fill="#868686"
                            />
                          </g>
                          <g clip-path="url(#clip1_9411_13051)">
                            <path
                              :class="{
                                'active-low':
                                  marketPricesBody.isSortNameAsc &&
                                  sortByType === 'name',
                              }"
                              d="M3.14207 10.1153L5.08311 6.87489C5.12313 6.80559 5.14431 6.72701 5.14453 6.64698C5.14475 6.56695 5.12402 6.48826 5.08439 6.41873C5.04476 6.3492 4.98761 6.29126 4.91864 6.25068C4.84966 6.21009 4.77126 6.18827 4.69124 6.18739L0.809152 6.18739C0.729127 6.18827 0.650727 6.21009 0.581752 6.25068C0.512777 6.29126 0.455631 6.3492 0.416 6.41873C0.376369 6.48826 0.355634 6.56695 0.355858 6.64698C0.356082 6.72701 0.377258 6.80559 0.417277 6.87489L2.35832 10.1153C2.39917 10.1827 2.45669 10.2383 2.52534 10.277C2.59398 10.3156 2.67142 10.3359 2.75019 10.3359C2.82897 10.3359 2.90641 10.3156 2.97505 10.277C3.04369 10.2383 3.10121 10.1827 3.14207 10.1153Z"
                              fill="#868686"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_9411_13051">
                              <rect width="5.5" height="5.5" fill="white" />
                            </clipPath>
                            <clipPath id="clip1_9411_13051">
                              <rect
                                width="5.5"
                                height="5.5"
                                fill="white"
                                transform="translate(5.5 11) rotate(-180)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>

                    <div class="col-center">
                      <button
                        class="pair-header-title"
                        :class="{ active: sortByType === 'price' }"
                        type="button"
                        @click="sortPairByPrice"
                      >
                        <span class="label">Price</span>

                        <svg
                          class="button-icon"
                          width="6"
                          height="11"
                          viewBox="0 0 6 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_9411_13051)">
                            <path
                              :class="{
                                'active-high':
                                  !marketPricesBody.isSortPriceAsc &&
                                  sortByType === 'price',
                              }"
                              d="M2.35793 0.884691L0.41689 4.12511C0.37687 4.19441 0.355695 4.27299 0.355471 4.35302C0.355246 4.43305 0.375981 4.51174 0.415612 4.58127C0.455244 4.6508 0.512389 4.70874 0.581364 4.74932C0.65034 4.78991 0.72874 4.81173 0.808765 4.81261H4.69085C4.77087 4.81173 4.84927 4.78991 4.91825 4.74932C4.98722 4.70874 5.04437 4.6508 5.084 4.58127C5.12363 4.51174 5.14437 4.43305 5.14414 4.35302C5.14392 4.27299 5.12274 4.19441 5.08272 4.12511L3.14168 0.884691C3.10083 0.817341 3.04331 0.761656 2.97466 0.723011C2.90602 0.684365 2.82858 0.664062 2.74981 0.664062C2.67103 0.664063 2.59359 0.684365 2.52495 0.723011C2.45631 0.761656 2.39879 0.817341 2.35793 0.884691Z"
                              fill="#868686"
                            />
                          </g>
                          <g clip-path="url(#clip1_9411_13051)">
                            <path
                              :class="{
                                'active-low':
                                  marketPricesBody.isSortPriceAsc &&
                                  sortByType === 'price',
                              }"
                              d="M3.14207 10.1153L5.08311 6.87489C5.12313 6.80559 5.14431 6.72701 5.14453 6.64698C5.14475 6.56695 5.12402 6.48826 5.08439 6.41873C5.04476 6.3492 4.98761 6.29126 4.91864 6.25068C4.84966 6.21009 4.77126 6.18827 4.69124 6.18739L0.809152 6.18739C0.729127 6.18827 0.650727 6.21009 0.581752 6.25068C0.512777 6.29126 0.455631 6.3492 0.416 6.41873C0.376369 6.48826 0.355634 6.56695 0.355858 6.64698C0.356082 6.72701 0.377258 6.80559 0.417277 6.87489L2.35832 10.1153C2.39917 10.1827 2.45669 10.2383 2.52534 10.277C2.59398 10.3156 2.67142 10.3359 2.75019 10.3359C2.82897 10.3359 2.90641 10.3156 2.97505 10.277C3.04369 10.2383 3.10121 10.1827 3.14207 10.1153Z"
                              fill="#868686"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_9411_13051">
                              <rect width="5.5" height="5.5" fill="white" />
                            </clipPath>
                            <clipPath id="clip1_9411_13051">
                              <rect
                                width="5.5"
                                height="5.5"
                                fill="white"
                                transform="translate(5.5 11) rotate(-180)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>

                    <div class="col-right">
                      <button
                        class="pair-header-title"
                        :class="{ active: sortByType === 'changes' }"
                        type="button"
                        @click="sortPairByChanges24h"
                      >
                        <span class="label">Change</span>

                        <svg
                          class="button-icon"
                          width="6"
                          height="11"
                          viewBox="0 0 6 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_9411_13051)">
                            <path
                              :class="{
                                'active-high':
                                  !marketPricesBody.isSortChange24hAsc &&
                                  sortByType === 'changes',
                              }"
                              d="M2.35793 0.884691L0.41689 4.12511C0.37687 4.19441 0.355695 4.27299 0.355471 4.35302C0.355246 4.43305 0.375981 4.51174 0.415612 4.58127C0.455244 4.6508 0.512389 4.70874 0.581364 4.74932C0.65034 4.78991 0.72874 4.81173 0.808765 4.81261H4.69085C4.77087 4.81173 4.84927 4.78991 4.91825 4.74932C4.98722 4.70874 5.04437 4.6508 5.084 4.58127C5.12363 4.51174 5.14437 4.43305 5.14414 4.35302C5.14392 4.27299 5.12274 4.19441 5.08272 4.12511L3.14168 0.884691C3.10083 0.817341 3.04331 0.761656 2.97466 0.723011C2.90602 0.684365 2.82858 0.664062 2.74981 0.664062C2.67103 0.664063 2.59359 0.684365 2.52495 0.723011C2.45631 0.761656 2.39879 0.817341 2.35793 0.884691Z"
                              fill="#868686"
                            />
                          </g>
                          <g clip-path="url(#clip1_9411_13051)">
                            <path
                              :class="{
                                'active-low':
                                  marketPricesBody.isSortChange24hAsc &&
                                  sortByType === 'changes',
                              }"
                              d="M3.14207 10.1153L5.08311 6.87489C5.12313 6.80559 5.14431 6.72701 5.14453 6.64698C5.14475 6.56695 5.12402 6.48826 5.08439 6.41873C5.04476 6.3492 4.98761 6.29126 4.91864 6.25068C4.84966 6.21009 4.77126 6.18827 4.69124 6.18739L0.809152 6.18739C0.729127 6.18827 0.650727 6.21009 0.581752 6.25068C0.512777 6.29126 0.455631 6.3492 0.416 6.41873C0.376369 6.48826 0.355634 6.56695 0.355858 6.64698C0.356082 6.72701 0.377258 6.80559 0.417277 6.87489L2.35832 10.1153C2.39917 10.1827 2.45669 10.2383 2.52534 10.277C2.59398 10.3156 2.67142 10.3359 2.75019 10.3359C2.82897 10.3359 2.90641 10.3156 2.97505 10.277C3.04369 10.2383 3.10121 10.1827 3.14207 10.1153Z"
                              fill="#868686"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_9411_13051">
                              <rect width="5.5" height="5.5" fill="white" />
                            </clipPath>
                            <clipPath id="clip1_9411_13051">
                              <rect
                                width="5.5"
                                height="5.5"
                                fill="white"
                                transform="translate(5.5 11) rotate(-180)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="pair-header">
                    <div class="pair-header__btn-wrap" v-if="isLogged">
                      <button
                        type="button"
                        class="pair-head-btn pair-header-all"
                        @click="getAllPairs"
                        :class="{
                          active: !marketPricesBody.isOnlyFavorite,
                        }"
                      >
                        <span class="pair-head-btn__text">all</span>
                      </button>

                      <button
                        type="button"
                        class="pair-head-btn pair-header-favorite"
                        @click="getFavoritePairs"
                        :class="{
                          active: marketPricesBody.isOnlyFavorite,
                        }"
                      >
                        <svg
                          width="17"
                          height="15"
                          viewBox="0 0 17 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.7738 14.98C12.6474 14.9806 12.5228 14.9508 12.4103 14.8933L8.38074 12.7886L4.35115 14.8933C4.0842 15.0334 3.76061 15.0096 3.51713 14.832C3.27364 14.6544 3.15273 14.354 3.20548 14.0577L3.9956 9.61958L0.740323 6.46636C0.533357 6.2603 0.45737 5.95705 0.542793 5.67805C0.63622 5.39223 0.884458 5.18431 1.18279 5.14201L5.68645 4.48771L7.66963 0.443714C7.80165 0.171756 8.07787 -0.000976801 8.38074 -0.000976562C8.68361 -0.000976562 8.95982 0.171757 9.09184 0.443714L11.0987 4.47983L15.6024 5.13412C15.9007 5.17643 16.149 5.38435 16.2424 5.67017C16.3278 5.94916 16.2518 6.25241 16.0449 6.45847L12.7896 9.61169L13.5797 14.0498C13.6373 14.3516 13.5139 14.6593 13.2636 14.8381C13.1206 14.9382 12.9483 14.9881 12.7738 14.98H12.7738Z"
                          />
                        </svg>
                      </button>
                    </div>

                    <form
                      class="pair-search-wrap"
                      :class="{ 'full-width': !isLogged }"
                    >
                      <input
                        type="text"
                        class="pair-search-input"
                        placeholder="Search..."
                        v-model="marketPricesBody.searchText"
                      />
                      <svg
                        class="search-icon"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M13.5325 12.4675L10.9825 9.925C11.8052 8.87684 12.2517 7.58249 12.25 6.25C12.25 2.93629 9.56371 0.25 6.25 0.25C2.93629 0.25 0.25 2.93629 0.25 6.25C0.25 9.56371 2.93629 12.25 6.25 12.25C7.58249 12.2517 8.87684 11.8052 9.925 10.9825L12.4675 13.5325C12.6083 13.6745 12.8 13.7543 13 13.7543C13.2 13.7543 13.3917 13.6745 13.5325 13.5325C13.6745 13.3917 13.7544 13.2 13.7544 13C13.7544 12.8 13.6745 12.6083 13.5325 12.4675ZM1.75 6.25C1.75 3.76472 3.76472 1.75 6.25 1.75C8.73528 1.75 10.75 3.76472 10.75 6.25C10.75 8.73528 8.73528 10.75 6.25 10.75C3.76472 10.75 1.75 8.73528 1.75 6.25V6.25Z"
                          fill="black"
                        />
                      </svg>
                    </form>
                  </div>
                  <div class="pair-wrap">
                    <vuescroll :ops="ops" ref="pairBlockScroll">
                      <TradingMarketPairs
                        v-for="(pair, index) in marketPrices"
                        :key="index"
                        :pair="pair"
                        :money-currency="moneyCurrency"
                        :is-logged="isLogged"
                        :selectActiveClass="
                          pair.currencyName === currentCurrencyName
                        "
                        :currentCurrency="currentCurrencyName"
                        :id="`trading__pairs-el-${index}`"
                        @selectPair="handlePair"
                        @toggleFavorite="handleFavorite"
                        @currentId="scrollToCurrentPair"
                      />
                    </vuescroll>
                  </div>
                </div>
              </div>
            </div>

            <div class="trading-main-row bottom-row">
              <div class="left-row">
                <h3 class="trading__block-title">News</h3>
                <div class="trading-news-wrap">
                  <vuescroll :ops="ops" ref="TradingNewsBlockScroll">
                    <TradingNews
                      v-for="(news, index) in newsList"
                      :key="index"
                      :news="news"
                    />
                  </vuescroll>
                </div>
              </div>

              <div class="center-row">
                <div class="chart-form-wrap">
                  <div class="SellBuy">
                    <div class="title_sellbuy tab">
                      <button
                        class="clickTabs tab-links"
                        :class="{ active: toggleForms === 'limit' }"
                        @click="toggleForms = 'limit'"
                      >
                        Limit
                      </button>
                      <button
                        class="clickTabs tab-links"
                        :class="{ active: toggleForms === 'market' }"
                        @click="toggleForms = 'market'"
                      >
                        Market
                      </button>
                      <!--                  <div class="tab_sellbuy " :class="{active:toggleForms === 'stop-limit'}">-->
                      <!--                    <button class="clickTabs tablinks" @click="toggleForms = 'stop-limit'">Stop Limit</button>-->
                      <!--                  </div>-->
                    </div>
                    <div
                      v-if="toggleForms === 'limit'"
                      :class="{
                        'first-tab-active': toggleForms === 'limit',
                      }"
                      class="content-tabs"
                      id="Limit"
                    >
                      <div class="main_input tab-content">
                        <div class="local_input">
                          <div class="label_input">
                            <div class="wrap_title_sell_buy">
                              <span>Buy </span>
                              <span
                                class="pair_content_tab"
                                v-text="currentCurrencyName"
                              ></span>
                            </div>
                          </div>

                          <form class="market-order_buy-form">
                            <div class="market-input-container">
                              <div class="market-input-title">Price</div>
                              <div class="market-input-wrap">
                                <input
                                  class="market-input"
                                  id="buyPrice"
                                  name="price"
                                  type="number"
                                  v-model="createBuyLimit.price"
                                />
                                <span
                                  class="market-input-item"
                                  v-text="moneyCurrency.symbol"
                                ></span>
                              </div>

                              <div
                                class="market-order-error"
                                v-if="!limitAskFlag"
                              >
                                <p
                                  class=""
                                  v-if="
                                    $v.createBuyLimit.price.$dirty &&
                                      !$v.createBuyLimit.price.required
                                  "
                                >
                                  This field is required
                                </p>
                                <p
                                  class=""
                                  v-else-if="
                                    $v.createBuyLimit.price.$dirty &&
                                      !$v.createBuyLimit.price.minValue
                                  "
                                >
                                  Min price is a
                                  {{
                                    $v.createBuyLimit.price.$params.minValue.min
                                  }}
                                </p>
                                <p
                                  v-else-if="
                                    $v.createBuyLimit.price.$dirty &&
                                      !$v.createBuyLimit.price.maxDecimal
                                  "
                                >
                                  Numbers must be decimal
                                </p>
                              </div>
                            </div>

                            <div class="market-input-container">
                              <div class="market-input-title">Amount</div>
                              <div class="market-input-wrap">
                                <input
                                  class="market-input"
                                  id="buyAmount"
                                  name="amount"
                                  type="number"
                                  v-model="createBuyLimit.amount"
                                />
                                <span
                                  class="market-input-item"
                                  v-text="mainCurrencyName"
                                ></span>
                              </div>

                              <div
                                class="market-order-error"
                                v-if="!limitAskFlag"
                              >
                                <p
                                  v-if="
                                    $v.createBuyLimit.amount.$dirty &&
                                      !$v.createBuyLimit.amount.required
                                  "
                                >
                                  This field is required
                                </p>
                                <p
                                  v-else-if="
                                    $v.createBuyLimit.amount.$dirty &&
                                      !$v.createBuyLimit.amount.minValue
                                  "
                                >
                                  Min amount is a
                                  {{
                                    $v.createBuyLimit.amount.$params.minValue
                                      .min
                                  }}
                                </p>
                                <p
                                  v-else-if="
                                    $v.createBuyLimit.amount.$dirty &&
                                      !$v.createBuyLimit.amount.integer
                                  "
                                >
                                  Amount must be integer
                                </p>
                              </div>
                            </div>

                            <!--                            <div class="market__percentage-container" v-if="isLogged">-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 25)"-->
                            <!--                              >25%-->
                            <!--                              </button>-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 50)"-->
                            <!--                              >50%-->
                            <!--                              </button>-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 75)"-->
                            <!--                              >75%-->
                            <!--                              </button>-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 100)"-->
                            <!--                              >100%-->
                            <!--                              </button>-->
                            <!--                            </div>-->

                            <div class="market-input-container disabled">
                              <div class="market-input-title">Total</div>
                              <div class="market-input-wrap">
                                <div
                                  class="market-input"
                                  id="buyTotal"
                                  type="number"
                                  v-text="totalBuyLimit"
                                ></div>
                                <span
                                  class="market-input-item"
                                  v-text="moneyCurrency.symbol"
                                ></span>
                              </div>
                            </div>

                            <div class="market__form-btn-wrap">
                              <button
                                v-if="isLogged"
                                class="market__form-btn market__buy-btn"
                                @click.stop.prevent="limitOperation(false)"
                              >
                                Buy
                              </button>

                              <router-link
                                v-else
                                :to="{ path: '/login' }"
                                class="market__form-btn market__buy-btn"
                                >Log in to buy
                              </router-link>
                            </div>

                            <!--                        <div class="" v-if="limitAskFlag">-->
                            <!--                          <p class="market-order-error"-->
                            <!--                             v-if="$v.createBuyLimit.price.$dirty && !$v.createBuyLimit.price.required"-->
                            <!--                          >Price field is required</p>-->
                            <!--                          <p class="market-order-error"-->
                            <!--                             v-else-if="!$v.createBuyLimit.price.minValue"-->
                            <!--                          >Min value is a {{ $v.createBuyLimit.price.$params.minValue.min }}</p>-->

                            <!--                          <p class="market-order-error"-->
                            <!--                             v-else-if="$v.createBuyLimit.amount.$dirty && !$v.createBuyLimit.amount.required"-->
                            <!--                          >Amount in buy is required</p>-->
                            <!--                          <p class="market-order-error"-->
                            <!--                             v-else-if="!$v.createBuyLimit.amount.minValue"-->
                            <!--                          >Min value is a {{ $v.createBuyLimit.amount.$params.minValue.min }}</p>-->
                            <!--                        </div>-->
                          </form>
                        </div>
                        <div class="local_input">
                          <div class="label_input">
                            <div class="wrap_title_sell_buy">
                              <span>Sell </span>
                              <span
                                class="pair_content_tab"
                                v-text="currentCurrencyName"
                              ></span>
                            </div>
                          </div>

                          <form class="market-order_sell-form">
                            <div class="market-input-container">
                              <div class="market-input-title">Price</div>
                              <div class="market-input-wrap">
                                <input
                                  class="market-input"
                                  id="sellPrice"
                                  name="price"
                                  type="number"
                                  v-model="createSellLimit.price"
                                />
                                <span
                                  class="market-input-item"
                                  v-text="moneyCurrency.symbol"
                                ></span>
                              </div>

                              <div
                                class="market-order-error"
                                v-if="limitAskFlag"
                              >
                                <p
                                  v-if="
                                    $v.createSellLimit.price.$dirty &&
                                      !$v.createSellLimit.price.required
                                  "
                                >
                                  This field is required
                                </p>
                                <p
                                  v-else-if="!$v.createSellLimit.price.minValue"
                                >
                                  Min price is
                                  {{
                                    $v.createSellLimit.price.$params.minValue
                                      .min
                                  }}
                                </p>
                                <p
                                  v-else-if="
                                    $v.createSellLimit.price.$dirty &&
                                      !$v.createSellLimit.price.maxDecimal
                                  "
                                >
                                  Numbers must be decimal
                                </p>
                              </div>
                            </div>

                            <div class="market-input-container">
                              <div class="market-input-title">Amount</div>
                              <div class="market-input-wrap">
                                <input
                                  class="market-input"
                                  id="sellAmount"
                                  name="amount"
                                  type="number"
                                  v-model="createSellLimit.amount"
                                />
                                <span
                                  class="market-input-item"
                                  v-text="mainCurrencyName"
                                ></span>
                              </div>

                              <div
                                class="market-order-error"
                                v-if="limitAskFlag"
                              >
                                <p
                                  v-if="
                                    $v.createSellLimit.amount.$dirty &&
                                      !$v.createSellLimit.amount.required
                                  "
                                >
                                  This field is required
                                </p>
                                <p
                                  v-else-if="
                                    !$v.createSellLimit.amount.minValue
                                  "
                                >
                                  Min amount is
                                  {{
                                    $v.createSellLimit.amount.$params.minValue
                                      .min
                                  }}
                                </p>
                                <p
                                  v-else-if="
                                    $v.createSellLimit.amount.$dirty &&
                                      !$v.createSellLimit.amount.integer
                                  "
                                >
                                  Amount must be integer
                                </p>
                              </div>
                            </div>

                            <!--                            <div class="market__percentage-container" v-if="isLogged">-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                            <!--                                      :disabled="disablePercentBtn"-->
                            <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 25)"-->
                            <!--                              >25%-->
                            <!--                              </button>-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                            <!--                                      :disabled="disablePercentBtn"-->
                            <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 50)"-->
                            <!--                              >50%-->
                            <!--                              </button>-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                            <!--                                      :disabled="disablePercentBtn"-->
                            <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 75)"-->
                            <!--                              >75%-->
                            <!--                              </button>-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                            <!--                                      :disabled="disablePercentBtn"-->
                            <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 100)"-->
                            <!--                              >100%-->
                            <!--                              </button>-->
                            <!--                            </div>-->

                            <div class="market-input-container disabled">
                              <div class="market-input-title">Total</div>
                              <div class="market-input-wrap">
                                <div
                                  class="market-input"
                                  id="sellTotalPrice"
                                  type="number"
                                  v-text="totalSellLimit"
                                ></div>
                                <span
                                  class="market-input-item"
                                  v-text="moneyCurrency.symbol"
                                ></span>
                              </div>
                            </div>

                            <div class="market__form-btn-wrap">
                              <button
                                v-if="isLogged"
                                class="market__form-btn market__sell-btn"
                                @click.stop.prevent="limitOperation(true)"
                              >
                                Sell
                              </button>

                              <router-link
                                v-else
                                :to="{ path: '/login' }"
                                class="market__form-btn market__sell-btn"
                                >Log in to sell
                              </router-link>
                            </div>

                            <!--                        <div class="" v-if="!limitAskFlag">-->
                            <!--                          <p class="market-order-error"-->
                            <!--                             v-if="$v.createSellLimit.price.$dirty && !$v.createSellLimit.price.required"-->
                            <!--                          >Price in sell is required</p>-->
                            <!--                          <p class="market-order-error"-->
                            <!--                             v-else-if="!$v.createSellLimit.price.minValue"-->
                            <!--                          >Min value is {{ $v.createSellLimit.price.$params.minValue.min }}</p>-->

                            <!--                          <p class="market-order-error"-->
                            <!--                             v-else-if="$v.createSellLimit.amount.$dirty && !$v.createSellLimit.amount.required"-->
                            <!--                          >Amount in sell is required</p>-->
                            <!--                          <p class="market-order-error"-->
                            <!--                             v-else-if="!$v.createSellLimit.amount.minValue"-->
                            <!--                          >Min value is {{ $v.createSellLimit.amount.$params.minValue.min }}</p>-->
                            <!--                        </div>-->
                          </form>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="toggleForms === 'market'"
                      class="content-tabs"
                      id="Market"
                    >
                      <div class="main_input tab-content">
                        <div class="local_input">
                          <div class="label_input">
                            <div class="wrap_title_sell_buy">
                              <span>Buy </span>
                              <span
                                class="pair_content_tab"
                                v-text="currentCurrencyName"
                              ></span>
                            </div>
                          </div>

                          <form class="market-order_buy-form">
                            <div class="market-input-container disabled">
                              <div class="market-input-title">Price</div>
                              <div class="market-input-wrap">
                                <div
                                  class="market-input"
                                  id="buyPriceMarket"
                                  v-text="`~ ${songPrice.lastPrice.toFixed(2)}`"
                                ></div>
                                <span
                                  class="market-input-item"
                                  v-text="moneyCurrency.symbol"
                                ></span>
                              </div>
                            </div>

                            <div class="market-input-container">
                              <div class="market-input-title">Amount</div>
                              <div class="market-input-wrap">
                                <input
                                  class="market-input"
                                  id="buyAmountMarket"
                                  name="amount"
                                  type="number"
                                  v-model="createBuyMarket.amount"
                                />
                                <span
                                  class="market-input-item"
                                  v-text="mainCurrencyName"
                                >
                                </span>
                              </div>

                              <div
                                class="market-order-error"
                                v-if="!marketAskFlag"
                              >
                                <p
                                  v-if="
                                    $v.createBuyMarket.amount.$dirty &&
                                      !$v.createBuyMarket.amount.required
                                  "
                                >
                                  This field is required
                                </p>
                                <p
                                  v-else-if="
                                    $v.createBuyMarket.amount.$dirty &&
                                      !$v.createBuyMarket.amount.minValue
                                  "
                                >
                                  Min amount is a
                                  {{
                                    $v.createBuyMarket.amount.$params.minValue
                                      .min
                                  }}
                                </p>
                                <p
                                  v-else-if="
                                    $v.createBuyMarket.amount.$dirty &&
                                      !$v.createBuyMarket.amount.integer
                                  "
                                >
                                  Amount must be integer
                                </p>
                              </div>
                            </div>

                            <!--                            <div class="market__percentage-container" v-if="isLogged">-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 25)"-->
                            <!--                              >25%-->
                            <!--                              </button>-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 50)"-->
                            <!--                              >50%-->
                            <!--                              </button>-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 75)"-->
                            <!--                              >75%-->
                            <!--                              </button>-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 100)"-->
                            <!--                              >100%-->
                            <!--                              </button>-->
                            <!--                            </div>-->

                            <div class="market-input-container disabled">
                              <div class="market-input-title">Total</div>
                              <div class="market-input-wrap">
                                <div
                                  class="market-input"
                                  id="buyTotalMarket"
                                  type="number"
                                  v-text="totalBuyMarket"
                                ></div>
                                <span
                                  class="market-input-item"
                                  v-text="moneyCurrency.symbol"
                                ></span>
                              </div>
                            </div>

                            <div class="market__form-btn-wrap">
                              <!--                              <button v-if="isLogged"-->
                              <!--                                      class="market__form-btn market__buy-btn"-->
                              <!--                                      @click.stop.prevent="marketRequest(false)"-->
                              <!--                              >Buy</button>-->
                              <button
                                v-if="isLogged"
                                class="market__form-btn market__buy-btn"
                                @click.stop.prevent="marketOperation(false)"
                              >
                                Buy
                              </button>

                              <router-link
                                v-else
                                :to="{ path: '/login' }"
                                class="market__form-btn market__buy-btn"
                                >Log in to buy
                              </router-link>
                            </div>

                            <!--                        <div class="" v-if="marketAskFlag">-->
                            <!--                          <p class="market-order-error"-->
                            <!--                             v-if="$v.createBuyMarket.amount.$dirty && !$v.createBuyMarket.amount.required"-->
                            <!--                          >fields are required</p>-->
                            <!--                          <p class="market-order-error"-->
                            <!--                             v-else-if="!$v.createBuyMarket.amount.minValue"-->
                            <!--                          >Min value is a {{ $v.createBuyMarket.amount.$params.minValue.min }}</p>-->
                            <!--                        </div>-->
                          </form>
                        </div>
                        <div class="local_input">
                          <div class="label_input">
                            <div class="wrap_title_sell_buy">
                              <span>Sell </span>
                              <span
                                class="pair_content_tab"
                                v-text="currentCurrencyName"
                              ></span>
                            </div>
                          </div>

                          <form class="market-order_sell-form">
                            <div class="market-input-container disabled">
                              <div class="market-input-title">Price</div>
                              <div class="market-input-wrap">
                                <div
                                  class="market-input"
                                  id="sellPriceMarket"
                                  v-text="`~ ${songPrice.lastPrice.toFixed(2)}`"
                                ></div>
                                <span
                                  class="market-input-item"
                                  v-text="moneyCurrency.symbol"
                                ></span>
                              </div>
                            </div>

                            <div class="market-input-container">
                              <div class="market-input-title">Amount</div>
                              <div class="market-input-wrap">
                                <input
                                  class="market-input"
                                  id="sellAmountMarket"
                                  name="amount"
                                  type="number"
                                  v-model="createSellMarket.amount"
                                />
                                <span
                                  class="market-input-item"
                                  v-text="mainCurrencyName"
                                ></span>
                              </div>

                              <div
                                class="market-order-error"
                                v-if="marketAskFlag"
                              >
                                <p
                                  v-if="
                                    $v.createSellMarket.amount.$dirty &&
                                      !$v.createSellMarket.amount.required
                                  "
                                >
                                  This field is required
                                </p>
                                <p
                                  v-else-if="
                                    $v.createSellMarket.amount.$dirty &&
                                      !$v.createSellMarket.amount.minValue
                                  "
                                >
                                  Min amount is a
                                  {{
                                    $v.createSellMarket.amount.$params.minValue
                                      .min
                                  }}
                                </p>
                                <p
                                  v-else-if="
                                    $v.createSellMarket.amount.$dirty &&
                                      !$v.createSellMarket.amount.integer
                                  "
                                >
                                  Amount must be integer
                                </p>
                              </div>
                            </div>

                            <!--                            <div class="market__percentage-container" v-if="isLogged">-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                            <!--                                      :disabled="disablePercentBtn"-->
                            <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 25)"-->
                            <!--                              >25%-->
                            <!--                              </button>-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                            <!--                                      :disabled="disablePercentBtn"-->
                            <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 50)"-->
                            <!--                              >50%-->
                            <!--                              </button>-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                            <!--                                      :disabled="disablePercentBtn"-->
                            <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 75)"-->
                            <!--                              >75%-->
                            <!--                              </button>-->
                            <!--                              <button class="market__percentage-item"-->
                            <!--                                      type="button"-->
                            <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                            <!--                                      :disabled="disablePercentBtn"-->
                            <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 100)"-->
                            <!--                              >100%-->
                            <!--                              </button>-->
                            <!--                            </div>-->

                            <div class="market-input-container disabled">
                              <div class="market-input-title">Total</div>
                              <div class="market-input-wrap">
                                <div
                                  class="market-input"
                                  id="sellTotalMarket"
                                  type="number"
                                  v-text="totalSellMarket"
                                ></div>
                                <span
                                  class="market-input-item"
                                  v-text="moneyCurrency.symbol"
                                ></span>
                              </div>
                            </div>

                            <div class="market__form-btn-wrap">
                              <!--                              <button v-if="isLogged"-->
                              <!--                                      class="market__form-btn market__sell-btn"-->
                              <!--                                      @click.stop.prevent="marketRequest(true)"-->
                              <!--                              >Sell</button>-->
                              <button
                                v-if="isLogged"
                                class="market__form-btn market__sell-btn"
                                @click.stop.prevent="marketOperation(true)"
                              >
                                Sell
                              </button>

                              <router-link
                                v-else
                                :to="{ path: '/login' }"
                                class="market__form-btn market__sell-btn"
                                >Log in to sell
                              </router-link>
                            </div>

                            <!--                        <div class="" v-if="!marketAskFlag">-->
                            <!--                          <p class="market-order-error"-->
                            <!--                             v-if="$v.createSellMarket.amount.$dirty && !$v.createSellMarket.amount.required"-->
                            <!--                          >fields are required</p>-->
                            <!--                          <p class="market-order-error"-->
                            <!--                             v-else-if="!$v.createSellMarket.amount.minValue"-->
                            <!--                          >Min value is a {{ $v.createSellMarket.amount.$params.minValue.min }}</p>-->
                            <!--                        </div>-->
                          </form>
                        </div>
                      </div>
                    </div>
                    <!--                <div v-if="toggleForms === 'stop-limit'" class="content-tabs" id="StopLimit">-->
                    <!--                  <div class="main_input tabcontent">-->
                    <!--                    <div class="local_input">-->
                    <!--                      <div class="label_input">-->
                    <!--                        <div class="wrap_title_sell_buy">-->
                    <!--                          <span>Buy </span>-->
                    <!--                          <span class="pair_content_tab"-->
                    <!--                                v-text="currentCurrencyName"></span>-->
                    <!--                        </div>-->
                    <!--                      </div>-->

                    <!--                      <form class="market-order_buy-form">-->

                    <!--                        <div class="market-input-container">-->
                    <!--                          <div class="market-input-title">Price</div>-->
                    <!--                          <div class="market-input-wrap">-->
                    <!--                            <input class="market-input"-->
                    <!--                                   id="buyPriceStopLimit"-->
                    <!--                                   name="price"-->
                    <!--                                   type="number"-->
                    <!--                                   v-model="createBuyStopLimit.price"-->
                    <!--                            >-->
                    <!--                            <span class="market-input-item">DJK</span>-->
                    <!--                          </div>-->
                    <!--                        </div>-->

                    <!--                        <div class="market-input-container">-->
                    <!--                          <div class="market-input-title">Amount</div>-->
                    <!--                          <div class="market-input-wrap">-->
                    <!--                            <input class="market-input"-->
                    <!--                                   id="buyAmountStopLimit"-->
                    <!--                                   name="amount"-->
                    <!--                                   type="number"-->
                    <!--                                   v-model="createBuyStopLimit.amount"-->
                    <!--                            >-->
                    <!--                            <span class="market-input-item"-->
                    <!--                                  v-text="currentCurrencyName"-->
                    <!--                            ></span>-->
                    <!--                          </div>-->
                    <!--                        </div>-->

                    <!--                        <div class="market-input-container">-->
                    <!--                          <div class="market-input-title">Stop price</div>-->
                    <!--                          <div class="market-input-wrap">-->
                    <!--                            <input class="market-input"-->
                    <!--                                   id="buyLimitStopLimit"-->
                    <!--                                   name="StopLimit"-->
                    <!--                                   type="number"-->
                    <!--                                   v-model="createBuyStopLimit.stopPrice"-->
                    <!--                            >-->
                    <!--                            <span class="market-input-item">DJK</span>-->
                    <!--                          </div>-->
                    <!--                        </div>-->

                    <!--                        <div class="market-input-container">-->
                    <!--                          <div class="market-input-title">Total</div>-->
                    <!--                          <div class="market-input-wrap">-->
                    <!--                            <input class="market-input"-->
                    <!--                                   id="buyTotalStopLimit"-->
                    <!--                                   name="total"-->
                    <!--                                   type="number"-->
                    <!--                                   v-model="totalBuyStopLimit"-->
                    <!--                            >-->
                    <!--                            <span class="market-input-item">DJK</span>-->
                    <!--                          </div>-->
                    <!--                        </div>-->

                    <!--                        <div v-if="isLogged === true">-->
                    <!--                          <a class="submit_form_sell submit_form_buy btn btn-hover open-popup-order"-->
                    <!--                             @click="stopLimitRequest(false)">Order BUY</a>-->
                    <!--                        </div>-->

                    <!--                        <div v-if="isLogged === false">-->
                    <!--                          <router-link :to="{path: '/login'}"-->
                    <!--                                       class="submit_form_sell submit_form_buy btn btn-hover"-->
                    <!--                          >Login-->
                    <!--                            or Register to trade-->
                    <!--                          </router-link>-->
                    <!--                        </div>-->

                    <!--                        <div class="" v-if="stopLimitFlag">-->
                    <!--                          <p class="market-order-error"-->
                    <!--                             v-if="$v.createBuyStopLimit.price.$dirty && !$v.createBuyStopLimit.price.required"-->
                    <!--                          >fields are required</p>-->
                    <!--                          <p class="market-order-error"-->
                    <!--                             v-else-if="!$v.createBuyStopLimit.price.minValue"-->
                    <!--                          >Min value is a {{ $v.createBuyStopLimit.price.$params.minValue.min }}</p>-->

                    <!--                          <p class="market-order-error"-->
                    <!--                             v-else-if="$v.createBuyStopLimit.amount.$dirty && !$v.createBuyStopLimit.amount.required"-->
                    <!--                          >fields are required</p>-->
                    <!--                          <p class="market-order-error"-->
                    <!--                             v-else-if="!$v.createBuyStopLimit.amount.minValue"-->
                    <!--                          >Min value is a {{ $v.createBuyStopLimit.amount.$params.minValue.min }}</p>-->

                    <!--                          <p class="market-order-error"-->
                    <!--                             v-else-if="$v.createBuyStopLimit.stopPrice.$dirty && !$v.createBuyStopLimit.stopPrice.required"-->
                    <!--                          >fields are required</p>-->
                    <!--                          <p class="market-order-error"-->
                    <!--                             v-else-if="!$v.createBuyStopLimit.stopPrice.minValue"-->
                    <!--                          >Min value is a {{ $v.createBuyStopLimit.stopPrice.$params.minValue.min }}</p>-->
                    <!--                        </div>-->

                    <!--                      </form>-->

                    <!--                    </div>-->
                    <!--                    <div class="local_input">-->
                    <!--                      <div class="label_input">-->
                    <!--                        <div class="wrap_title_sell_buy">-->
                    <!--                          <span>Sell </span>-->
                    <!--                          <span class="pair_content_tab"-->
                    <!--                                v-text="currentCurrencyName"></span>-->
                    <!--                        </div>-->
                    <!--                      </div>-->

                    <!--                      <form class="market-order_sell-form">-->

                    <!--                        <div class="market-input-container">-->
                    <!--                          <div class="market-input-title">Price</div>-->
                    <!--                          <div class="market-input-wrap">-->
                    <!--                            <input class="market-input"-->
                    <!--                                   id="sellPriceStopLimit"-->
                    <!--                                   name="price"-->
                    <!--                                   type="number"-->
                    <!--                                   v-model="createSellStopLimit.price"-->
                    <!--                            >-->
                    <!--                            <span class="market-input-item">RR</span>-->
                    <!--                          </div>-->
                    <!--                        </div>-->

                    <!--                        <div class="market-input-container">-->
                    <!--                          <div class="market-input-title">Amount</div>-->
                    <!--                          <div class="market-input-wrap">-->
                    <!--                            <input class="market-input"-->
                    <!--                                   id="sellAmountStopLimit"-->
                    <!--                                   name="amount"-->
                    <!--                                   type="number"-->
                    <!--                                   v-model="createSellStopLimit.amount"-->

                    <!--                            >-->
                    <!--                            <span class="market-input-item"-->
                    <!--                                  v-text="currentCurrencyName"-->
                    <!--                            ></span>-->
                    <!--                          </div>-->
                    <!--                        </div>-->

                    <!--                        <div class="market-input-container">-->
                    <!--                          <div class="market-input-title">Stop price</div>-->
                    <!--                          <div class="market-input-wrap">-->
                    <!--                            <input class="market-input"-->
                    <!--                                   id="sellLimitStopLimit"-->
                    <!--                                   name="StopLimit"-->
                    <!--                                   type="number"-->
                    <!--                                   v-model="createSellStopLimit.stopPrice"-->
                    <!--                            >-->
                    <!--                            <span class="market-input-item">DJK</span>-->
                    <!--                          </div>-->
                    <!--                        </div>-->

                    <!--                        <div class="market-input-container">-->
                    <!--                          <div class="market-input-title">Total</div>-->
                    <!--                          <div class="market-input-wrap">-->
                    <!--                            <input class="market-input"-->
                    <!--                                   id="sellTotalStopLimit"-->
                    <!--                                   name="total"-->
                    <!--                                   type="number"-->
                    <!--                                   v-model="totalSellStopLimit"-->
                    <!--                            >-->
                    <!--                            <span class="market-input-item">DJK</span>-->
                    <!--                          </div>-->
                    <!--                        </div>-->

                    <!--                        <div v-if="isLogged === true">-->
                    <!--                          <a @click="stopLimitRequest(true)"-->
                    <!--                             class="submit_form_sell btn btn-hover open-popup-order">Order SELL</a>-->
                    <!--                        </div>-->

                    <!--                        <div v-if="isLogged === false">-->
                    <!--                          <router-link :to="{path: '/login'}"-->
                    <!--                                       class="submit_form_sell btn btn-hover">Login-->
                    <!--                            or Register to trade-->
                    <!--                          </router-link>-->
                    <!--                        </div>-->

                    <!--                        <div class="" v-if="!stopLimitFlag">-->
                    <!--                          <p class="market-order-error"-->
                    <!--                             v-if="$v.createSellStopLimit.price.$dirty && !$v.createSellStopLimit.price.required"-->
                    <!--                          >fields are required</p>-->
                    <!--                          <p class="market-order-error"-->
                    <!--                             v-else-if="!$v.createSellStopLimit.price.minValue"-->
                    <!--                          >Min value is a {{ $v.createSellStopLimit.price.$params.minValue.min }}</p>-->

                    <!--                          <p class="market-order-error"-->
                    <!--                             v-if="$v.createSellStopLimit.amount.$dirty && !$v.createSellStopLimit.amount.required"-->
                    <!--                          >fields are required</p>-->
                    <!--                          <p class="market-order-error"-->
                    <!--                             v-else-if="!$v.createSellStopLimit.amount.minValue"-->
                    <!--                          >Min value is a {{ $v.createSellStopLimit.amount.$params.minValue.min }}</p>-->

                    <!--                          <p class="market-order-error"-->
                    <!--                             v-if="$v.createSellStopLimit.stopPrice.$dirty && !$v.createSellStopLimit.stopPrice.required"-->
                    <!--                          >fields are required</p>-->
                    <!--                          <p class="market-order-error"-->
                    <!--                             v-else-if="!$v.createSellStopLimit.stopPrice.minValue"-->
                    <!--                          >Min value is a {{ $v.createSellStopLimit.stopPrice.$params.minValue.min }}</p>-->
                    <!--                        </div>-->

                    <!--                      </form>-->

                    <!--                    </div>-->
                    <!--                  </div>-->
                    <!--                </div>-->
                  </div>
                </div>
              </div>

              <div class="right-row">
                <h3 class="trading__block-title">Trade History</h3>
                <div class="trade-history-main-wrap">
                  <div class="chart-item-header">
                    <div class="col-left">
                      <span class="label">Price</span>
                    </div>
                    <div class="col-center">
                      <span class="label">Amount</span>
                    </div>
                    <div class="col-right">
                      <span class="label">Time</span>
                    </div>
                  </div>

                  <div class="trade-history-wrap">
                    <vuescroll :ops="ops" ref="TradeHistoryBlockScroll">
                      <TradingHistoryMarket
                        v-for="(history, index) in songHistory"
                        :key="index"
                        :history="history"
                      />
                    </vuescroll>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="isLogged && !fullChartFlag && screenWidthFlag === 'desktop'"
            class="trading-orders-table-wrap"
          >
            <div class="trading-orders-table-header">
              <button
                type="button"
                class="trading-orders-tab-btn"
                :class="{ active: orderTablesFlag === 'open' }"
                @click="toggleOrderTable('open')"
              >
                <span class="text-content">Open orders</span>
              </button>
              <button
                type="button"
                class="trading-orders-tab-btn"
                :class="{ active: orderTablesFlag === '24h' }"
                @click="toggleOrderTable('24h')"
              >
                <span class="text-content">My 24 hour order history</span>
              </button>
            </div>

            <div class="trading-orders-table" v-if="orderTablesFlag === 'open'">
              <div class="table-wrap" v-if="songOrders.openOrders.length">
                <table class="table orders-table trading-table">
                  <thead>
                    <tr>
                      <th class="left medium-col">Pair</th>
                      <th class="left">Type</th>
                      <th class="left">Side</th>
                      <th class="left medium-col">Price</th>
                      <th class="left">Amount</th>
                      <th class="center medium-col">Total</th>
                      <th class="center large-col">Trigger Conditions</th>
                      <th class="center">Date</th>
                      <th class="center medium-col">
                        <!--                      wait for back-end part-->

                        <!--                      <button type="button"-->
                        <!--                              @click="cancelAllOpenOrders"-->
                        <!--                              class="order-cancel-btn"-->
                        <!--                      >Cancel all-->
                        <!--                      </button>-->
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    class="trading-open-orders-wrapper"
                    :class="{
                      'no-scroll': songOrders.openOrders.length < 6,
                    }"
                  >
                    <vuescroll :ops="ops" ref="MyOrdersBlockScroll">
                      <TradingOrdersTableRow
                        v-for="(order, index) in songOrders.openOrders"
                        :key="index"
                        :order="order"
                        :current-name="currentCurrencyName"
                        :money-symbol="moneyCurrency.name"
                        :type-flag="orderTablesFlag"
                        @cancelOneOrder="cancelOneOrder"
                      />
                    </vuescroll>
                  </tbody>
                </table>
              </div>

              <div class="table-wrap" v-else>
                <table class="table orders-table trading-table">
                  <thead>
                    <tr>
                      <th class="left medium-col">Pair</th>
                      <th class="left">Type</th>
                      <th class="left">Side</th>
                      <th class="left medium-col">Price</th>
                      <th class="left">Amount</th>
                      <th class="center medium-col">Total</th>
                      <th class="center large-col">Trigger Conditions</th>
                      <th class="center">Date</th>
                      <th class="center medium-col"></th>
                    </tr>
                  </thead>
                  <tbody class="no-data-plug">
                    You dont have any orders yet
                  </tbody>
                </table>
              </div>
            </div>

            <div class="trading-orders-table" v-if="orderTablesFlag === '24h'">
              <div class="table-wrap" v-if="songOrders.twentyFourHOrder.length">
                <table class="table orders-table trading-table">
                  <thead>
                    <tr>
                      <th class="left medium-col">Pair</th>
                      <th class="left">Type</th>
                      <th class="left">Side</th>
                      <th class="left medium-col">Price</th>
                      <th class="left">Amount</th>
                      <th class="center medium-col">Total</th>
                      <th class="center large-col">Trigger Conditions</th>
                      <th class="center">Date</th>
                      <th class="center medium-col">
                        <!--                      wait for back-end part-->

                        <!--                      <button type="button"-->
                        <!--                              @click="cancelAll24hOrders"-->
                        <!--                              class="order-cancel-btn"-->
                        <!--                      >Cancel all-->
                        <!--                      </button>-->
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    class="trading-open-orders-wrapper"
                    :class="{
                      'no-scroll': songOrders.twentyFourHOrder.length < 6,
                    }"
                  >
                    <vuescroll :ops="ops" ref="MyOrders24HBlockScroll">
                      <TradingOrdersTableRow
                        v-for="(order, index) in songOrders.twentyFourHOrder"
                        :key="index"
                        :order="order"
                        :current-name="currentCurrencyName"
                        :money-symbol="moneyCurrency.name"
                        :type-flag="orderTablesFlag"
                        @cancelOneOrder="cancelOneOrder"
                      />
                    </vuescroll>
                  </tbody>
                </table>
              </div>

              <div class="table-wrap" v-else>
                <table class="table orders-table trading-table">
                  <thead>
                    <tr>
                      <th class="left medium-col">Pair</th>
                      <th class="left">Type</th>
                      <th class="left">Side</th>
                      <th class="left medium-col">Price</th>
                      <th class="left">Amount</th>
                      <th class="center medium-col">Total</th>
                      <th class="center large-col">Trigger Conditions</th>
                      <th class="center">Date</th>
                      <th class="center medium-col"></th>
                    </tr>
                  </thead>
                  <tbody class="no-data-plug">
                    You dont have any orders yet
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            class="trading__full-chart"
            v-if="fullChartFlag && screenWidthFlag === 'desktop'"
          >
            <trading-chart
              ref="TradingChart"
              class="js--trading-chart"
              :full-chart-flag="fullChartFlag"
              :currentWidth="currentWidth"
              :currentHeight="currentHeight"
              :candlesData="candles"
              :defaultRange="defaultRange"
              :timeFrame="candleTimeFrame"
              :lastCandle="lastCandle"
              :notEnoughDataFlag="notEnoughDataFlag"
              :userTimezone="userTimezone"
              @changeTimeFrame="changeTimeFrame"
              @resizeChart="resizeChart"
            ></trading-chart>
          </div>

          <div
            class="trading-main-container"
            v-if="screenWidthFlag === 'mobile'"
          >
            <ul class="trading-tab-list--mobile">
              <li
                class="trading-tab-list-item--mobile"
                :class="{ active: viewTab === 'chart' }"
                @click="viewTab = 'chart'"
              >
                <button class="tab-button">Chart</button>
              </li>
              <li
                class="trading-tab-list-item--mobile"
                :class="{ active: viewTab === 'order-book' }"
                @click="viewTab = 'order-book'"
              >
                <button class="tab-button">Order book</button>
              </li>
              <li
                class="trading-tab-list-item--mobile"
                :class="{ active: viewTab === 'trades' }"
                @click="viewTab = 'trades'"
              >
                <button class="tab-button">Trades</button>
              </li>
              <li
                class="trading-tab-list-item--mobile"
                :class="{ active: viewTab === 'news' }"
                @click="viewTab = 'news'"
              >
                <button class="tab-button">News</button>
              </li>
              <li
                class="trading-tab-list-item--mobile"
                :class="{ active: viewTab === 'coin-list' }"
                @click="viewTab = 'coin-list'"
              >
                <button class="tab-button">Coin List</button>
              </li>
            </ul>

            <div class="trading-content--mobile">
              <div v-if="viewTab === 'chart'" id="graph" class="block">
                <trading-chart
                  ref="TradingChart"
                  class="js--trading-chart"
                  :screen="screenWidthFlag"
                  :full-chart-flag="fullChartFlag"
                  :currentWidth="currentWidth"
                  :currentHeight="currentHeight"
                  :candlesData="candles"
                  :defaultRange="defaultRange"
                  :timeFrame="candleTimeFrame"
                  :lastCandle="lastCandle"
                  :notEnoughDataFlag="notEnoughDataFlag"
                  :userTimezone="userTimezone"
                  @changeTimeFrame="changeTimeFrame"
                  @resizeChart="resizeChart"
                ></trading-chart>
              </div>
              <div v-if="viewTab === 'chart'" class="SellBuy">
                <div class="title_sellbuy tab">
                  <button
                    class="clickTabs tab-links"
                    :class="{ active: toggleForms === 'limit' }"
                    @click="toggleForms = 'limit'"
                  >
                    Limit
                  </button>
                  <button
                    class="clickTabs tab-links"
                    :class="{ active: toggleForms === 'market' }"
                    @click="toggleForms = 'market'"
                  >
                    Market
                  </button>
                  <!--                  <div class="tab_sellbuy " :class="{active:toggleForms === 'stop-limit'}">-->
                  <!--                    <button class="clickTabs tablinks" @click="toggleForms = 'stop-limit'">Stop Limit</button>-->
                  <!--                  </div>-->
                </div>
                <div
                  v-if="toggleForms === 'limit'"
                  :class="{
                    'first-tab-active': toggleForms === 'limit',
                  }"
                  class="content-tabs"
                  id="Limit"
                >
                  <div class="main_input tab-content">
                    <div class="local_input">
                      <div class="label_input">
                        <div class="wrap_title_sell_buy">
                          <span>Buy </span>
                          <span
                            class="pair_content_tab"
                            v-text="currentCurrencyName"
                          ></span>
                        </div>
                      </div>

                      <form class="market-order_buy-form">
                        <div class="market-input-container">
                          <div class="market-input-title">Price</div>
                          <div class="market-input-wrap">
                            <input
                              class="market-input"
                              id="buyPrice"
                              name="price"
                              type="number"
                              v-model="createBuyLimit.price"
                            />
                            <span
                              class="market-input-item"
                              v-text="moneyCurrency.symbol"
                            ></span>
                          </div>

                          <div class="market-order-error" v-if="!limitAskFlag">
                            <p
                              class=""
                              v-if="
                                $v.createBuyLimit.price.$dirty &&
                                  !$v.createBuyLimit.price.required
                              "
                            >
                              This field is required
                            </p>
                            <p
                              class=""
                              v-else-if="
                                $v.createBuyLimit.price.$dirty &&
                                  !$v.createBuyLimit.price.minValue
                              "
                            >
                              Min price is a
                              {{ $v.createBuyLimit.price.$params.minValue.min }}
                            </p>
                            <p
                              v-else-if="
                                $v.createBuyLimit.price.$dirty &&
                                  !$v.createBuyLimit.price.maxDecimal
                              "
                            >
                              Numbers must be decimal
                            </p>
                          </div>
                        </div>

                        <div class="market-input-container">
                          <div class="market-input-title">Amount</div>
                          <div class="market-input-wrap">
                            <input
                              class="market-input"
                              id="buyAmount"
                              name="amount"
                              type="number"
                              v-model="createBuyLimit.amount"
                            />
                            <span
                              class="market-input-item"
                              v-text="mainCurrencyName"
                            ></span>
                          </div>

                          <div class="market-order-error" v-if="!limitAskFlag">
                            <p
                              v-if="
                                $v.createBuyLimit.amount.$dirty &&
                                  !$v.createBuyLimit.amount.required
                              "
                            >
                              This field is required
                            </p>
                            <p
                              v-else-if="
                                $v.createBuyLimit.amount.$dirty &&
                                  !$v.createBuyLimit.amount.minValue
                              "
                            >
                              Min amount is a
                              {{
                                $v.createBuyLimit.amount.$params.minValue.min
                              }}
                            </p>
                            <p
                              v-else-if="
                                $v.createBuyLimit.amount.$dirty &&
                                  !$v.createBuyLimit.amount.integer
                              "
                            >
                              Amount must be integer
                            </p>
                          </div>
                        </div>

                        <!--                            <div class="market__percentage-container" v-if="isLogged">-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 25)"-->
                        <!--                              >25%-->
                        <!--                              </button>-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 50)"-->
                        <!--                              >50%-->
                        <!--                              </button>-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 75)"-->
                        <!--                              >75%-->
                        <!--                              </button>-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 100)"-->
                        <!--                              >100%-->
                        <!--                              </button>-->
                        <!--                            </div>-->

                        <div class="market-input-container disabled">
                          <div class="market-input-title">Total</div>
                          <div class="market-input-wrap">
                            <div
                              class="market-input"
                              id="buyTotal"
                              type="number"
                              v-text="totalBuyLimit"
                            ></div>
                            <span
                              class="market-input-item"
                              v-text="moneyCurrency.symbol"
                            ></span>
                          </div>
                        </div>

                        <div class="market__form-btn-wrap">
                          <button
                            v-if="isLogged"
                            class="market__form-btn market__buy-btn"
                            @click.stop.prevent="limitOperation(false)"
                          >
                            Buy
                          </button>

                          <router-link
                            v-else
                            :to="{ path: '/login' }"
                            class="market__form-btn market__buy-btn"
                            >Log in to buy
                          </router-link>
                        </div>

                        <!--                        <div class="" v-if="limitAskFlag">-->
                        <!--                          <p class="market-order-error"-->
                        <!--                             v-if="$v.createBuyLimit.price.$dirty && !$v.createBuyLimit.price.required"-->
                        <!--                          >Price field is required</p>-->
                        <!--                          <p class="market-order-error"-->
                        <!--                             v-else-if="!$v.createBuyLimit.price.minValue"-->
                        <!--                          >Min value is a {{ $v.createBuyLimit.price.$params.minValue.min }}</p>-->

                        <!--                          <p class="market-order-error"-->
                        <!--                             v-else-if="$v.createBuyLimit.amount.$dirty && !$v.createBuyLimit.amount.required"-->
                        <!--                          >Amount in buy is required</p>-->
                        <!--                          <p class="market-order-error"-->
                        <!--                             v-else-if="!$v.createBuyLimit.amount.minValue"-->
                        <!--                          >Min value is a {{ $v.createBuyLimit.amount.$params.minValue.min }}</p>-->
                        <!--                        </div>-->
                      </form>
                    </div>
                    <div class="local_input">
                      <div class="label_input">
                        <div class="wrap_title_sell_buy">
                          <span>Sell </span>
                          <span
                            class="pair_content_tab"
                            v-text="currentCurrencyName"
                          ></span>
                        </div>
                      </div>

                      <form class="market-order_sell-form">
                        <div class="market-input-container">
                          <div class="market-input-title">Price</div>
                          <div class="market-input-wrap">
                            <input
                              class="market-input"
                              id="sellPrice"
                              name="price"
                              type="number"
                              v-model="createSellLimit.price"
                            />
                            <span
                              class="market-input-item"
                              v-text="moneyCurrency.symbol"
                            ></span>
                          </div>

                          <div class="market-order-error" v-if="limitAskFlag">
                            <p
                              v-if="
                                $v.createSellLimit.price.$dirty &&
                                  !$v.createSellLimit.price.required
                              "
                            >
                              This field is required
                            </p>
                            <p v-else-if="!$v.createSellLimit.price.minValue">
                              Min price is
                              {{
                                $v.createSellLimit.price.$params.minValue.min
                              }}
                            </p>
                            <p
                              v-else-if="
                                $v.createSellLimit.price.$dirty &&
                                  !$v.createSellLimit.price.maxDecimal
                              "
                            >
                              Numbers must be decimal
                            </p>
                          </div>
                        </div>

                        <div class="market-input-container">
                          <div class="market-input-title">Amount</div>
                          <div class="market-input-wrap">
                            <input
                              class="market-input"
                              id="sellAmount"
                              name="amount"
                              type="number"
                              v-model="createSellLimit.amount"
                            />
                            <span
                              class="market-input-item"
                              v-text="mainCurrencyName"
                            ></span>
                          </div>

                          <div class="market-order-error" v-if="limitAskFlag">
                            <p
                              v-if="
                                $v.createSellLimit.amount.$dirty &&
                                  !$v.createSellLimit.amount.required
                              "
                            >
                              This field is required
                            </p>
                            <p v-else-if="!$v.createSellLimit.amount.minValue">
                              Min amount is
                              {{
                                $v.createSellLimit.amount.$params.minValue.min
                              }}
                            </p>
                            <p
                              v-else-if="
                                $v.createSellLimit.amount.$dirty &&
                                  !$v.createSellLimit.amount.integer
                              "
                            >
                              Amount must be integer
                            </p>
                          </div>
                        </div>

                        <!--                            <div class="market__percentage-container" v-if="isLogged">-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                        <!--                                      :disabled="disablePercentBtn"-->
                        <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 25)"-->
                        <!--                              >25%-->
                        <!--                              </button>-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                        <!--                                      :disabled="disablePercentBtn"-->
                        <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 50)"-->
                        <!--                              >50%-->
                        <!--                              </button>-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                        <!--                                      :disabled="disablePercentBtn"-->
                        <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 75)"-->
                        <!--                              >75%-->
                        <!--                              </button>-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                        <!--                                      :disabled="disablePercentBtn"-->
                        <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 100)"-->
                        <!--                              >100%-->
                        <!--                              </button>-->
                        <!--                            </div>-->

                        <div class="market-input-container disabled">
                          <div class="market-input-title">Total</div>
                          <div class="market-input-wrap">
                            <div
                              class="market-input"
                              id="sellTotalPrice"
                              type="number"
                              v-text="totalSellLimit"
                            ></div>
                            <span
                              class="market-input-item"
                              v-text="moneyCurrency.symbol"
                            ></span>
                          </div>
                        </div>

                        <div class="market__form-btn-wrap">
                          <button
                            v-if="isLogged"
                            class="market__form-btn market__sell-btn"
                            @click.stop.prevent="limitOperation(true)"
                          >
                            Sell
                          </button>

                          <router-link
                            v-else
                            :to="{ path: '/login' }"
                            class="market__form-btn market__sell-btn"
                            >Log in to sell
                          </router-link>
                        </div>

                        <!--                        <div class="" v-if="!limitAskFlag">-->
                        <!--                          <p class="market-order-error"-->
                        <!--                             v-if="$v.createSellLimit.price.$dirty && !$v.createSellLimit.price.required"-->
                        <!--                          >Price in sell is required</p>-->
                        <!--                          <p class="market-order-error"-->
                        <!--                             v-else-if="!$v.createSellLimit.price.minValue"-->
                        <!--                          >Min value is {{ $v.createSellLimit.price.$params.minValue.min }}</p>-->

                        <!--                          <p class="market-order-error"-->
                        <!--                             v-else-if="$v.createSellLimit.amount.$dirty && !$v.createSellLimit.amount.required"-->
                        <!--                          >Amount in sell is required</p>-->
                        <!--                          <p class="market-order-error"-->
                        <!--                             v-else-if="!$v.createSellLimit.amount.minValue"-->
                        <!--                          >Min value is {{ $v.createSellLimit.amount.$params.minValue.min }}</p>-->
                        <!--                        </div>-->
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  v-if="toggleForms === 'market'"
                  class="content-tabs"
                  id="Market"
                >
                  <div class="main_input tab-content">
                    <div class="local_input">
                      <div class="label_input">
                        <div class="wrap_title_sell_buy">
                          <span>Buy </span>
                          <span
                            class="pair_content_tab"
                            v-text="currentCurrencyName"
                          ></span>
                        </div>
                      </div>

                      <form class="market-order_buy-form">
                        <div class="market-input-container disabled">
                          <div class="market-input-title">Price</div>
                          <div class="market-input-wrap">
                            <div
                              class="market-input"
                              id="buyPriceMarket"
                              v-text="`~ ${songPrice.lastPrice.toFixed(2)}`"
                            ></div>
                            <span
                              class="market-input-item"
                              v-text="moneyCurrency.symbol"
                            ></span>
                          </div>
                        </div>

                        <div class="market-input-container">
                          <div class="market-input-title">Amount</div>
                          <div class="market-input-wrap">
                            <input
                              class="market-input"
                              id="buyAmountMarket"
                              name="amount"
                              type="number"
                              v-model="createBuyMarket.amount"
                            />
                            <span
                              class="market-input-item"
                              v-text="mainCurrencyName"
                            >
                            </span>
                          </div>

                          <div class="market-order-error" v-if="!marketAskFlag">
                            <p
                              v-if="
                                $v.createBuyMarket.amount.$dirty &&
                                  !$v.createBuyMarket.amount.required
                              "
                            >
                              This field is required
                            </p>
                            <p
                              v-else-if="
                                $v.createBuyMarket.amount.$dirty &&
                                  !$v.createBuyMarket.amount.minValue
                              "
                            >
                              Min amount is a
                              {{
                                $v.createBuyMarket.amount.$params.minValue.min
                              }}
                            </p>
                            <p
                              v-else-if="
                                $v.createBuyMarket.amount.$dirty &&
                                  !$v.createBuyMarket.amount.integer
                              "
                            >
                              Amount must be integer
                            </p>
                          </div>
                        </div>

                        <!--                            <div class="market__percentage-container" v-if="isLogged">-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 25)"-->
                        <!--                              >25%-->
                        <!--                              </button>-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 50)"-->
                        <!--                              >50%-->
                        <!--                              </button>-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 75)"-->
                        <!--                              >75%-->
                        <!--                              </button>-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      @click="getPercentageOfAvailableBuy(toggleForms, 100)"-->
                        <!--                              >100%-->
                        <!--                              </button>-->
                        <!--                            </div>-->

                        <div class="market-input-container disabled">
                          <div class="market-input-title">Total</div>
                          <div class="market-input-wrap">
                            <div
                              class="market-input"
                              id="buyTotalMarket"
                              type="number"
                              v-text="totalBuyMarket"
                            ></div>
                            <span
                              class="market-input-item"
                              v-text="moneyCurrency.symbol"
                            ></span>
                          </div>
                        </div>

                        <div class="market__form-btn-wrap">
                          <!--                              <button v-if="isLogged"-->
                          <!--                                      class="market__form-btn market__buy-btn"-->
                          <!--                                      @click.stop.prevent="marketRequest(false)"-->
                          <!--                              >Buy</button>-->
                          <button
                            v-if="isLogged"
                            class="market__form-btn market__buy-btn"
                            @click.stop.prevent="marketOperation(false)"
                          >
                            Buy
                          </button>

                          <router-link
                            v-else
                            :to="{ path: '/login' }"
                            class="market__form-btn market__buy-btn"
                            >Log in to buy
                          </router-link>
                        </div>

                        <!--                        <div class="" v-if="marketAskFlag">-->
                        <!--                          <p class="market-order-error"-->
                        <!--                             v-if="$v.createBuyMarket.amount.$dirty && !$v.createBuyMarket.amount.required"-->
                        <!--                          >fields are required</p>-->
                        <!--                          <p class="market-order-error"-->
                        <!--                             v-else-if="!$v.createBuyMarket.amount.minValue"-->
                        <!--                          >Min value is a {{ $v.createBuyMarket.amount.$params.minValue.min }}</p>-->
                        <!--                        </div>-->
                      </form>
                    </div>
                    <div class="local_input">
                      <div class="label_input">
                        <div class="wrap_title_sell_buy">
                          <span>Sell </span>
                          <span
                            class="pair_content_tab"
                            v-text="currentCurrencyName"
                          ></span>
                        </div>
                      </div>

                      <form class="market-order_sell-form">
                        <div class="market-input-container disabled">
                          <div class="market-input-title">Price</div>
                          <div class="market-input-wrap">
                            <div
                              class="market-input"
                              id="sellPriceMarket"
                              v-text="`~ ${songPrice.lastPrice.toFixed(2)}`"
                            ></div>
                            <span
                              class="market-input-item"
                              v-text="moneyCurrency.symbol"
                            ></span>
                          </div>
                        </div>

                        <div class="market-input-container">
                          <div class="market-input-title">Amount</div>
                          <div class="market-input-wrap">
                            <input
                              class="market-input"
                              id="sellAmountMarket"
                              name="amount"
                              type="number"
                              v-model="createSellMarket.amount"
                            />
                            <span
                              class="market-input-item"
                              v-text="mainCurrencyName"
                            ></span>
                          </div>

                          <div class="market-order-error" v-if="marketAskFlag">
                            <p
                              v-if="
                                $v.createSellMarket.amount.$dirty &&
                                  !$v.createSellMarket.amount.required
                              "
                            >
                              This field is required
                            </p>
                            <p
                              v-else-if="
                                $v.createSellMarket.amount.$dirty &&
                                  !$v.createSellMarket.amount.minValue
                              "
                            >
                              Min amount is a
                              {{
                                $v.createSellMarket.amount.$params.minValue.min
                              }}
                            </p>
                            <p
                              v-else-if="
                                $v.createSellMarket.amount.$dirty &&
                                  !$v.createSellMarket.amount.integer
                              "
                            >
                              Amount must be integer
                            </p>
                          </div>
                        </div>

                        <!--                            <div class="market__percentage-container" v-if="isLogged">-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                        <!--                                      :disabled="disablePercentBtn"-->
                        <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 25)"-->
                        <!--                              >25%-->
                        <!--                              </button>-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                        <!--                                      :disabled="disablePercentBtn"-->
                        <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 50)"-->
                        <!--                              >50%-->
                        <!--                              </button>-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                        <!--                                      :disabled="disablePercentBtn"-->
                        <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 75)"-->
                        <!--                              >75%-->
                        <!--                              </button>-->
                        <!--                              <button class="market__percentage-item"-->
                        <!--                                      type="button"-->
                        <!--                                      :class="{'disabled-btn': disablePercentBtn}"-->
                        <!--                                      :disabled="disablePercentBtn"-->
                        <!--                                      @click="getPercentageOfAvailableSell(toggleForms, 100)"-->
                        <!--                              >100%-->
                        <!--                              </button>-->
                        <!--                            </div>-->

                        <div class="market-input-container disabled">
                          <div class="market-input-title">Total</div>
                          <div class="market-input-wrap">
                            <div
                              class="market-input"
                              id="sellTotalMarket"
                              type="number"
                              v-text="totalSellMarket"
                            ></div>
                            <span
                              class="market-input-item"
                              v-text="moneyCurrency.symbol"
                            ></span>
                          </div>
                        </div>

                        <div class="market__form-btn-wrap">
                          <!--                              <button v-if="isLogged"-->
                          <!--                                      class="market__form-btn market__sell-btn"-->
                          <!--                                      @click.stop.prevent="marketRequest(true)"-->
                          <!--                              >Sell</button>-->
                          <button
                            v-if="isLogged"
                            class="market__form-btn market__sell-btn"
                            @click.stop.prevent="marketOperation(true)"
                          >
                            Sell
                          </button>

                          <router-link
                            v-else
                            :to="{ path: '/login' }"
                            class="market__form-btn market__sell-btn"
                            >Log in to sell
                          </router-link>
                        </div>

                        <!--                        <div class="" v-if="!marketAskFlag">-->
                        <!--                          <p class="market-order-error"-->
                        <!--                             v-if="$v.createSellMarket.amount.$dirty && !$v.createSellMarket.amount.required"-->
                        <!--                          >fields are required</p>-->
                        <!--                          <p class="market-order-error"-->
                        <!--                             v-else-if="!$v.createSellMarket.amount.minValue"-->
                        <!--                          >Min value is a {{ $v.createSellMarket.amount.$params.minValue.min }}</p>-->
                        <!--                        </div>-->
                      </form>
                    </div>
                  </div>
                </div>
                <!--                <div v-if="toggleForms === 'stop-limit'" class="content-tabs" id="StopLimit">-->
                <!--                  <div class="main_input tabcontent">-->
                <!--                    <div class="local_input">-->
                <!--                      <div class="label_input">-->
                <!--                        <div class="wrap_title_sell_buy">-->
                <!--                          <span>Buy </span>-->
                <!--                          <span class="pair_content_tab"-->
                <!--                                v-text="currentCurrencyName"></span>-->
                <!--                        </div>-->
                <!--                      </div>-->

                <!--                      <form class="market-order_buy-form">-->

                <!--                        <div class="market-input-container">-->
                <!--                          <div class="market-input-title">Price</div>-->
                <!--                          <div class="market-input-wrap">-->
                <!--                            <input class="market-input"-->
                <!--                                   id="buyPriceStopLimit"-->
                <!--                                   name="price"-->
                <!--                                   type="number"-->
                <!--                                   v-model="createBuyStopLimit.price"-->
                <!--                            >-->
                <!--                            <span class="market-input-item">DJK</span>-->
                <!--                          </div>-->
                <!--                        </div>-->

                <!--                        <div class="market-input-container">-->
                <!--                          <div class="market-input-title">Amount</div>-->
                <!--                          <div class="market-input-wrap">-->
                <!--                            <input class="market-input"-->
                <!--                                   id="buyAmountStopLimit"-->
                <!--                                   name="amount"-->
                <!--                                   type="number"-->
                <!--                                   v-model="createBuyStopLimit.amount"-->
                <!--                            >-->
                <!--                            <span class="market-input-item"-->
                <!--                                  v-text="currentCurrencyName"-->
                <!--                            ></span>-->
                <!--                          </div>-->
                <!--                        </div>-->

                <!--                        <div class="market-input-container">-->
                <!--                          <div class="market-input-title">Stop price</div>-->
                <!--                          <div class="market-input-wrap">-->
                <!--                            <input class="market-input"-->
                <!--                                   id="buyLimitStopLimit"-->
                <!--                                   name="StopLimit"-->
                <!--                                   type="number"-->
                <!--                                   v-model="createBuyStopLimit.stopPrice"-->
                <!--                            >-->
                <!--                            <span class="market-input-item">DJK</span>-->
                <!--                          </div>-->
                <!--                        </div>-->

                <!--                        <div class="market-input-container">-->
                <!--                          <div class="market-input-title">Total</div>-->
                <!--                          <div class="market-input-wrap">-->
                <!--                            <input class="market-input"-->
                <!--                                   id="buyTotalStopLimit"-->
                <!--                                   name="total"-->
                <!--                                   type="number"-->
                <!--                                   v-model="totalBuyStopLimit"-->
                <!--                            >-->
                <!--                            <span class="market-input-item">DJK</span>-->
                <!--                          </div>-->
                <!--                        </div>-->

                <!--                        <div v-if="isLogged === true">-->
                <!--                          <a class="submit_form_sell submit_form_buy btn btn-hover open-popup-order"-->
                <!--                             @click="stopLimitRequest(false)">Order BUY</a>-->
                <!--                        </div>-->

                <!--                        <div v-if="isLogged === false">-->
                <!--                          <router-link :to="{path: '/login'}"-->
                <!--                                       class="submit_form_sell submit_form_buy btn btn-hover"-->
                <!--                          >Login-->
                <!--                            or Register to trade-->
                <!--                          </router-link>-->
                <!--                        </div>-->

                <!--                        <div class="" v-if="stopLimitFlag">-->
                <!--                          <p class="market-order-error"-->
                <!--                             v-if="$v.createBuyStopLimit.price.$dirty && !$v.createBuyStopLimit.price.required"-->
                <!--                          >fields are required</p>-->
                <!--                          <p class="market-order-error"-->
                <!--                             v-else-if="!$v.createBuyStopLimit.price.minValue"-->
                <!--                          >Min value is a {{ $v.createBuyStopLimit.price.$params.minValue.min }}</p>-->

                <!--                          <p class="market-order-error"-->
                <!--                             v-else-if="$v.createBuyStopLimit.amount.$dirty && !$v.createBuyStopLimit.amount.required"-->
                <!--                          >fields are required</p>-->
                <!--                          <p class="market-order-error"-->
                <!--                             v-else-if="!$v.createBuyStopLimit.amount.minValue"-->
                <!--                          >Min value is a {{ $v.createBuyStopLimit.amount.$params.minValue.min }}</p>-->

                <!--                          <p class="market-order-error"-->
                <!--                             v-else-if="$v.createBuyStopLimit.stopPrice.$dirty && !$v.createBuyStopLimit.stopPrice.required"-->
                <!--                          >fields are required</p>-->
                <!--                          <p class="market-order-error"-->
                <!--                             v-else-if="!$v.createBuyStopLimit.stopPrice.minValue"-->
                <!--                          >Min value is a {{ $v.createBuyStopLimit.stopPrice.$params.minValue.min }}</p>-->
                <!--                        </div>-->

                <!--                      </form>-->

                <!--                    </div>-->
                <!--                    <div class="local_input">-->
                <!--                      <div class="label_input">-->
                <!--                        <div class="wrap_title_sell_buy">-->
                <!--                          <span>Sell </span>-->
                <!--                          <span class="pair_content_tab"-->
                <!--                                v-text="currentCurrencyName"></span>-->
                <!--                        </div>-->
                <!--                      </div>-->

                <!--                      <form class="market-order_sell-form">-->

                <!--                        <div class="market-input-container">-->
                <!--                          <div class="market-input-title">Price</div>-->
                <!--                          <div class="market-input-wrap">-->
                <!--                            <input class="market-input"-->
                <!--                                   id="sellPriceStopLimit"-->
                <!--                                   name="price"-->
                <!--                                   type="number"-->
                <!--                                   v-model="createSellStopLimit.price"-->
                <!--                            >-->
                <!--                            <span class="market-input-item">RR</span>-->
                <!--                          </div>-->
                <!--                        </div>-->

                <!--                        <div class="market-input-container">-->
                <!--                          <div class="market-input-title">Amount</div>-->
                <!--                          <div class="market-input-wrap">-->
                <!--                            <input class="market-input"-->
                <!--                                   id="sellAmountStopLimit"-->
                <!--                                   name="amount"-->
                <!--                                   type="number"-->
                <!--                                   v-model="createSellStopLimit.amount"-->

                <!--                            >-->
                <!--                            <span class="market-input-item"-->
                <!--                                  v-text="currentCurrencyName"-->
                <!--                            ></span>-->
                <!--                          </div>-->
                <!--                        </div>-->

                <!--                        <div class="market-input-container">-->
                <!--                          <div class="market-input-title">Stop price</div>-->
                <!--                          <div class="market-input-wrap">-->
                <!--                            <input class="market-input"-->
                <!--                                   id="sellLimitStopLimit"-->
                <!--                                   name="StopLimit"-->
                <!--                                   type="number"-->
                <!--                                   v-model="createSellStopLimit.stopPrice"-->
                <!--                            >-->
                <!--                            <span class="market-input-item">DJK</span>-->
                <!--                          </div>-->
                <!--                        </div>-->

                <!--                        <div class="market-input-container">-->
                <!--                          <div class="market-input-title">Total</div>-->
                <!--                          <div class="market-input-wrap">-->
                <!--                            <input class="market-input"-->
                <!--                                   id="sellTotalStopLimit"-->
                <!--                                   name="total"-->
                <!--                                   type="number"-->
                <!--                                   v-model="totalSellStopLimit"-->
                <!--                            >-->
                <!--                            <span class="market-input-item">DJK</span>-->
                <!--                          </div>-->
                <!--                        </div>-->

                <!--                        <div v-if="isLogged === true">-->
                <!--                          <a @click="stopLimitRequest(true)"-->
                <!--                             class="submit_form_sell btn btn-hover open-popup-order">Order SELL</a>-->
                <!--                        </div>-->

                <!--                        <div v-if="isLogged === false">-->
                <!--                          <router-link :to="{path: '/login'}"-->
                <!--                                       class="submit_form_sell btn btn-hover">Login-->
                <!--                            or Register to trade-->
                <!--                          </router-link>-->
                <!--                        </div>-->

                <!--                        <div class="" v-if="!stopLimitFlag">-->
                <!--                          <p class="market-order-error"-->
                <!--                             v-if="$v.createSellStopLimit.price.$dirty && !$v.createSellStopLimit.price.required"-->
                <!--                          >fields are required</p>-->
                <!--                          <p class="market-order-error"-->
                <!--                             v-else-if="!$v.createSellStopLimit.price.minValue"-->
                <!--                          >Min value is a {{ $v.createSellStopLimit.price.$params.minValue.min }}</p>-->

                <!--                          <p class="market-order-error"-->
                <!--                             v-if="$v.createSellStopLimit.amount.$dirty && !$v.createSellStopLimit.amount.required"-->
                <!--                          >fields are required</p>-->
                <!--                          <p class="market-order-error"-->
                <!--                             v-else-if="!$v.createSellStopLimit.amount.minValue"-->
                <!--                          >Min value is a {{ $v.createSellStopLimit.amount.$params.minValue.min }}</p>-->

                <!--                          <p class="market-order-error"-->
                <!--                             v-if="$v.createSellStopLimit.stopPrice.$dirty && !$v.createSellStopLimit.stopPrice.required"-->
                <!--                          >fields are required</p>-->
                <!--                          <p class="market-order-error"-->
                <!--                             v-else-if="!$v.createSellStopLimit.stopPrice.minValue"-->
                <!--                          >Min value is a {{ $v.createSellStopLimit.stopPrice.$params.minValue.min }}</p>-->
                <!--                        </div>-->

                <!--                      </form>-->

                <!--                    </div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
              <div
                v-if="viewTab === 'order-book'"
                class="order-books-main-wrap"
              >
                <div class="chart-item-header">
                  <span
                    class="label order-books-price-title"
                    v-text="`Price (${moneyCurrency.symbol})`"
                  ></span>
                  <span
                    class="label order-books-amount-title"
                    v-text="`Amount (${currentCurrencyName})`"
                  ></span>
                  <span
                    class="label order-books-total-title"
                    v-text="`Total (${moneyCurrency.symbol})`"
                  ></span>
                </div>

                <div class="chart-item-content">
                  <div class="order-books order-books-isAskTrue">
                    <TradingOrders
                      v-for="(order, index) in orderBookTrue"
                      :key="index"
                      :order="order"
                    />
                  </div>

                  <div class="last-price-item">
                    <span
                      class="market-price-value"
                      v-text="
                        `${moneyCurrency.symbol} ${songPrice.lastPrice.toFixed(
                          2
                        )}`
                      "
                    ></span>
                  </div>

                  <div class="order-books order-books-isAskFalse">
                    <TradingOrdersFalse
                      v-for="(order, index) in orderBookFalse"
                      :key="index"
                      :order="order"
                    />
                  </div>
                </div>
              </div>
              <div v-if="viewTab === 'news'" class="trading-news-wrap">
                <vuescroll :ops="ops" ref="TradingNewsBlockScroll">
                  <TradingNews
                    v-for="(news, index) in newsList"
                    :key="index"
                    :news="news"
                  />
                </vuescroll>
              </div>
              <div v-if="viewTab === 'trades'" class="trade-history-main-wrap">
                <div class="chart-item-header">
                  <div class="col-left">
                    <span class="label">Price</span>
                  </div>
                  <div class="col-center">
                    <span class="label">Amount</span>
                  </div>
                  <div class="col-right">
                    <span class="label">Time</span>
                  </div>
                </div>

                <div class="trade-history-wrap">
                  <vuescroll :ops="ops" ref="TradeHistoryBlockScroll">
                    <TradingHistoryMarket
                      v-for="(history, index) in songHistory"
                      :key="index"
                      :history="history"
                    />
                  </vuescroll>
                </div>
              </div>
              <div v-if="viewTab === 'coin-list'" class="pair-main-wrapper">
                <div class="chart-item-header">
                  <div class="col-left">
                    <button
                      class="pair-header-title"
                      :class="{ active: sortByType === 'name' }"
                      type="button"
                      @click="sortPairByName"
                    >
                      <span class="label">Pair</span>

                      <svg
                        class="button-icon"
                        width="6"
                        height="11"
                        viewBox="0 0 6 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_9411_13051)">
                          <path
                            :class="{
                              'active-high':
                                !marketPricesBody.isSortNameAsc &&
                                sortByType === 'name',
                            }"
                            d="M2.35793 0.884691L0.41689 4.12511C0.37687 4.19441 0.355695 4.27299 0.355471 4.35302C0.355246 4.43305 0.375981 4.51174 0.415612 4.58127C0.455244 4.6508 0.512389 4.70874 0.581364 4.74932C0.65034 4.78991 0.72874 4.81173 0.808765 4.81261H4.69085C4.77087 4.81173 4.84927 4.78991 4.91825 4.74932C4.98722 4.70874 5.04437 4.6508 5.084 4.58127C5.12363 4.51174 5.14437 4.43305 5.14414 4.35302C5.14392 4.27299 5.12274 4.19441 5.08272 4.12511L3.14168 0.884691C3.10083 0.817341 3.04331 0.761656 2.97466 0.723011C2.90602 0.684365 2.82858 0.664062 2.74981 0.664062C2.67103 0.664063 2.59359 0.684365 2.52495 0.723011C2.45631 0.761656 2.39879 0.817341 2.35793 0.884691Z"
                            fill="#868686"
                          />
                        </g>
                        <g clip-path="url(#clip1_9411_13051)">
                          <path
                            :class="{
                              'active-low':
                                marketPricesBody.isSortNameAsc &&
                                sortByType === 'name',
                            }"
                            d="M3.14207 10.1153L5.08311 6.87489C5.12313 6.80559 5.14431 6.72701 5.14453 6.64698C5.14475 6.56695 5.12402 6.48826 5.08439 6.41873C5.04476 6.3492 4.98761 6.29126 4.91864 6.25068C4.84966 6.21009 4.77126 6.18827 4.69124 6.18739L0.809152 6.18739C0.729127 6.18827 0.650727 6.21009 0.581752 6.25068C0.512777 6.29126 0.455631 6.3492 0.416 6.41873C0.376369 6.48826 0.355634 6.56695 0.355858 6.64698C0.356082 6.72701 0.377258 6.80559 0.417277 6.87489L2.35832 10.1153C2.39917 10.1827 2.45669 10.2383 2.52534 10.277C2.59398 10.3156 2.67142 10.3359 2.75019 10.3359C2.82897 10.3359 2.90641 10.3156 2.97505 10.277C3.04369 10.2383 3.10121 10.1827 3.14207 10.1153Z"
                            fill="#868686"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_9411_13051">
                            <rect width="5.5" height="5.5" fill="white" />
                          </clipPath>
                          <clipPath id="clip1_9411_13051">
                            <rect
                              width="5.5"
                              height="5.5"
                              fill="white"
                              transform="translate(5.5 11) rotate(-180)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>

                  <div class="col-center">
                    <button
                      class="pair-header-title"
                      :class="{ active: sortByType === 'price' }"
                      type="button"
                      @click="sortPairByPrice"
                    >
                      <span class="label">Price</span>

                      <svg
                        class="button-icon"
                        width="6"
                        height="11"
                        viewBox="0 0 6 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_9411_13051)">
                          <path
                            :class="{
                              'active-high':
                                !marketPricesBody.isSortPriceAsc &&
                                sortByType === 'price',
                            }"
                            d="M2.35793 0.884691L0.41689 4.12511C0.37687 4.19441 0.355695 4.27299 0.355471 4.35302C0.355246 4.43305 0.375981 4.51174 0.415612 4.58127C0.455244 4.6508 0.512389 4.70874 0.581364 4.74932C0.65034 4.78991 0.72874 4.81173 0.808765 4.81261H4.69085C4.77087 4.81173 4.84927 4.78991 4.91825 4.74932C4.98722 4.70874 5.04437 4.6508 5.084 4.58127C5.12363 4.51174 5.14437 4.43305 5.14414 4.35302C5.14392 4.27299 5.12274 4.19441 5.08272 4.12511L3.14168 0.884691C3.10083 0.817341 3.04331 0.761656 2.97466 0.723011C2.90602 0.684365 2.82858 0.664062 2.74981 0.664062C2.67103 0.664063 2.59359 0.684365 2.52495 0.723011C2.45631 0.761656 2.39879 0.817341 2.35793 0.884691Z"
                            fill="#868686"
                          />
                        </g>
                        <g clip-path="url(#clip1_9411_13051)">
                          <path
                            :class="{
                              'active-low':
                                marketPricesBody.isSortPriceAsc &&
                                sortByType === 'price',
                            }"
                            d="M3.14207 10.1153L5.08311 6.87489C5.12313 6.80559 5.14431 6.72701 5.14453 6.64698C5.14475 6.56695 5.12402 6.48826 5.08439 6.41873C5.04476 6.3492 4.98761 6.29126 4.91864 6.25068C4.84966 6.21009 4.77126 6.18827 4.69124 6.18739L0.809152 6.18739C0.729127 6.18827 0.650727 6.21009 0.581752 6.25068C0.512777 6.29126 0.455631 6.3492 0.416 6.41873C0.376369 6.48826 0.355634 6.56695 0.355858 6.64698C0.356082 6.72701 0.377258 6.80559 0.417277 6.87489L2.35832 10.1153C2.39917 10.1827 2.45669 10.2383 2.52534 10.277C2.59398 10.3156 2.67142 10.3359 2.75019 10.3359C2.82897 10.3359 2.90641 10.3156 2.97505 10.277C3.04369 10.2383 3.10121 10.1827 3.14207 10.1153Z"
                            fill="#868686"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_9411_13051">
                            <rect width="5.5" height="5.5" fill="white" />
                          </clipPath>
                          <clipPath id="clip1_9411_13051">
                            <rect
                              width="5.5"
                              height="5.5"
                              fill="white"
                              transform="translate(5.5 11) rotate(-180)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>

                  <div class="col-right">
                    <button
                      class="pair-header-title"
                      :class="{ active: sortByType === 'changes' }"
                      type="button"
                      @click="sortPairByChanges24h"
                    >
                      <span class="label">Change</span>

                      <svg
                        class="button-icon"
                        width="6"
                        height="11"
                        viewBox="0 0 6 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_9411_13051)">
                          <path
                            :class="{
                              'active-high':
                                !marketPricesBody.isSortChange24hAsc &&
                                sortByType === 'changes',
                            }"
                            d="M2.35793 0.884691L0.41689 4.12511C0.37687 4.19441 0.355695 4.27299 0.355471 4.35302C0.355246 4.43305 0.375981 4.51174 0.415612 4.58127C0.455244 4.6508 0.512389 4.70874 0.581364 4.74932C0.65034 4.78991 0.72874 4.81173 0.808765 4.81261H4.69085C4.77087 4.81173 4.84927 4.78991 4.91825 4.74932C4.98722 4.70874 5.04437 4.6508 5.084 4.58127C5.12363 4.51174 5.14437 4.43305 5.14414 4.35302C5.14392 4.27299 5.12274 4.19441 5.08272 4.12511L3.14168 0.884691C3.10083 0.817341 3.04331 0.761656 2.97466 0.723011C2.90602 0.684365 2.82858 0.664062 2.74981 0.664062C2.67103 0.664063 2.59359 0.684365 2.52495 0.723011C2.45631 0.761656 2.39879 0.817341 2.35793 0.884691Z"
                            fill="#868686"
                          />
                        </g>
                        <g clip-path="url(#clip1_9411_13051)">
                          <path
                            :class="{
                              'active-low':
                                marketPricesBody.isSortChange24hAsc &&
                                sortByType === 'changes',
                            }"
                            d="M3.14207 10.1153L5.08311 6.87489C5.12313 6.80559 5.14431 6.72701 5.14453 6.64698C5.14475 6.56695 5.12402 6.48826 5.08439 6.41873C5.04476 6.3492 4.98761 6.29126 4.91864 6.25068C4.84966 6.21009 4.77126 6.18827 4.69124 6.18739L0.809152 6.18739C0.729127 6.18827 0.650727 6.21009 0.581752 6.25068C0.512777 6.29126 0.455631 6.3492 0.416 6.41873C0.376369 6.48826 0.355634 6.56695 0.355858 6.64698C0.356082 6.72701 0.377258 6.80559 0.417277 6.87489L2.35832 10.1153C2.39917 10.1827 2.45669 10.2383 2.52534 10.277C2.59398 10.3156 2.67142 10.3359 2.75019 10.3359C2.82897 10.3359 2.90641 10.3156 2.97505 10.277C3.04369 10.2383 3.10121 10.1827 3.14207 10.1153Z"
                            fill="#868686"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_9411_13051">
                            <rect width="5.5" height="5.5" fill="white" />
                          </clipPath>
                          <clipPath id="clip1_9411_13051">
                            <rect
                              width="5.5"
                              height="5.5"
                              fill="white"
                              transform="translate(5.5 11) rotate(-180)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="pair-header">
                  <div class="pair-header__btn-wrap" v-if="isLogged">
                    <button
                      type="button"
                      class="pair-head-btn pair-header-all"
                      @click="getAllPairs"
                      :class="{
                        active: !marketPricesBody.isOnlyFavorite,
                      }"
                    >
                      <span class="pair-head-btn__text">all</span>
                    </button>

                    <button
                      type="button"
                      class="pair-head-btn pair-header-favorite"
                      @click="getFavoritePairs"
                      :class="{
                        active: marketPricesBody.isOnlyFavorite,
                      }"
                    >
                      <svg
                        width="17"
                        height="15"
                        viewBox="0 0 17 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.7738 14.98C12.6474 14.9806 12.5228 14.9508 12.4103 14.8933L8.38074 12.7886L4.35115 14.8933C4.0842 15.0334 3.76061 15.0096 3.51713 14.832C3.27364 14.6544 3.15273 14.354 3.20548 14.0577L3.9956 9.61958L0.740323 6.46636C0.533357 6.2603 0.45737 5.95705 0.542793 5.67805C0.63622 5.39223 0.884458 5.18431 1.18279 5.14201L5.68645 4.48771L7.66963 0.443714C7.80165 0.171756 8.07787 -0.000976801 8.38074 -0.000976562C8.68361 -0.000976562 8.95982 0.171757 9.09184 0.443714L11.0987 4.47983L15.6024 5.13412C15.9007 5.17643 16.149 5.38435 16.2424 5.67017C16.3278 5.94916 16.2518 6.25241 16.0449 6.45847L12.7896 9.61169L13.5797 14.0498C13.6373 14.3516 13.5139 14.6593 13.2636 14.8381C13.1206 14.9382 12.9483 14.9881 12.7738 14.98H12.7738Z"
                        />
                      </svg>
                    </button>
                  </div>

                  <form
                    class="pair-search-wrap"
                    :class="{ 'full-width': !isLogged }"
                  >
                    <input
                      type="text"
                      class="pair-search-input"
                      placeholder="Search..."
                      v-model="marketPricesBody.searchText"
                    />
                    <svg
                      class="search-icon"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.5325 12.4675L10.9825 9.925C11.8052 8.87684 12.2517 7.58249 12.25 6.25C12.25 2.93629 9.56371 0.25 6.25 0.25C2.93629 0.25 0.25 2.93629 0.25 6.25C0.25 9.56371 2.93629 12.25 6.25 12.25C7.58249 12.2517 8.87684 11.8052 9.925 10.9825L12.4675 13.5325C12.6083 13.6745 12.8 13.7543 13 13.7543C13.2 13.7543 13.3917 13.6745 13.5325 13.5325C13.6745 13.3917 13.7544 13.2 13.7544 13C13.7544 12.8 13.6745 12.6083 13.5325 12.4675ZM1.75 6.25C1.75 3.76472 3.76472 1.75 6.25 1.75C8.73528 1.75 10.75 3.76472 10.75 6.25C10.75 8.73528 8.73528 10.75 6.25 10.75C3.76472 10.75 1.75 8.73528 1.75 6.25V6.25Z"
                        fill="black"
                      />
                    </svg>
                  </form>
                </div>
                <div class="pair-wrap">
                  <vuescroll :ops="ops" ref="pairBlockScroll">
                    <TradingMarketPairs
                      v-for="(pair, index) in marketPrices"
                      :key="index"
                      :pair="pair"
                      :money-currency="moneyCurrency"
                      :is-logged="isLogged"
                      :selectActiveClass="
                        pair.currencyName === currentCurrencyName
                      "
                      :currentCurrency="currentCurrencyName"
                      :id="`trading__pairs-el-${index}`"
                      @selectPair="handlePair"
                      @toggleFavorite="handleFavorite"
                      @currentId="scrollToCurrentPair"
                    />
                  </vuescroll>
                </div>
              </div>
            </div>

            <div
              v-if="isLogged && screenWidthFlag === 'mobile'"
              class="trading-orders-table-wrap"
            >
              <div class="trading-orders-table-header">
                <button
                  type="button"
                  class="trading-orders-tab-btn"
                  :class="{ active: orderTablesFlag === 'open' }"
                  @click="toggleOrderTable('open')"
                >
                  <span class="text-content">Open orders</span>
                </button>
                <button
                  type="button"
                  class="trading-orders-tab-btn"
                  :class="{ active: orderTablesFlag === '24h' }"
                  @click="toggleOrderTable('24h')"
                >
                  <span class="text-content">My 24 hour order history</span>
                </button>
              </div>

              <div
                class="trading-orders-table"
                v-if="orderTablesFlag === 'open'"
              >
                <div class="table-wrap" v-if="songOrders.openOrders.length">
                  <table class="table orders-table trading-table">
                    <thead>
                      <tr>
                        <th class="left medium-col">Pair</th>
                        <th class="left">Type</th>
                        <th class="left">Side</th>
                        <th class="left medium-col">Price</th>
                        <th class="left">Amount</th>
                        <th class="center medium-col">Total</th>
                        <th class="center large-col">Trigger Conditions</th>
                        <th class="center">Date</th>
                        <th class="center medium-col">
                          <!--                      wait for back-end part-->

                          <!--                      <button type="button"-->
                          <!--                              @click="cancelAllOpenOrders"-->
                          <!--                              class="order-cancel-btn"-->
                          <!--                      >Cancel all-->
                          <!--                      </button>-->
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="trading-open-orders-wrapper"
                      :class="{
                        'no-scroll': songOrders.openOrders.length < 6,
                      }"
                    >
                      <vuescroll :ops="ops" ref="MyOrdersBlockScroll">
                        <TradingOrdersTableRow
                          v-for="(order, index) in songOrders.openOrders"
                          :key="index"
                          :order="order"
                          :current-name="currentCurrencyName"
                          :money-symbol="moneyCurrency.name"
                          :type-flag="orderTablesFlag"
                          @cancelOneOrder="cancelOneOrder"
                        />
                      </vuescroll>
                    </tbody>
                  </table>
                </div>

                <div class="table-wrap" v-else>
                  <table class="table orders-table trading-table">
                    <thead>
                      <tr>
                        <th class="left medium-col">Pair</th>
                        <th class="left">Type</th>
                        <th class="left">Side</th>
                        <th class="left medium-col">Price</th>
                        <th class="left">Amount</th>
                        <th class="center medium-col">Total</th>
                        <th class="center large-col">Trigger Conditions</th>
                        <th class="center">Date</th>
                        <th class="center medium-col"></th>
                      </tr>
                    </thead>
                    <tbody class="no-data-plug">
                      You dont have any orders yet
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                class="trading-orders-table"
                v-if="orderTablesFlag === '24h'"
              >
                <div
                  class="table-wrap"
                  v-if="songOrders.twentyFourHOrder.length"
                >
                  <table class="table orders-table trading-table">
                    <thead>
                      <tr>
                        <th class="left medium-col">Pair</th>
                        <th class="left">Type</th>
                        <th class="left">Side</th>
                        <th class="left medium-col">Price</th>
                        <th class="left">Amount</th>
                        <th class="center medium-col">Total</th>
                        <th class="center large-col">Trigger Conditions</th>
                        <th class="center">Date</th>
                        <th class="center medium-col">
                          <!--                      wait for back-end part-->

                          <!--                      <button type="button"-->
                          <!--                              @click="cancelAll24hOrders"-->
                          <!--                              class="order-cancel-btn"-->
                          <!--                      >Cancel all-->
                          <!--                      </button>-->
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      class="trading-open-orders-wrapper"
                      :class="{
                        'no-scroll': songOrders.twentyFourHOrder.length < 6,
                      }"
                    >
                      <vuescroll :ops="ops" ref="MyOrders24HBlockScroll">
                        <TradingOrdersTableRow
                          v-for="(order, index) in songOrders.twentyFourHOrder"
                          :key="index"
                          :order="order"
                          :current-name="currentCurrencyName"
                          :money-symbol="moneyCurrency.name"
                          :type-flag="orderTablesFlag"
                          @cancelOneOrder="cancelOneOrder"
                        />
                      </vuescroll>
                    </tbody>
                  </table>
                </div>

                <div class="table-wrap" v-else>
                  <table class="table orders-table trading-table">
                    <thead>
                      <tr>
                        <th class="left medium-col">Pair</th>
                        <th class="left">Type</th>
                        <th class="left">Side</th>
                        <th class="left medium-col">Price</th>
                        <th class="left">Amount</th>
                        <th class="center medium-col">Total</th>
                        <th class="center large-col">Trigger Conditions</th>
                        <th class="center">Date</th>
                        <th class="center medium-col"></th>
                      </tr>
                    </thead>
                    <tbody class="no-data-plug">
                      You dont have any orders yet
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <!--      <main id="root2" v-else>-->

      <!--        <section class="coming-soon-section">-->
      <!--          <div class="coming-soon-img">-->
      <!--            <img src="@/assets/img/coming-soon.svg" alt="coming-soon">-->
      <!--          </div>-->
      <!--          <div class="coming-soon-text">-->
      <!--            <h1>Secondary market is coming soon!</h1>-->
      <!--            <p>We are currently working on this service</p>-->
      <!--          </div>-->
      <!--          <div class="coming-soon-line"></div>-->
      <!--          <p class="coming-soon-link">-->
      <!--            Take me back to-->
      <!--            <router-link :to="'/'" style="color: #FF0090">djookyX.com</router-link>-->
      <!--          </p>-->
      <!--        </section>-->
      <!--      </main>-->

      <DisclaimerTradingPopup
        ref="DisclaimerTradingPopup"
        @confirmDisclaimer="confirmDisclaimer"
      />

      <ServerErrorPopup
        ref="serverErrorPopup"
        :error-title="popups.error.mainErrorTitle"
        :error-message="popups.error.mainErrorMessage"
      />

      <SuccessPopup ref="successPopup" :errorMessage="popups.successMessage" />

      <SimpleConfirmPopup
        ref="ConfirmPopup"
        :title="popups.confirmTitle"
        :message="popups.confirmMessage"
        :confirm-btn-txt="popups.confirmBtnText"
        :cancel-btn-txt="popups.cancelBtnText"
        :action-id="popups.id"
        @confirmAction="confirmPopupAction"
        @cancelAction="cancelPopupAction"
      />

      <Footer />
    </div>
  </div>
</template>

<script>
import settingData from "@/components/trading/tradingSettingData";
import Footer from "@/components/footer/Footer";
import Header from "@/components/header/Header";
import TradingOrders from "@/components/trading/TradingOrders";
import TradingMarketPairs from "@/components/trading/TradingMarketPairs";
import TradingHistoryMarket from "@/components/trading/TradingHistoryMarket";
import TradingOrdersFalse from "@/components/trading/TradingOrdersFalse";
import TradingOrdersTableRow from "@/components/trading/TradingOrdersTableRow";
import AudioPlayer from "@/components/AudioPlayer";
import { debounce } from "lodash";
import { required, minValue, integer } from "vuelidate/lib/validators";
import maxDecimal from "@/helpers/VuelidateMaxDecimals";
import { mapActions, mapGetters } from "vuex";
import TradingChart from "@/components/chart/TradingChart";
import nameHandler from "@/mixins/nameHandler";
import vuescroll from "vuescroll";
import TradingNews from "@/components/trading/TradingNews";
import ServerErrorPopup from "@/components/popups/ServerErrorPopup";
import SuccessPopup from "@/components/popups/SuccessPopup";
import DisclaimerTradingPopup from "@/components/popups/DisclaimerTradingPopup";
import SimpleConfirmPopup from "@/components/popups/SimpleConfirmPopup";
import moment from "moment";

export default {
  name: "Trading",
  data() {
    return {
      ...settingData,
      loadFlag: null,
      currentCurrencyName: localStorage.getItem("tradingSongName"),
      mainCurrencyName: process.env.VUE_APP_CURRENCY_NAME_SHORT,
      screenWidthFlag: "desktop",
      viewTab: "chart", // chart | order-book | trades | news | coin-list
      moneyCurrency: {
        symbol: "€",
        name: "EUR",
      },
      ops: {
        rail: {
          gutterOfEnds: "5px",
          gutterOfSide: "3px",
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          background: "#D9D9D9",
          opacity: 1,
          minSize: 0.3,
          size: "4px",
          specifyBorderRadius: "27px",
        },
      },
      popups: {
        confirmTitle: null,
        confirmMessage: null,
        successMessage: "Complete!",
        confirmBtnText: null,
        cancelBtnText: null,
        id: null,
        error: {
          mainErrorTitle: "",
          mainErrorMessage: "",
        },
      },
      cancelData: {},
      percentageFlag: "",
      disablePercentBtn: false,
      // song data
      tradeSongData: {},
      tradeSongArtistName: null,
      mp3: "",
      player: new Audio(),
      togglePlayerFlag: true,
      artistId: null,
      songId: null,
      // intervals
      requestInterval: null,
      lastCandleInterval: null,
      candlesInterval: null,
      requestTimeout: null,
      lastCandleTimeout: null,
      range: 0,
      playEvent: null,
      currentTime: "00:00",
      songDuration: "00:00",
      thumbCurrentTime: "00:00",
      rewindInputHint: false,
      playerSrc: null,
    };
  },
  validations() {
    if (this.toggleForms === "limit") {
      if (this.limitAskFlag) {
        return {
          createSellLimit: {
            price: {
              required,
              maxDecimal: maxDecimal(2),
              minValue: minValue(0.05),
            },
            amount: {
              required,
              minValue: minValue(1),
              integer,
            },
          },
        };
      } else if (!this.limitAskFlag) {
        return {
          createBuyLimit: {
            price: {
              required,
              maxDecimal: maxDecimal(2),
              minValue: minValue(0.05),
            },
            amount: {
              required,
              minValue: minValue(1),
              integer,
            },
          },
        };
      }
    }
    if (this.toggleForms === "market") {
      if (this.marketAskFlag) {
        return {
          createSellMarket: {
            amount: {
              required,
              minValue: minValue(1),
              integer,
            },
          },
        };
      } else if (!this.marketAskFlag) {
        return {
          createBuyMarket: {
            amount: {
              required,
              minValue: minValue(1),
              integer,
            },
          },
        };
      }
    }
  },
  mixins: [nameHandler],
  watch: {
    "marketPricesBody.searchText"() {
      this.debounceMarketPrice();
    },
    createBuyLimit: {
      deep: true,
      handler() {
        let totalValue = this.createBuyLimit.price * this.createBuyLimit.amount;
        this.totalBuyLimit = totalValue.toFixed(2);
      },
    },
    createSellLimit: {
      deep: true,
      handler() {
        let totalValue =
          this.createSellLimit.price * this.createSellLimit.amount;
        this.totalSellLimit = totalValue.toFixed(2);
      },
    },
    createBuyMarket: {
      deep: true,
      handler() {
        let totalValue = this.songPrice.lastPrice * this.createBuyMarket.amount;
        this.totalBuyMarket = totalValue.toFixed(2);
      },
    },
    createSellMarket: {
      deep: true,
      handler() {
        let totalValue =
          this.songPrice.lastPrice * this.createSellMarket.amount;
        this.totalSellMarket = totalValue.toFixed(2);
      },
    },
    // createBuyStopLimit: {
    //   deep: true,
    //   handler() {
    //     let totalValue = (this.createBuyStopLimit.price * this.createBuyStopLimit.amount)
    //     this.totalBuyStopLimit = totalValue.toFixed(6);
    //   }
    // },
    // createSellStopLimit: {
    //   deep: true,
    //   handler() {
    //     let totalValue = (this.createSellStopLimit.price * this.createSellStopLimit.amount)
    //     this.totalSellStopLimit = totalValue.toFixed(6);
    //   }
    // },
    toggleForms() {
      this.resetSaleBlock();
    },
    loadFlag() {
      window.scrollTo(0, 0);
    },
  },
  methods: {
    ...mapActions({
      setMoney: "money/setMoney",
    }),
    rangeMouseDownHandler() {
      this.player.pause();
      clearInterval(this.playEvent);
    },
    rangeMouseUpHandler() {
      if (!this.togglePlayerFlag) {
        this.toggleMusic();
      }
    },
    changeTimeFrame(time) {
      // TF BUTTONS MUST BE: FIFTEEN_MINUTE, HOUR, DAY, WEEK, MONTH
      this.turnOnLastCandleInterval(false);
      this.updateChart();
      this.requestTimeFrame = time;

      if (time === "FIFTEEN_MINUTE") {
        this.candleTimeFrame = "15m";
      }
      if (time === "HOUR") {
        this.candleTimeFrame = "1H";
      }
      if (time === "DAY") {
        this.candleTimeFrame = "1D";
      }
      if (time === "WEEK") {
        this.candleTimeFrame = "1W";
      }
      if (time === "MONTH") {
        this.candleTimeFrame = "1M";
      }

      this.requestCandles();
    },
    requestCandles() {
      this.$load(async () => {
        let startDateInSec = Math.floor(this.dateRange[0] / 1000);
        let currentDateInSec = Math.floor(this.dateRange[1] / 1000);
        this.defaultRange = [this.dateRange[0], this.dateRange[1]];

        let body = {
          dateFrom: startDateInSec,
          dateTo: currentDateInSec,
          songId: localStorage.getItem("tradingSongId"),
          timeFrame: this.requestTimeFrame, // ONE_MINUTE(1),THREE_MINUTE(3),FIVE_MINUTE(5), FIFTEEN_MINUTE(15), HALF_HOUR(30), HOUR(60), FOUR_HOUR(240), SIX_HOUR(360), EIGHT_HOUR(480), TWELVE_HOUR(720), DAY(1440), THREE_DAY(4320), WEEK(10080), MONTH(43200);
        };
        this.candles = (
          await this.$api.trading.getCandles(body)
        ).data.body.elements;

        this.notEnoughDataFlag = this.candles.length <= 1;
        if (this.notEnoughDataFlag) {
          this.turnOnLastCandleInterval(false);
        } else {
          this.lastCandleTimeout = setTimeout(() => {
            this.turnOnLastCandleInterval(false);
            this.turnOnLastCandleInterval(true);
          }, 2000);
        }
      });
    },
    requestLastCandle() {
      let body = {
        songId: localStorage.getItem("tradingSongId"),
        timeFrame: this.requestTimeFrame,
      };
      this.$load(async () => {
        let res = await this.$api.trading.getLastCandles(body);
        this.lastCandle = res.data.body;
      });
    },
    requestPairMarketPrice() {
      if (this.isLogged) {
        this.$load(async () => {
          let songId = localStorage.getItem("tradingSongId");
          this.marketPrices = (
            await this.$api.trading.getMarketPricesAuth(this.marketPricesBody)
          ).data.body.elements;
          this.orderBookTrue = (
            await this.$api.trading.getOrderBook(true, songId)
          ).data.body.elements;
          this.orderBookFalse = (
            await this.$api.trading.getOrderBook(false, songId)
          ).data.body.elements;
          this.songHistory = (
            await this.$api.trading.getHistory(songId)
          ).data.body.elements;
          this.songOrders.openOrders = (
            await this.$api.trading.getOpenOrders(songId)
          ).data.body.elements;
          this.songOrders.twentyFourHOrder = (
            await this.$api.trading.getSong24hOrders(songId)
          ).data.body.elements;
        });
      } else {
        this.$load(async () => {
          let songId = localStorage.getItem("tradingSongId");
          this.marketPrices = (
            await this.$api.trading.getMarketPricesUnAuth(this.marketPricesBody)
          ).data.body.elements;
          this.orderBookTrue = (
            await this.$api.trading.getOrderBook(true, songId)
          ).data.body.elements;
          this.orderBookFalse = (
            await this.$api.trading.getOrderBook(false, songId)
          ).data.body.elements;
          this.songHistory = (
            await this.$api.trading.getHistory(songId)
          ).data.body.elements;
        });
      }
    },
    requestSongMarketPrice() {
      this.$load(async () => {
        let songId = localStorage.getItem("tradingSongId");
        this.songPrice.lastPrice = (
          await this.$api.trading.getCurrentSongMarketPrice(songId)
        ).data.body.price;
        this.songPrice.priceChanges = (
          await this.$api.trading.getCurrentSongPriceChanges(songId)
        ).data.body.value;

        // this.songPrice.high24h = (await this.$api.trading.getHigh24h(songId)).data.body.price
        // this.songPrice.low24h = (await this.$api.trading.getLow24h(songId)).data.body.price
        // this.songPrice.volume24h = (await this.$api.trading.getVolume24h(songId)).data.body.volume
        // this.songPrice.djookyVolume = (await this.$api.trading.getDjooky24hVolume(songId)).data.body.volume
      });
    },
    // requestTradeSongData() {
    //   this.togglePlayerFlag = true
    //   this.$load(async () => {
    //     let songId = localStorage.getItem('tradingSongId')
    //     this.tradeSongData = (await this.$api.trading.getTradeSongInfo(songId)).data.body
    //     this.mp3 = this.tradeSongData.songUrlFile
    //     this.player.src = this.mp3
    //     this.tradeSongArtistName = this.getNameDefault(
    //         this.tradeSongData.companyName,
    //         this.tradeSongData.stageName,
    //         this.tradeSongData.singerFirstName,
    //         this.tradeSongData.singerLastName
    //     );
    //   })
    // },
    handlePair() {
      this.currentCurrencyName = localStorage.getItem("tradingSongName");

      this.updateChart();
      this.resetIntervals();
      this.resetData();
      this.resetSaleBlock();

      this.mainRequestData();
    },
    handleFavorite() {
      this.requestPairMarketPrice();
    },
    resetConfirmPopupData() {
      this.popups.id = null;
      this.popups.confirmBtnText = null;
      this.popups.cancelBtnText = null;
      this.popups.confirmTitle = null;
      this.popups.confirmMessage = null;
    },
    cancelOneOrder(requestData, info) {
      this.cancelData = requestData;
      this.popups.id = "cancel";
      this.popups.confirmTitle = `Cancellation of ${info.orderType.toLowerCase()} order`;
      this.popups.confirmMessage = `Are you sure you want to cancel ${
        info.orderType
      } order to ${
        info.ask ? "sell" : "buy"
      } <span style="font-weight: 600; white-space: nowrap">${info.amount} ${
        info.name
      }</span> for <span style="font-weight: 600; white-space: nowrap">${
        this.moneyCurrency.symbol
      } ${info.totalPrice}</span>?`;
      this.popups.confirmBtnText = "Yes";
      this.popups.cancelBtnText = "No";
      this.$refs["ConfirmPopup"].openPopup();
    },
    // cancelAllOpenOrders() {
    //   this.popups.error.mainErrorTitle = 'Oops!'
    //   this.popups.error.mainErrorMessage = 'Functional is being developed'
    //   this.$refs.serverErrorPopup.openPopups()
    // },
    // cancelAll24hOrders() {
    //   this.popups.error.mainErrorTitle = 'Oops!'
    //   this.popups.error.mainErrorMessage = 'Functional is being developed'
    //   this.$refs.serverErrorPopup.openPopups()
    // },
    limitOperation(ask) {
      this.limitAskFlag = ask;
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      if (!ask) {
        if (this.money < this.totalBuyLimit) {
          this.popups.error.mainErrorMessage =
            "You don’t have enough balance to buy.";
          this.$refs.serverErrorPopup.openPopups();
          return;
        }

        this.popups.id = "trade";
        this.popups.confirmBtnText = "Yes";
        this.popups.cancelBtnText = "Cancel";
        this.popups.confirmTitle = `Buying ${this.mainCurrencyName}`;
        this.popups.confirmMessage = `Are you sure you want to buy <span style="font-weight: 600; white-space: nowrap">${this.createBuyLimit.amount} ${this.currentCurrencyName}</span> for <span style="font-weight: 600; white-space: nowrap">${this.moneyCurrency.symbol} ${this.totalBuyLimit}</span>?`;
        this.$refs.ConfirmPopup.openPopup();
      } else {
        this.$load(
          async () => {
            let songName = localStorage.getItem("tradingSongName");
            await this.$api.wallet.getBalance(songName).then((res) => {
              this.coinBalance = res.data.body.totalAmount;

              if (this.coinBalance < this.createSellLimit.amount) {
                this.popups.error.mainErrorMessage =
                  "You don’t have enough balance of coin to sell.";
                this.$refs.serverErrorPopup.openPopups();
                return;
              }

              this.popups.id = "trade";
              this.popups.confirmBtnText = "Yes";
              this.popups.cancelBtnText = "Cancel";
              this.popups.confirmTitle = `Selling ${this.mainCurrencyName}`;
              this.popups.confirmMessage = `Are you sure you want to sell <span style="font-weight: 600; white-space: nowrap">${this.createSellLimit.amount} ${this.currentCurrencyName}</span> for <span style="font-weight: 600; white-space: nowrap">${this.moneyCurrency.symbol} ${this.totalSellLimit}</span>?`;
              this.$refs.ConfirmPopup.openPopup();
            });
          },
          (error) => {}
        );
      }
    },
    marketOperation(ask) {
      this.marketAskFlag = ask;
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }

      if (!ask) {
        if (this.money < this.totalBuyMarket) {
          this.popups.error.mainErrorMessage =
            "You don’t have enough balance to buy.";
          this.$refs.serverErrorPopup.openPopups();
          return;
        }

        this.popups.id = "trade";
        this.popups.confirmBtnText = "Yes";
        this.popups.cancelBtnText = "Cancel";
        this.popups.confirmTitle = `Buying ${this.mainCurrencyName}`;
        this.popups.confirmMessage = `Are you sure you want to buy <span style="font-weight: 600; white-space: nowrap">${this.createBuyMarket.amount} ${this.currentCurrencyName}</span>?`;
        this.$refs.ConfirmPopup.openPopup();
      } else {
        this.$load(async () => {
          let songName = localStorage.getItem("tradingSongName");
          await this.$api.wallet.getBalance(songName).then((res) => {
            this.coinBalance = res.data.body.totalAmount;

            if (this.coinBalance < this.createSellMarket.amount) {
              this.popups.error.mainErrorMessage =
                "You don’t have enough balance of coin to sell.";
              this.$refs.serverErrorPopup.openPopups();
              return;
            }

            this.popups.id = "trade";
            this.popups.confirmBtnText = "Yes";
            this.popups.cancelBtnText = "Cancel";
            this.popups.confirmTitle = `Selling ${this.mainCurrencyName}`;
            this.popups.confirmMessage = `Are you sure you want to sell <span style="font-weight: 600; white-space: nowrap">${this.createSellMarket.amount} ${this.currentCurrencyName}</span>?`;
            this.$refs.ConfirmPopup.openPopup();
          });
        });
      }
    },
    confirmPopupAction(id) {
      if (id === "trade") {
        this.$refs.DisclaimerTradingPopup.openPopups();
      }

      if (id === "cancel") {
        this.$load(
          async () => {
            await this.$api.trading.cancelOne(this.cancelData).then((res) => {
              this.popups.successMessage = `You have successfully submitted a request to cancel the order!`;
              this.$refs["successPopup"].openPopups();
              this.requestPairMarketPrice();
              this.cancelData = {};
            });
          },
          (error) => {
            this.popups.error.mainErrorMessage = error.data.errors[0].message;
            this.$refs.serverErrorPopup.openPopups();
          }
        );
      }

      setTimeout(() => {
        this.resetConfirmPopupData();
      });
    },
    cancelPopupAction(id) {
      if (id === "cancel") {
        this.cancelData = {};
      }
    },
    confirmDisclaimer() {
      this.$refs.DisclaimerTradingPopup.resetState();

      if (this.toggleForms === "market") {
        this.marketRequest(this.marketAskFlag);
        return;
      }

      if (this.toggleForms === "limit") {
        this.limitRequest(this.limitAskFlag);
      }
    },
    limitRequest(ask) {
      let data = {
        uuid: localStorage.getItem("tradingSongId"),
      };
      if (ask) {
        data.ask = true;
        data.amount = this.createSellLimit.amount;
        data.price = +this.createSellLimit.price;
        // this.limitAskFlag = false
      } else {
        data.ask = false;
        data.amount = this.createBuyLimit.amount;
        data.price = +this.createBuyLimit.price;
        // this.limitAskFlag = true
      }
      // console.log('BODY: ', data)
      this.$load(
        async () => {
          let songName = localStorage.getItem("tradingSongName");
          await this.$api.wallet.getBalance(songName).then((res) => {
            this.coinBalance = res.data.body.totalAmount;

            if (this.coinBalance < this.createSellLimit.amount && ask) {
              this.popups.error.mainErrorMessage =
                "You don’t have enough balance of coin to sell.";
              this.$refs.serverErrorPopup.openPopups();
              return;
            }

            this.$load(
              async () => {
                await this.$api.trading.createLimit(data).then(() => {
                  this.resetSaleBlock();
                  this.$v.$reset();
                  this.popups.successMessage = `You have successfully placed a ${
                    ask ? "sell" : "buy"
                  } limit order!`;
                  this.$refs.successPopup.openPopups();
                  setTimeout(() => {
                    this.$refs["headerPanel"].changeStatus();
                  }, 1500);
                });
              },
              (error) => {
                this.resetSaleBlock();
                this.popups.error.mainErrorMessage =
                  error.data.errors[0].message;
                this.$refs.serverErrorPopup.openPopups();
              }
            );
          });
        },
        (error) => {
          this.resetSaleBlock();
          this.popups.error.mainErrorMessage = error.data.errors[0].message;
          this.$refs.serverErrorPopup.openPopups();
        }
      );
    },
    marketRequest(ask) {
      this.$load(
        async () => {
          let data = {
            uuid: localStorage.getItem("tradingSongId"),
            price: this.songPrice.lastPrice,
          };
          if (ask) {
            data.ask = true;
            data.amount = this.createSellMarket.amount;
          } else {
            data.ask = false;
            data.amount = this.createBuyMarket.amount;
          }
          let songName = localStorage.getItem("tradingSongName");
          await this.$api.wallet.getBalance(songName).then((res) => {
            this.coinBalance = res.data.body.totalAmount;

            if (this.coinBalance < this.createSellMarket.amount && ask) {
              this.popups.error.mainErrorMessage =
                "You don’t have enough balance of coin to sell.";
              this.$refs.serverErrorPopup.openPopups();
              return;
            }

            this.$load(
              async () => {
                await this.$api.trading.createMarket(data).then(() => {
                  this.resetSaleBlock();
                  this.$v.$reset();
                  this.popups.successMessage = `You have successfully placed a ${
                    ask ? "sell" : "buy"
                  } market order!`;
                  this.$refs.successPopup.openPopups();
                  setTimeout(() => {
                    this.$refs["headerPanel"].changeStatus();
                  }, 1500);
                });
              },
              (error) => {
                this.resetSaleBlock();
                this.popups.error.mainErrorMessage =
                  error.data.errors[0].message;
                this.$refs.serverErrorPopup.openPopups();
              }
            );
          });
        },
        (error) => {
          this.resetSaleBlock();
          this.popups.error.mainErrorMessage = error.data.errors[0].message;
          this.$refs.serverErrorPopup.openPopups();
        }
      );
    },
    // stopLimitRequest(ask) {
    //   this.$load(async () => {
    //     let data = {
    //       uuid: localStorage.getItem('tradingSongId'),
    //     }
    //     if (!ask) {
    //       data.ask = false
    //       data.amount = this.createBuyStopLimit.amount
    //       data.price = this.createBuyStopLimit.price
    //       data.stopPrice = this.createBuyStopLimit.stopPrice
    //       this.stopLimitFlag = true
    //     } else {
    //       data.ask = true
    //       // data.amount = this.createSellStopLimit.amount
    //       // data.price = this.createSellStopLimit.price
    //       // data.stopPrice = this.createSellStopLimit.stopPrice
    //       this.stopLimitFlag = false
    //     }
    //     if (this.$v.$invalid) {
    //       this.$v.$touch()
    //       return
    //     } else {
    //       await this.$api.trading.createStopLimit(data)
    //       this.resetSaleBlock()
    //       this.$v.$reset()
    //     }
    //   }, (error) => {
    //     this.resetSaleBlock()
    //     alert(error.data.errors[0].message)
    //   })
    // },
    // getPercentageOfAvailableBuy(orderType, percent) {
    //   if (!this.availableMoneyBalance) {
    //     return
    //   }
    //
    //   if (orderType === 'limit') {
    //     let budget = this.money / 100 * percent
    //     this.createBuyLimit.price = this.songPrice.lastPrice
    //     this.createBuyLimit.amount = Math.round(budget / this.songPrice.lastPrice)
    //     this.totalBuyLimit = (this.createBuyLimit.price * this.createBuyLimit.amount).toFixed(2)
    //
    //     if (this.totalBuyLimit > this.money) {
    //       this.createBuyLimit.amount = this.createBuyLimit.amount - 1
    //       this.totalBuyLimit = (this.createBuyLimit.price * this.createBuyLimit.amount).toFixed(2)
    //     }
    //     return
    //   }
    //
    //   if (orderType === 'market') {
    //     let budget = this.money / 100 * percent
    //     this.createBuyMarket.price = this.songPrice.lastPrice
    //     this.createBuyMarket.amount = Math.round(budget / this.songPrice.lastPrice)
    //     this.totalBuyMarket = (this.createBuyMarket.price * this.createBuyMarket.amount).toFixed(2)
    //
    //     if (this.totalBuyMarket > this.money) {
    //       this.createBuyMarket.amount = this.createBuyMarket.amount - 1
    //       this.totalBuyMarket = (this.createBuyMarket.price * this.createBuyMarket.amount).toFixed(2)
    //     }
    //   }
    // },
    // getPercentageOfAvailableSell(orderType, percent) {
    //   // this.getActualCoinBalance()
    //   this.disablePercentBtn = true
    //   this.$load(async () => {
    //     let songName = localStorage.getItem('tradingSongName')
    //     await this.$api.wallet.getBalance(songName).then((res) => {
    //       this.coinBalance = res.data.body.totalAmount
    //
    //       if (orderType === 'limit') {
    //         let budget = this.coinBalance / 100 * percent
    //         this.createSellLimit.price = this.songPrice.lastPrice
    //         this.createSellLimit.amount = Math.round(budget)
    //         this.totalSellLimit = (this.createSellLimit.price * this.createSellLimit.amount).toFixed(2)
    //       }
    //
    //       if (orderType === 'market') {
    //         let budget = this.coinBalance / 100 * percent
    //         this.createSellMarket.price = this.songPrice.lastPrice
    //         this.createSellMarket.amount = Math.round(budget)
    //         this.totalSellMarket = (this.createSellMarket.price * this.createSellMarket.amount).toFixed(2)
    //       }
    //
    //       this.disablePercentBtn = false
    //     })
    //   }, (error) => {
    //     this.popups.error.mainErrorMessage = error.data.errors[0].message
    //     this.$refs.serverErrorPopup.openPopups()
    //   })
    // },
    // getActualCoinBalance() {
    //   this.$load(async () => {
    //     let songName = localStorage.getItem('tradingSongName')
    //     await this.$api.wallet.getBalance(songName).then((res) => {
    //       this.coinBalance = res.data.body.totalAmount
    //     })
    //   })
    // },
    btnBack() {
      this.$router.push({ path: "/market" });

      // if (window.history) {
      //   window.history.back()
      // } else {
      //   this.$router.push({path: '/market'})
      // }
    },
    toggleMusic() {
      if (this.togglePlayerFlag) {
        this.togglePlayerFlag = false;
        localStorage.setItem("currentSongPlaying", this.mp3);
        this.$refs.audioPlayer.toggleMusic();
      } else {
        this.togglePlayerFlag = true;
        this.$refs.audioPlayer.toggleMusic();
      }
    },
    numColorHandler(num) {
      return num === 0 ? "#747474" : num > 0 ? "#4C9F4A" : "#D03737";
    },
    numPositiveHandler(num) {
      if (num === null) {
        console.log("price changes response: ", num);
      }
      return num
        ? num > 0
          ? `+${num.toFixed(2)}%`
          : `${num.toFixed(2)}%`
        : "0.00%";
    },
    mainRequestData() {
      this.$load(
        async () => {
          this.loadFlag = true;

          // request on mount
          if (this.isLogged) {
            await this.$api.trading
              .getMarketPricesAuth(this.marketPricesBody)
              .then((res) => {
                this.marketPrices = res.data.body.elements;

                this.localStorageCheck();
                let songId = localStorage.getItem("tradingSongId");

                this.$load(async () => {
                  this.orderBookTrue = (
                    await this.$api.trading.getOrderBook(true, songId)
                  ).data.body.elements;
                  this.orderBookFalse = (
                    await this.$api.trading.getOrderBook(false, songId)
                  ).data.body.elements;
                  this.songHistory = (
                    await this.$api.trading.getHistory(songId)
                  ).data.body.elements;
                  this.songOrders.openOrders = (
                    await this.$api.trading.getOpenOrders(songId)
                  ).data.body.elements;
                  this.songOrders.twentyFourHOrder = (
                    await this.$api.trading.getSong24hOrders(songId)
                  ).data.body.elements;

                  // song data request
                  this.tradeSongData = (
                    await this.$api.trading.getTradeSongInfo(songId)
                  ).data.body;
                  this.mp3 = this.tradeSongData.songUrlFile;
                  this.player.src = this.mp3;
                  this.playerSrc = this.mp3;
                  this.tradeSongArtistName = this.getNameDefault(
                    this.tradeSongData.companyName,
                    this.tradeSongData.stageName,
                    this.tradeSongData.singerFirstName,
                    this.tradeSongData.singerLastName
                  );

                  // candles request
                  let startDateInSec = Math.floor(this.dateRange[0] / 1000);
                  let currentDateInSec = Math.floor(this.dateRange[1] / 1000);
                  this.defaultRange = [this.dateRange[0], this.dateRange[1]];
                  // console.log('default UTC: ', new Date(this.defaultRange[0]), new Date(this.defaultRange[1]))
                  // console.log('REQUEST UTC: ', new Date(startDateInSec * 1000), new Date(currentDateInSec * 1000))

                  let body = {
                    dateFrom: startDateInSec,
                    dateTo: currentDateInSec,
                    songId: songId,
                    timeFrame: this.requestTimeFrame,
                  };
                  this.candles = (
                    await this.$api.trading.getCandles(body)
                  ).data.body.elements;

                  // market prices
                  this.songPrice.lastPrice = (
                    await this.$api.trading.getCurrentSongMarketPrice(songId)
                  ).data.body.price;
                  this.songPrice.priceChanges = (
                    await this.$api.trading.getCurrentSongPriceChanges(songId)
                  ).data.body.value;

                  this.notEnoughDataFlag = this.candles.length <= 1;
                  if (this.notEnoughDataFlag) {
                    this.turnOnLastCandleInterval(false);
                  } else {
                    this.lastCandleTimeout = setTimeout(() => {
                      this.turnOnLastCandleInterval(false);
                      this.turnOnLastCandleInterval(true);
                    }, 2000);
                  }

                  this.requestTimeout = setTimeout(() => {
                    this.turnOnRequestInterval(true);
                  }, 2000);
                  this.loadFlag = false;
                });
              });
          } else {
            await this.$api.trading
              .getMarketPricesUnAuth(this.marketPricesBody)
              .then((res) => {
                this.marketPrices = res.data.body.elements;

                this.localStorageCheck();
                let songId = localStorage.getItem("tradingSongId");

                this.$load(async () => {
                  this.orderBookTrue = (
                    await this.$api.trading.getOrderBook(true, songId)
                  ).data.body.elements;
                  this.orderBookFalse = (
                    await this.$api.trading.getOrderBook(false, songId)
                  ).data.body.elements;
                  this.songHistory = (
                    await this.$api.trading.getHistory(songId)
                  ).data.body.elements;

                  // song data request
                  this.tradeSongData = (
                    await this.$api.trading.getTradeSongInfo(songId)
                  ).data.body;
                  this.mp3 = this.tradeSongData.songUrlFile;
                  this.player.src = this.mp3;
                  this.playerSrc = this.mp3;
                  this.tradeSongArtistName = this.getNameDefault(
                    this.tradeSongData.companyName,
                    this.tradeSongData.stageName,
                    this.tradeSongData.singerFirstName,
                    this.tradeSongData.singerLastName
                  );

                  // candles request
                  let startDateInSec = Math.floor(this.dateRange[0] / 1000);
                  let currentDateInSec = Math.floor(this.dateRange[1] / 1000);
                  this.defaultRange = [this.dateRange[0], this.dateRange[1]];

                  let body = {
                    dateFrom: startDateInSec,
                    dateTo: currentDateInSec,
                    songId: songId,
                    timeFrame: this.requestTimeFrame,
                  };
                  this.candles = (
                    await this.$api.trading.getCandles(body)
                  ).data.body.elements;

                  // market prices
                  this.songPrice.lastPrice = (
                    await this.$api.trading.getCurrentSongMarketPrice(songId)
                  ).data.body.price;
                  this.songPrice.priceChanges = (
                    await this.$api.trading.getCurrentSongPriceChanges(songId)
                  ).data.body.value;

                  this.notEnoughDataFlag = this.candles.length <= 1;
                  if (this.notEnoughDataFlag) {
                    this.turnOnLastCandleInterval(false);
                  } else {
                    this.lastCandleTimeout = setTimeout(() => {
                      this.turnOnLastCandleInterval(false);
                      this.turnOnLastCandleInterval(true);
                    }, 2000);
                  }

                  this.requestTimeout = setTimeout(() => {
                    this.turnOnRequestInterval(true);
                  }, 2000);

                  this.loadFlag = false;
                });
              });
          }
        },
        (error) => {
          this.loadFlag = false;
          this.popups.error.mainErrorMessage = error.data.errors[0].message;
          this.$refs.serverErrorPopup.openPopups();
        }
      );
    },
    localStorageCheck() {
      if (!this.$route.params.id) {
        localStorage.setItem("tradingSongId", this.marketPrices[0].songUuid);
        localStorage.setItem(
          "tradingSongName",
          this.marketPrices[0].currencyName
        );
        this.currentCurrencyName = this.marketPrices[0].currencyName;
        const lowName = this.marketPrices[0].currencyName.toLowerCase();
        this.$router.push({ path: `/trading/${lowName}` });
        return;
      }

      const nameInUrl = this.$route.params.id;
      if (
        localStorage.hasOwnProperty("tradingSongId") &&
        localStorage.hasOwnProperty("tradingSongName")
      ) {
        this.currentCurrencyName = localStorage.getItem("tradingSongName");
        return;
      }

      const neededObj = this.marketPrices.find(
        (el) => el.currencyName.toLowerCase() === nameInUrl
      );
      if (!neededObj) {
        localStorage.setItem("tradingSongId", this.marketPrices[0].songUuid);
        localStorage.setItem(
          "tradingSongName",
          this.marketPrices[0].currencyName
        );
        this.currentCurrencyName = this.marketPrices[0].currencyName;
        const lowName = this.marketPrices[0].currencyName.toLowerCase();
        this.$router.push({ path: `/trading/${lowName}` });

        this.popups.error.mainErrorTitle = "Wrong redirect url!";
        this.popups.error.mainErrorMessage =
          "That coin pair are not exist or url is wrong.";
        this.$refs.serverErrorPopup.openPopups();
        return;
      }

      localStorage.setItem("tradingSongName", neededObj.currencyName);
      localStorage.setItem("tradingSongId", neededObj.songUuid);
      this.currentCurrencyName = neededObj.currencyName;
    },
    resetIntervals() {
      clearTimeout(this.requestTimeout);
      clearTimeout(this.lastCandleTimeout);

      clearInterval(this.lastCandleInterval);
      clearInterval(this.requestInterval);
      clearInterval(this.candlesInterval);
    },
    resetData() {
      this.tradeSongData.authorPhotoUrl = null;
      this.tradeSongArtistName = null;

      this.player.currentTime = 0;
      this.togglePlayerFlag = true;
      this.player.pause();
    },
    resetSaleBlock() {
      this.createBuyLimit.amount = null;
      this.createBuyLimit.price = null;
      this.createSellLimit.amount = null;
      this.createSellLimit.price = null;
      this.totalBuyLimit = null;
      this.totalSellLimit = null;
      this.limitAskFlag = null;

      this.createBuyMarket.amount = null;
      this.createSellMarket.amount = null;
      this.totalBuyMarket = null;
      this.totalSellMarket = null;
      this.marketAskFlag = null;

      // this.createBuyStopLimit.amount = null
      // this.createBuyStopLimit.price = null
      // this.createBuyStopLimit.stopPrice = null
      // this.createSellStopLimit.amount = null
      // this.createSellStopLimit.price = null
      // this.createSellStopLimit.stopPrice = null
      // this.stopLimitFlag = null
    },
    updateChart() {
      if (
        this.$refs &&
        this.$refs.TradingChart &&
        this.$refs.TradingChart.$refs.TradingVue
      ) {
        this.$refs.TradingChart.$refs.TradingVue.resetChart(true);
        this.$refs.TradingChart.resetData();
      }
    },
    turnOnLastCandleInterval(turnOn) {
      if (turnOn) {
        this.lastCandleInterval = setInterval(() => {
          this.requestLastCandle();
        }, 1000);
      } else {
        clearInterval(this.lastCandleInterval);
      }
    },
    turnOnRequestInterval(turnOn) {
      if (turnOn) {
        this.requestInterval = setInterval(() => {
          this.requestSongMarketPrice();
          this.requestPairMarketPrice();
        }, 1500);
      } else {
        clearInterval(this.requestInterval);
      }
    },
    getAllPairs() {
      this.marketPricesBody.isOnlyFavorite = false;
    },
    getFavoritePairs() {
      this.marketPricesBody.isOnlyFavorite = true;
    },
    sortPairByName() {
      this.pairsByNameCounter += 1;

      if (this.pairsByNameCounter === 2) {
        this.pairsByNameCounter = 0;
      }

      if (this.pairsByNameCounter === 0) {
        this.sortByType = "name";
        this.marketPricesBody.isSortNameAsc = true;
        delete this.marketPricesBody.isSortPriceAsc;
        delete this.marketPricesBody.isSortChange24hAsc;
      }

      if (this.pairsByNameCounter === 1) {
        this.sortByType = "name";
        this.marketPricesBody.isSortNameAsc = false;
        delete this.marketPricesBody.isSortPriceAsc;
        delete this.marketPricesBody.isSortChange24hAsc;
      }
    },
    sortPairByPrice() {
      this.pairsByPriceCounter += 1;

      if (this.pairsByPriceCounter === 2) {
        this.pairsByPriceCounter = 0;
      }

      if (this.pairsByPriceCounter === 0) {
        this.sortByType = "price";
        this.marketPricesBody.isSortPriceAsc = true;

        delete this.marketPricesBody.isSortNameAsc;
        delete this.marketPricesBody.isSortChange24hAsc;
      }

      if (this.pairsByPriceCounter === 1) {
        this.sortByType = "price";
        this.marketPricesBody.isSortPriceAsc = false;

        delete this.marketPricesBody.isSortNameAsc;
        delete this.marketPricesBody.isSortChange24hAsc;
      }
    },
    sortPairByChanges24h() {
      this.pairsByChanges24hCounter += 1;

      if (this.pairsByChanges24hCounter === 2) {
        this.pairsByChanges24hCounter = 0;
      }

      if (this.pairsByChanges24hCounter === 0) {
        this.sortByType = "changes";
        this.marketPricesBody.isSortChange24hAsc = true;

        delete this.marketPricesBody.isSortNameAsc;
        delete this.marketPricesBody.isSortPriceAsc;
      }

      if (this.pairsByChanges24hCounter === 1) {
        this.sortByType = "changes";
        this.marketPricesBody.isSortChange24hAsc = false;

        delete this.marketPricesBody.isSortNameAsc;
        delete this.marketPricesBody.isSortPriceAsc;
      }
    },
    requestNews() {
      this.$load(async () => {
        this.newsList = (await this.$api.news.getAll()).data.body.elements;
      });
    },
    toggleOrderTable(flag) {
      if (this.isLogged) {
        let songId = localStorage.getItem("tradingSongId");

        if (this.orderTablesFlag === "open" && flag === "24h") {
          this.orderTablesFlag = "24h";
          this.$load(async () => {
            this.songOrders.twentyFourHOrder = (
              await this.$api.trading.getSong24hOrders(songId)
            ).data.body.elements;
          });
          return;
        }

        if (this.orderTablesFlag === "24h" && flag === "open") {
          this.orderTablesFlag = "open";
          this.$load(async () => {
            this.songOrders.openOrders = (
              await this.$api.trading.getOpenOrders(songId)
            ).data.body.elements;
          });
        }
      }
    },
    async setChartSize() {
      if (this.$refs && this.$refs.TradingChart) {
        await this.$nextTick();

        let htmlItems = document.getElementsByClassName("js--trading-chart");
        this.currentWidth = htmlItems[0].parentElement.offsetWidth;
        this.currentHeight = htmlItems[0].parentElement.offsetHeight;
      }
    },
    lengthWatcher() {
      window.addEventListener(
        "resize",
        () => {
          if (window.innerWidth >= 1200) {
            this.screenWidthFlag = "desktop";
            return;
          }
          if (window.innerWidth < 1200) {
            this.screenWidthFlag = "mobile";
          }
        },
        false
      );
    },
    // setChartSize() {
    //   this.$nextTick(() => {
    //     let htmlItems = document.getElementsByClassName('js--trading-chart')
    //     this.currentWidth = htmlItems[0].parentElement.offsetWidth
    //     this.currentHeight = htmlItems[0].parentElement.offsetHeight
    //   })
    // },
    resizeChart() {
      this.fullChartFlag = !this.fullChartFlag;
      this.resetIntervals();
      this.updateChart();
      this.resetData();
      this.resetSaleBlock();

      if (this.fullChartFlag) {
        this.requestCandles();
      } else {
        this.mainRequestData();
      }

      this.setChartSize();
    },
    async scrollToCurrentPair(toItem) {
      await this.$nextTick();
      if (this.$refs["pairBlockScroll"]) {
        this.$refs["pairBlockScroll"].scrollIntoView(`#${toItem}`, true);
      }
    },
  },
  computed: {
    ...mapGetters({
      isLogged: "authentication/isLogged",
      user: "user/getUser",
      money: "money/getMoney",
    }),
    availableMoneyBalance() {
      return this.money > this.songPrice.lastPrice + 0.05;
    },
    dateRange() {
      let currentDateInMs = Date.now();
      let startDateInMs = currentDateInMs - 2629800000 * 6;
      return [startDateInMs, currentDateInMs];
    },
    userTimezone() {
      const tz = moment().utcOffset() / 60;
      return tz ? tz : 0;
    },
  },
  mounted() {
    document.title = "Trading";
    if (this.isLogged) {
      this.setMoney();
    }

    this.setChartSize();

    window.addEventListener(
      "resize",
      () => {
        this.setChartSize();
      },
      true
    );

    this.requestNews();
    this.mainRequestData();

    // this.candlesInterval = setInterval(() => {
    //   this.requestCandles()
    // }, 180000) // 3 min

    this.debounceMarketPrice = debounce(this.requestPairMarketPrice, 1000);

    this.lengthWatcher();
    if (window.innerWidth >= 1200) {
      this.screenWidthFlag = "desktop";
      return;
    }
    if (window.innerWidth < 1200) {
      this.screenWidthFlag = "mobile";
    }
  },
  beforeDestroy() {
    this.player.currentTime = 0;
    this.resetSaleBlock();
    this.resetData();

    this.resetIntervals();
    setTimeout(() => {
      this.resetIntervals();
    }, 2500);

    window.removeEventListener(
      "resize",
      () => {
        this.setChartSize();
      },
      true
    );

    localStorage.removeItem("tradingSongName");
    localStorage.removeItem("tradingSongId");
  },
  components: {
    TradingOrdersTableRow,
    TradingOrdersFalse,
    TradingHistoryMarket,
    TradingOrders,
    TradingMarketPairs,
    Footer,
    Header,
    TradingChart,
    vuescroll,
    TradingNews,
    ServerErrorPopup,
    DisclaimerTradingPopup,
    SuccessPopup,
    SimpleConfirmPopup,
    AudioPlayer,
  },
};
</script>

<style scoped>
.trading.section {
  position: relative;
}

.preloader-plug {
  z-index: 101;
  padding-top: 80px;
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.preloader-wrapper {
  z-index: 102;
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

/*.preloader {*/
/*  width: 100%;*/
/*  height: 100vh;*/
/*}*/

/* .market-order_buy-form {
}

.market-order_sell-form {
} */

.market-input-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px 6px 10px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  margin-bottom: 16px;
}

.market-input-container.disabled {
  background: #c6c6c6;
}

.market-input-container.disabled .market-input-item,
.market-input-container.disabled .market-input {
  color: #272727;
  font-weight: 600;
}

.market-input-title {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  text-align: left;
  color: #272727;
  margin-right: 8px;
}

.market-input-wrap {
  position: relative;
  width: calc(100% - 45px);
  display: flex;
  align-items: center;
}

.market-input-item {
  position: absolute;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #767575;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.market-input {
  background: transparent;
  padding-right: 17px;
  text-align: right;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  /*color: #767575;*/
}

/*.market-input.market-fake-input {*/
/*  display: flex;*/
/*  align-items: center;*/
/*}*/

.market-order-error {
  font-size: 12px;
  margin-top: 0;
  color: red;
  position: absolute;
  left: 0;
  bottom: -18px;
}

.market-order-error p {
  margin-bottom: 0;
}

.market__coin-price-wrapper,
.market__coin-block-wrapper {
  display: flex;
}

.market__coin-block-separator {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 86px;
  background-color: #eaeaea;
}

.market__song-block-rewind {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.rewind-time {
  min-width: 35px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 150px;
  height: 4px;
  background: rgba(255, 0, 144, 0.3);
  border-radius: 5px;
  background-image: linear-gradient(#ff0090, #ff0090);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #ff0090;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.audio-player-trading-wrapper {
  color: #000000;
  /*width: 152px;*/
}

.market__song-block .audio-player-trading-wrapper {
  margin-top: 20px;
}

.market__song-block-info {
  width: 200px;
}

.market__song-block-info a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1200px) {
  .trading .market-coin-info {
    flex-wrap: nowrap;
  }

  .market__song-block-rewind {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .market__coin-block {
    width: 50%;
    justify-content: space-between;
    padding: 0 20px;
  }

  .market__song-block {
    width: 50%;
  }

  .market__coin-block-separator {
    display: block;
  }

  .trading .market__coin-block:not(.mobile) {
    border-left: none;
  }
}

@media only screen and (max-width: 768px) {
  .market__coin-block-separator {
    display: none;
  }

  .trading .market-coin-info {
    flex-wrap: wrap;
    justify-content: center;
  }

  .market__song-block {
    width: 100%;
    order: 2;
    flex-direction: column;
  }

  .market__song-block-info {
    order: 1;
    margin-right: 0;
    text-align: center;
  }

  .market__song-block-info a {
    margin-bottom: 10px;
  }

  .market__song-block-info a:last-child {
    margin-bottom: 0;
  }

  .market__song-block-play-btn {
    order: 2;
    margin-top: 20px;
    margin-right: 0;
  }

  .audio-player-trading-wrapper {
    order: 3;
  }
}

@media only screen and (max-width: 500px) {
  .market__song-block {
    width: 100%;
  }
}
</style>
