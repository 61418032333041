<template>
  <div class="trading-chart-wrapper">

    <div class="trading-chart__header">

      <div
          v-if="screen === 'desktop'"
          class="trading-chart__header-main"
      >
        <span class="trading-chart__header-title">Time</span>
        <button class="trading-chart__time-btn"
                :class="{'active': timeFrame === '15m',
                         'disabled-btn': disableAllTimeFrameBtn}"
                :disabled="disableAllTimeFrameBtn"
                @click="changeTimeFrame('FIFTEEN_MINUTE')"
        >15 Minutes</button>
        <button class="trading-chart__time-btn"
                :class="{'active': timeFrame === '1H',
                         'disabled-btn': disableAllTimeFrameBtn}"
                :disabled="disableAllTimeFrameBtn"
                @click="changeTimeFrame('HOUR')"
        >1 Hour</button>
        <button class="trading-chart__time-btn"
                :class="{'active': timeFrame === '1D',
                         'disabled-btn': disableAllTimeFrameBtn}"
                :disabled="disableAllTimeFrameBtn"
                @click="changeTimeFrame('DAY')"
        >1 Day</button>
        <button class="trading-chart__time-btn"
                :class="{'active': timeFrame === '1W',
                         'disabled-btn': disableAllTimeFrameBtn}"
                :disabled="disableAllTimeFrameBtn"
                @click="changeTimeFrame('WEEK')"
        >1 Week</button>
        <button class="trading-chart__time-btn"
                :class="{'active': timeFrame === '1M',
                         'disabled-btn': disableAllTimeFrameBtn}"
                :disabled="disableAllTimeFrameBtn"
                @click="changeTimeFrame('MONTH')"
        >1 Month</button>
      </div>

      <div
          v-if="screen === 'mobile'"
          class="trading-chart__header-main"
      >
        <span class="trading-chart__header-title">Time</span>
        <GeneralSelect
            class="trading__timeframe-select"
            :identifier="'trading__timeframe'"
            :options="timeFrameList"
            :current="currentTF"
            :placeholder="'Timeframe'"
            @changed="setTimeFrame"
        />
      </div>

      <div class="trading-chart__header-right" :style="{'width': chartSidebarWidth}">
        <button
            class="trading-chart__resize-btn"
            type="button"
            @click="toggleChartSize"
        >
          <svg v-if="fullChartFlag"
               width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.25 5.5H12.75V10.5H5.25V5.5ZM1.5 5.5H0.25V1.125L0.875 0.5H5.25V1.75H1.5V5.5ZM17.75 1.125V5.5H16.5V1.75H12.75V0.5H17.125L17.75 1.125ZM16.5 10.5H17.75V14.875L17.125 15.5H12.75V14.25H16.5V10.5ZM1.5 14.25H5.25V15.5H0.875L0.25 14.875V10.5H1.5V14.25Z"/>
          </svg>

          <svg v-else
              width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.75 13H15.25V3H2.75V13ZM5.25 5.5H12.75V10.5H5.25V5.5ZM1.5 5.5H0.25V1.125L0.875 0.5H5.25V1.75H1.5V5.5ZM17.75 1.125V5.5H16.5V1.75H12.75V0.5H17.125L17.75 1.125ZM16.5 10.5H17.75V14.875L17.125 15.5H12.75V14.25H16.5V10.5ZM1.5 14.25H5.25V15.5H0.875L0.25 14.875V10.5H1.5V14.25Z"/>
          </svg>
        </button>
      </div>

    </div>

      <!--   height compute: 37px header + 2px borders + 15px padding-bottom   -->
      <!--    @range-changed="$emit('rangeChange', $event)"-->
    <trading-vue
        v-if="!notEnoughDataFlag"
        class="trading-chart"
        ref="TradingVue"
        :data="allData"
        :id="'trading-chart'"
        :titleTxt="` `"
        :width="currentWidth - 2"
        :height="currentHeight - 54"
        :font="chartFont"
        :colorCandleUp="colors.colorCandleUp"
        :colorCandleDw="colors.colorCandleDw"
        :colorBack="colors.colorBack"
        :colorGrid="colors.colorGrid"
        :colorWickUp="colors.colorWickUp"
        :colorWickDw="colors.colorWickDw"
        :colorTextHL="colors.colorTextHL"
        :colorText="colors.colorText"
        :colorPanel="colors.colorPanel"
        :colorWickSm="colors.colorWickSm"
        :colorCross="colors.colorCross"
        :chartConfig="chartConfig"
        :indexBased="true"
        :timezone="userTimezone"
    ></trading-vue>

    <div v-else class="trading-chart-plug" :style="{ 'height': `${currentHeight - 54}px`}">
      <img src="@/assets/img/trading-not_enough-plug.svg" class="trading-chart-plug-img" alt="Not enough data plug">
      <p class="trading-chart-plug-text">
        Sorry we don’t have enough data to
        <br/> draw chart for you :(
      </p>
    </div>

    <ServerErrorPopup ref="serverErrorPopup" :error-title="error.errorTitle" :error-message="error.errorMessage"/>

  </div>
</template>
<script>

import TradingVue from 'trading-vue-js'
import ServerErrorPopup from "@/components/popups/ServerErrorPopup";
import GeneralSelect from "@/components/inputs/GeneralSelect";

export default {
  name: 'TradingChart',
  props: {
    currentWidth: Number,
    currentHeight: Number,
    timeFrame: String,
    lastCandle: {
      type: Object,
      default: () => {}
    },
    candlesData: {
      type: Array,
      default: () => []
    },
    defaultRange: {
      type: Array,
      default: () => []
    },
    notEnoughDataFlag: {
      type: Boolean,
      default: () => false
    },
    fullChartFlag: {
      type: Boolean,
      default: () => false
    },
    userTimezone: {
      type: Number,
      default: () => 0,
    },
    screen: {
      type: String,
      required: false,
      default: () => 'desktop'
    }
  },
  data() {
    return {
      timeFrameList: [
        {
          name: '15 Minutes',
          value: '15m'
        },
        {
          name: '1 hour',
          value: '1H'
        },
        {
          name: '1 day',
          value: '1D'
        },
        {
          name: '1 week',
          value: '1W'
        },
        {
          name: '1 month',
          value: '1M'
        }
      ],
      allData: {
        chart: {   // required
          type: 'Candles', // "<Candles|Spline>"
          // indexBased: true, // Index-based rendering mode <true|false>
          data: [], // [timestamp, open, high, low, close, volume]
          tf: '15m', // Forced timeframe 1s/5s/10s/20s/30s/1m/3m/5m/15m/30m/1H/2H/3H/4H/12H/1D/1W/1M/1Y
          grid: {},
          settings: {} // Settings (depending on "type")
        },
      },
      chartFont: '12px Gilroy, Roboto, sans-serif',
      colors: {
        colorBack: '#FFFFFF',
        colorGrid: '#EAEAEA',
        colorCandleUp: '#4C9F4A',
        colorCandleDw: '#D03737',
        colorWickUp: '#4C9F4A',
        colorWickDw: '#D03737',
        colorVolUp: '#4C9F4A',
        colorVolDw: '#D03737',
        colorText: '#898989',
        colorTextHL: '#000000',
        colorPanel: '#DEDEDE',
        colorWickSm: '#FF0090',
        colorCross: '#898989',
      },
      timezone: null,
      disableAllTimeFrameBtn: false,
      chartSidebarWidth: '',
      chartConfig: {
        SBMIN: 75,
        // Minimal sidebar px
        SBMAX: Infinity,
        // Max sidebar, px
        TOOLBAR: 57,
        // Toolbar width px
        TB_ICON: 25,
        // Toolbar icon size px
        TB_ITEM_M: 6,
        // Toolbar item margin px
        TB_ICON_BRI: 1,
        // Toolbar icon brightness
        TB_ICON_HOLD: 420,
        // ms, wait to expand
        TB_BORDER: 0.5,
        // Toolbar border px
        TB_B_STYLE: 'dotted',
        // Toolbar border style
        TOOL_COLL: 7,
        // Tool collision threshold
        EXPAND: 0.15,
        // %/100 of range
        CANDLEW: 0.6,
        // %/100 of step
        GRIDX: 100,
        // px
        GRIDY: 47,
        // px
        BOTBAR: 28,
        // px
        PANHEIGHT: 22,
        // px
        DEFAULT_LEN: 50,
        // candles
        MINIMUM_LEN: 5,
        // candles,
        MIN_ZOOM: 25,
        // candles
        MAX_ZOOM: 1000,
        // candles,
        VOLSCALE: 0.10,
        // %/100 of height
        UX_OPACITY: 0.9,
        // Ux background opacity
        ZOOM_MODE: 'tv',
        // 'tv' or 'tl'
        L_BTN_SIZE: 21,
        // Legend Button size, px
        L_BTN_MARGIN: '-6px 0 -6px 0',
        // css margin
        SCROLL_WHEEL: 'prevent' // 'pass', 'click'
      },
      error: {
        errorTitle: '',
        errorMessage: '',
      },
    }
  },
  methods: {
    resetData() {
      this.allData.chart.data = []
    },
    syncWidth() {
      if (this.notEnoughDataFlag) {
        this.chartSidebarWidth = '75px'
        return
      }
      let item = document.getElementById('trading-chart-sidebar-0-canvas')
      this.chartSidebarWidth = item.style.width
    },
    changeTimeFrame(time) {
      this.disableAllTimeFrameBtn = true
      setTimeout(() => {
        this.disableAllTimeFrameBtn = false
      }, 1750)
      this.$emit('changeTimeFrame', time)
    },
    setTimeFrame(time) {
      let formatTime
      switch (time) {
        case '15m':
          formatTime = 'FIFTEEN_MINUTE';
          break;
        case '1H':
          formatTime = 'HOUR';
          break;
        case '1D':
          formatTime = 'DAY';
          break;
        case '1W':
          formatTime = 'WEEK';
          break;
        case '1M':
          formatTime = 'MONTH';
          break;
        default:
          formatTime = 'FIFTEEN_MINUTE';
      }
      this.$emit('changeTimeFrame', formatTime)
    },
    toggleChartSize() {
      this.$emit('resizeChart')
    },
  },
  computed: {
    currentTF() {
      return this.timeFrameList.find((i) => i.value.includes(this.timeFrame))
    }
  },
  watch: {
    'candlesData': {
      deep: true,
      handler() {
        this.allData.chart.data = []
        this.candlesData.forEach((el) => {
          let arr = [el.date, el.openPrice, el.highPrice, el.lowPrice, el.closePrice, el.volume]
          this.allData.chart.data.push(arr)
        })
      }
    },
    timeFrame() {
      this.allData.chart.tf = this.timeFrame
    },
    'lastCandle': {
      deep: true,
      handler() {
        if (this.candlesData) {
          let currentLast = this.lastCandle
          this.allData.chart.data[this.allData.chart.data.length - 1] = [
            currentLast.date,
            currentLast.openPrice,
            currentLast.highPrice,
            currentLast.lowPrice,
            currentLast.closePrice,
            currentLast.volume
          ]
        }
      }
    },
  },
  mounted() {
    this.syncWidth()
  },
  components: {
    GeneralSelect,
    TradingVue,
    ServerErrorPopup,
  }
}
</script>

<style>

.t-vue-lspan {
  font-weight: 500;
  color: #000000;
}

.t-vue-lspan:nth-child(2) {
  color: #4C9F4A;
}

.t-vue-lspan:nth-child(3) {
  color: #D03737;
}

.trading__timeframe-select.general_select {
  width: 70px;
  height: 20px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #FF0090;
}

.trading__timeframe-select .general_select-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px 4px 4px;
  background: #FFF5FB;
  border: 1px solid #FF0090;
  border-radius: 16px;
}

.trading__timeframe-select .general_select-item {
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trading__timeframe-select .general_select-list {
  background: #FFF5FB;
  border-right: 1px solid #FF0090;
  border-left: 1px solid #FF0090;
  border-bottom: 1px solid #FF0090;
  border-radius: 0 0 10px 10px;
  z-index: 15;
}

.trading__timeframe-select .general_select-selected.open {
  border-radius: 10px 10px 0 0;
}

.trading__timeframe-select .general_select-selected + .general_select-arrow {
  width: 4px;
  height: 2px;
  fill: #FF0090;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}

.trading__timeframe-select .general_select-selected + .general_select-arrow path {
  stroke: #FF0090;
}

.trading__timeframe-select p.general_select-item-content {
  border: none;
}

@media only screen and (max-width: 768px) {
  .trading-vue-legend {
    display: none;
  }
}

</style>