<template>
<div class="initial-sale-document-wrapper" ref="top">
  <!--PAGE #1-->
  <div class="format-a4">

    <div class="document-header">
      <div class="document-title-wrap">
        <h2 class="document-title">INITIAL SALE AGREEMENT # 1</h2>
      </div>
      <div class="djooky-logo">
        <svg width="253" height="39" viewBox="0 0 253 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M137.724 15.1813L150.582 30.345H142.614L129.668 15.2165L142.614 0.0175781H150.582L137.724 15.1813Z" fill="#FE0190"/>
          <path d="M6.68393 24.7334H12.9809C19.7352 24.7334 21.7755 19.5616 21.7755 15.111C21.7755 9.95673 19.3482 5.50609 12.9809 5.50609H6.68393V24.7334ZM13.7196 0C23.5345 0 28.5474 6.84304 28.5474 15.1989C28.5474 23.9946 23.6752 30.3274 13.0161 30.3274H0V0H13.7196Z" fill="#FE0190"/>
          <path d="M46.2949 27.3897C46.5511 30.6305 45.5987 33.8514 43.6213 36.4316C42.2142 38.1908 40.1034 39 36.5856 39H34.8267V33.0716C35.1433 33.0716 35.5478 33.1597 35.8468 33.1597C38.45 33.1597 39.6285 30.9432 39.6285 27.5657V0H46.2949V27.3897Z" fill="#FE0190"/>
          <path d="M70.832 6.24487C65.7311 6.24487 62.354 9.92155 62.354 15.3573C62.354 20.793 65.7311 24.4695 70.832 24.4695C75.9329 24.4695 79.31 20.9513 79.31 15.3573C79.31 9.76323 75.9329 6.24487 70.832 6.24487ZM70.832 30.7672C67.7901 30.7811 64.8124 29.8921 62.2758 28.2127C59.7393 26.5334 57.7579 24.1392 56.5826 21.3331C55.4072 18.527 55.0907 15.4352 55.673 12.4492C56.2554 9.46311 57.7104 6.71701 59.854 4.55838C61.9977 2.39974 64.7334 0.925662 67.7151 0.322729C70.6967 -0.280204 73.7902 0.0150773 76.604 1.17126C79.4177 2.32745 81.8252 4.29255 83.5218 6.81779C85.2184 9.34303 86.1277 12.3149 86.1347 15.3573C86.1848 17.3857 85.8241 19.4033 85.0742 21.2886C84.3242 23.174 83.2006 24.888 81.771 26.3277C80.3413 27.7673 78.6352 28.9029 76.7553 29.6659C74.8754 30.4288 72.8605 30.8034 70.832 30.7672Z" fill="#FE0190"/>
          <path d="M108.825 6.24487C103.724 6.24487 100.347 9.92155 100.347 15.3573C100.347 20.793 103.724 24.4695 108.825 24.4695C113.926 24.4695 117.303 20.9513 117.303 15.3573C117.303 9.76323 113.926 6.24487 108.825 6.24487ZM108.825 30.7672C105.783 30.7811 102.805 29.8921 100.268 28.2127C97.7319 26.5334 95.7506 24.1392 94.5752 21.3331C93.3999 18.527 93.0833 15.4352 93.6657 12.4492C94.248 9.46311 95.7031 6.71701 97.8467 4.55838C99.9903 2.39974 102.726 0.925662 105.708 0.322729C108.689 -0.280204 111.783 0.0150773 114.597 1.17126C117.41 2.32745 119.818 4.29255 121.514 6.81779C123.211 9.34303 124.12 12.3149 124.127 15.3573C124.178 17.3857 123.817 19.4033 123.067 21.2886C122.317 23.174 121.193 24.888 119.764 26.3277C118.334 27.7673 116.628 28.9029 114.748 29.6659C112.868 30.4288 110.853 30.8034 108.825 30.7672Z" fill="#FE0190"/>
          <path d="M166.957 16.1136L156.369 0.0175781H164.46L170.264 9.53448L176.139 0.0175781H184.16L173.641 16.1136V30.345H166.957V16.1136Z" fill="#FE0190"/>
          <path d="M221.273 15.1813L234.131 30.345H226.163L213.235 15.2165L226.163 0.0175781H234.131L221.273 15.1813Z" fill="#FE0190"/>
          <path d="M203.895 15.1813L191.037 30.345H199.005L211.933 15.2165L199.005 0.0175781H191.037L203.895 15.1813Z" fill="#FE0190"/>
        </svg>
      </div>
    </div>

    <div class="document-section">

      <p class="document-main-text">
        WHEREAS, Djooky GmbH, a legal entity incorporated under the laws of Germany, is operating a music fundraising
        and music royalty rights trading platform, which includes music competitions, known as “Djooky” located at
        <a href="https://djookyx.com" target="_blank" class="document-link">https://djookyx.com</a> (“Platform”);
      </p>

      <p class="document-main-text">
        WHEREAS, the Artist is the creator (or co-creator) of original music composition (and/or underlying sound
        recording) and the owner (or co-owner) of the related intellectual property rights including current and
        future claims for remuneration arising from the use and/or exploitation of such music composition
        (and/or sound recording) (the “Rights” as defined in the
        <a href="https://djookyx.com/terms-conditions/" target="_blank" class="document-link">Terms of Use of the Platform</a>).
      </p>

      <p class="document-main-text">
        WHEREAS, the Artist is starting the initial sale of a minority share in his/her Rights and offers an Investor
        to buy an ownership in his/her Rights in the music composition and/or sound recording under the terms and conditions of this Agreement (the “Initial Sale”);
      </p>

      <p class="document-main-text">
        WHEREAS, the Initial Sale shall be effected through a Dutch Auction process, subject to the clearing price
        resulting in aggregate proceeds equal to or greater than a specified minimum threshold within a specified
        time period within the Agreement (as laid out in the <a href="https://djookyx.com/terms-conditions/" target="_blank" class="document-link">Terms of Use of the Platform</a>).
      </p>

      <p class="document-main-text">
        WHEREAS, the Parties by signing this Agreement warrants that they are binding by this Agreement
        and subject to <a href="https://djookyx.com/terms-conditions/" target="_blank" class="document-link">Terms of Use of the Platform</a> ;
      </p>

      <div class="document-border_line"></div>

      <p class="document-main-text">
        NOW, THEREFORE, in consideration of the terms and conditions contained in this Initial Sale Agreement,
        and for other good and valuable consideration, the sufficiency of which are hereby acknowledged, the Artist and the Investor hereby agree as follows:
      </p>

    </div>

    <div class="document-section">

      <h3 class="document-text-title">Initial sale offering</h3>
      <p class="document-text">
        Subject to section 2 below, Artist assigns to the Investor an undivided fractional share (percentage interest)
        in the Rights relating to the music composition  and/or sound recording set out in Exhibit A (the “Sold Rights”)
        in return of funds which the Investor will invest in the Artist music composition in accordance with the Terms of Use of the Platform.
      </p>
      <p class="document-text">
        The Agreement is considered valid and in effect only in case of successfully raised funds via the Initial
        Sale in accordance with the <a href="https://djookyx.com/terms-conditions/" target="_blank" class="document-link underline">Terms of Use of the Platform</a> .
      </p>

      <h3 class="document-text-title">Auction procedure</h3>
      <p class="document-text">
        During the Term (as defined in Exhibit A) and after successful achieving the Threshold (as defined in the Exhibit A)
        within the Platform the percentage interest of the Rights set out in Exhibit A is
        considered assigned to the Investor (subject to clearing of funds).
      </p>
      <p class="document-text">
        Detailed explanation of the auction procedure is subject to
        the <a href="https://djookyx.com/terms-conditions/" target="_blank" class="document-link underline">Terms of Use of the Platform</a> .
      </p>

    </div>

    <div class="border-page"></div>

  </div>
  <!--PAGE #2-->
  <div class="format-a4">

    <div class="document-section">

      <h3 class="document-text-title">Sales proceeds</h3>
      <p class="document-text">
        By entering into this Agreement, the Artist guarantees that within the Platform he/she has entered into
        signed the relevant agreements with third party service providers as set out in Exhibit A and that those
        parties will receive the corresponding amounts from the Threshold for their services (in accordance with those agreements).
      </p>

      <h3 class="document-text-title">Royalty agent</h3>
      <p class="document-text">
        The Investor is aware and agrees that for his/her Sold Rights the Artist has irrevocably appointed a royalty
        agent for the purposes of receiving, collecting and disbursing royalty and other payments received in
        respect of the relevant Rights subject to <a href="https://djookyx.com/terms-conditions/" target="_blank" class="document-link underline">Terms of Use of the Platform</a> .
      </p>

      <h3 class="document-text-title">Escrow agent</h3>
      <p class="document-text">
        In order to obtain the Sold Rights, the Investor must have deposited sufficient money with an Escrow Agent
        subject to <a href="https://djookyx.com/terms-conditions/" target="_blank" class="document-link underline">Terms of Use of the Platform</a> .
      </p>

      <h3 class="document-text-title">Form of an Agreement</h3>
      <p class="document-text">
        This Agreement may be signed or otherwise executed in counterparts and a facsimile,
        scanned PDF or electronic version of this Agreement shall be adequate and binding.
      </p>

    </div>

    <div class="document-section">

      <div class="document-border_line"></div>

      <p class="document-main-text uppercase">
        BY BY CLICKING ON “I HAVE READ AND AGREE TO THE INITIAL SALE AGREEMENT”, PARTIES ACKNOWLEDGE THAT:
        <br>(1) PARTIES HAVE READ ALL PROVISIONS, DISCLAIMERS, AND DISCLOSURES MADE HEREIN AND IN THE TERMS OF USE OF THE PLATFORM;
        <br>(2) PARTIES UNDERSTAND ALL SUCH PROVISIONS, DISCLAIMERS, AND DISCLOSURES AND HAVE ENTERED INTO THIS AGREEMENT VOLUNTARILY.
        <br>THE <a href="https://djookyx.com/terms-conditions/" target="_blank" class="document-link uppercase">Terms of Use of the Platform</a> .
      </p>

      <div class="document-border_line"></div>


    </div>

    <div class="border-page"></div>


  </div>
  <!--PAGE #3-->
  <div class="format-a4">

    <div class="exhibit-header">

      <div class="exhibit-header-titles-container">
        <div class="exhibit-header-titles">

          <div class="exhibit-title-wrap">
            <h2 class="document-title">EXHIBIT A</h2>
          </div>

          <div class="exhibit-subtitle-wrap">
            <span class="exhibit-subtitle">to the Initial Sale Agreement</span>
          </div>

        </div>
      </div>

      <div class="djooky-logo">
        <svg width="253" height="39" viewBox="0 0 253 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M137.724 15.1813L150.582 30.345H142.614L129.668 15.2165L142.614 0.0175781H150.582L137.724 15.1813Z" fill="#FE0190"/>
          <path d="M6.68393 24.7334H12.9809C19.7352 24.7334 21.7755 19.5616 21.7755 15.111C21.7755 9.95673 19.3482 5.50609 12.9809 5.50609H6.68393V24.7334ZM13.7196 0C23.5345 0 28.5474 6.84304 28.5474 15.1989C28.5474 23.9946 23.6752 30.3274 13.0161 30.3274H0V0H13.7196Z" fill="#FE0190"/>
          <path d="M46.2949 27.3897C46.5511 30.6305 45.5987 33.8514 43.6213 36.4316C42.2142 38.1908 40.1034 39 36.5856 39H34.8267V33.0716C35.1433 33.0716 35.5478 33.1597 35.8468 33.1597C38.45 33.1597 39.6285 30.9432 39.6285 27.5657V0H46.2949V27.3897Z" fill="#FE0190"/>
          <path d="M70.832 6.24487C65.7311 6.24487 62.354 9.92155 62.354 15.3573C62.354 20.793 65.7311 24.4695 70.832 24.4695C75.9329 24.4695 79.31 20.9513 79.31 15.3573C79.31 9.76323 75.9329 6.24487 70.832 6.24487ZM70.832 30.7672C67.7901 30.7811 64.8124 29.8921 62.2758 28.2127C59.7393 26.5334 57.7579 24.1392 56.5826 21.3331C55.4072 18.527 55.0907 15.4352 55.673 12.4492C56.2554 9.46311 57.7104 6.71701 59.854 4.55838C61.9977 2.39974 64.7334 0.925662 67.7151 0.322729C70.6967 -0.280204 73.7902 0.0150773 76.604 1.17126C79.4177 2.32745 81.8252 4.29255 83.5218 6.81779C85.2184 9.34303 86.1277 12.3149 86.1347 15.3573C86.1848 17.3857 85.8241 19.4033 85.0742 21.2886C84.3242 23.174 83.2006 24.888 81.771 26.3277C80.3413 27.7673 78.6352 28.9029 76.7553 29.6659C74.8754 30.4288 72.8605 30.8034 70.832 30.7672Z" fill="#FE0190"/>
          <path d="M108.825 6.24487C103.724 6.24487 100.347 9.92155 100.347 15.3573C100.347 20.793 103.724 24.4695 108.825 24.4695C113.926 24.4695 117.303 20.9513 117.303 15.3573C117.303 9.76323 113.926 6.24487 108.825 6.24487ZM108.825 30.7672C105.783 30.7811 102.805 29.8921 100.268 28.2127C97.7319 26.5334 95.7506 24.1392 94.5752 21.3331C93.3999 18.527 93.0833 15.4352 93.6657 12.4492C94.248 9.46311 95.7031 6.71701 97.8467 4.55838C99.9903 2.39974 102.726 0.925662 105.708 0.322729C108.689 -0.280204 111.783 0.0150773 114.597 1.17126C117.41 2.32745 119.818 4.29255 121.514 6.81779C123.211 9.34303 124.12 12.3149 124.127 15.3573C124.178 17.3857 123.817 19.4033 123.067 21.2886C122.317 23.174 121.193 24.888 119.764 26.3277C118.334 27.7673 116.628 28.9029 114.748 29.6659C112.868 30.4288 110.853 30.8034 108.825 30.7672Z" fill="#FE0190"/>
          <path d="M166.957 16.1136L156.369 0.0175781H164.46L170.264 9.53448L176.139 0.0175781H184.16L173.641 16.1136V30.345H166.957V16.1136Z" fill="#FE0190"/>
          <path d="M221.273 15.1813L234.131 30.345H226.163L213.235 15.2165L226.163 0.0175781H234.131L221.273 15.1813Z" fill="#FE0190"/>
          <path d="M203.895 15.1813L191.037 30.345H199.005L211.933 15.2165L199.005 0.0175781H191.037L203.895 15.1813Z" fill="#FE0190"/>
        </svg>
      </div>

    </div>

    <div class="exhibit-content">

      <div class="exhibit-invest-container clearfix">

        <div class="exhibit-invest left-side">
          <h3 class="exhibit-invest-title">ARTIST <span class="invest-highlight">seller</span></h3>
          <div class="exhibit-invest-info">
            <div class="exhibit-invest-info-title">ID:</div>
            <div class="exhibit-invest-info-value"></div>
          </div>
          <div class="exhibit-invest-info">
            <div class="exhibit-invest-info-title">Full Name:</div>
            <div class="exhibit-invest-info-value"></div>
          </div>
        </div>

        <div class="exhibit-invest right-side">
          <h3 class="exhibit-invest-title">INVESTOR <span class="invest-highlight">buyer</span></h3>
          <div class="exhibit-invest-info">
            <div class="exhibit-invest-info-title">ID:</div>
            <div class="exhibit-invest-info-value"></div>
          </div>
          <div class="exhibit-invest-info">
            <div class="exhibit-invest-info-title">Full Name:</div>
            <div class="exhibit-invest-info-value"></div>
          </div>
        </div>

      </div>

      <div class="exhibit-info-list">

        <div class="exhibit-invest-info">
          <div class="exhibit-invest-info-title">Music Composition Title</div>
          <div class="exhibit-invest-info-value"></div>
        </div>

        <div class="exhibit-invest-info">
          <div class="exhibit-invest-info-title">Music Composition ID</div>
          <div class="exhibit-invest-info-value"></div>
        </div>

        <div class="exhibit-invest-info">
          <div class="exhibit-invest-info-title">Artist Name</div>
          <div class="exhibit-invest-info-value"></div>
        </div>

        <div class="exhibit-invest-info">
          <div class="exhibit-invest-info-title">Co-authors Names</div>
          <div class="exhibit-invest-info-value"></div>
        </div>

        <div class="exhibit-invest-info">
          <div class="exhibit-invest-info-title">Object of the Rights on offer</div>
          <div class="exhibit-invest-info-value">Music composition and Master recording</div>
        </div>

        <div class="exhibit-invest-info">
          <div class="exhibit-invest-info-title">Rights shares on offer (%)</div>
          <div class="exhibit-invest-info-value"></div>
        </div>

        <div class="exhibit-invest-info">
          <div class="exhibit-invest-info-title">Minimum bid price per share in the Rights on offer</div>
          <div class="exhibit-invest-info-value"></div>
        </div>

        <div class="exhibit-invest-info">
          <div class="exhibit-invest-info-title">Final amount of the Sold Rights purchase</div>
          <div class="exhibit-invest-info-value"></div>
        </div>

        <div class="exhibit-invest-info">
          <div class="exhibit-invest-info-title">Amount of Sold Rights acquired by Investor</div>
          <div class="exhibit-invest-info-value"></div>
        </div>

        <div class="exhibit-invest-info">
          <div class="exhibit-invest-info-title">Term of the auction</div>
          <div class="exhibit-invest-info-value"></div>
        </div>

        <div class="exhibit-invest-info">
          <div class="exhibit-invest-info-title">Threshold (minimum funding target)</div>
          <div class="exhibit-invest-info-value"></div>
        </div>

      </div>

    </div>

    <div class="border-page"></div>


  </div>


</div>
</template>

<script>
export default {
  name: "InitialSaleDocument",
  methods: {
  }
}
</script>

<style scoped>

.initial-sale-document-wrapper {
  /*padding-top: 50px;*/
  padding: 50px 15px 0 15px;
}

.format-a4 {
  position: relative;
  max-width: 487px;
  width: 100%;
  text-align: left;
  margin: 0 auto;
}

.pink-border {
  position: absolute;
  bottom: 0;
  height: 2.11mm;
  width: 210mm;
  background: #FF0090;
}

/*-----------------*/

.document-header {
  width: 100%;
  display: table;
  padding-bottom: 3.95mm;
  border-bottom: 0.26mm solid #E5E5E5;
  margin-bottom: 7.12mm;
}

.document-title-wrap {
  display: table-cell;
  text-align: left;
}

.document-title {
  margin: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 5.27mm;
  line-height: 7.91mm;
  color: #000000;
}

.djooky-logo {
  display: table-cell;
  text-align: right;
}

.djooky-logo svg {
  width: 100px;
  height: auto;
}

.document-section {
  margin-bottom: 8.44mm;
}

.document-main-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
  margin-bottom: 2.64mm;
}

.document-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 2.64mm;
}

.document-text-title {
  font-style: normal;
  font-weight: 600;
  font-size: 3.16mm;
  line-height: 3.95mm;
  color: #272727;
  margin-bottom: 1.32mm;
}

.document-border_line {
  height: 0.26mm;
  width: 100%;
  background: #E5E5E5;
  margin-bottom: 2.64mm;
}

.format-a4 .document-link {
  color: #FE0190;
  text-decoration: none;
  cursor: pointer;
}

.exhibit-header {
  width: 100%;
  display: table;
  margin-bottom: 15.03mm;
}

.exhibit-header .djooky-logo {
  vertical-align: middle;
}

.exhibit-header-titles-container {
  width: 69.85mm;
  display: table-cell;
  vertical-align: middle;
}

.exhibit-header-titles {
  width: 100%;
  display: table;
  vertical-align: middle;
}

.exhibit-title-wrap {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

.exhibit-subtitle-wrap {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  border-left: 0.26mm solid #000000;
}

.exhibit-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /*line-height: 3.95mm;*/
  color: #000000;
}

.exhibit-content {
  width: 100%;
  padding-bottom: 4.75mm;
  border-bottom: 0.26mm solid #E5E5E5;
}

.exhibit-invest-container {
  width: 100%;
  /*display: block;*/
  margin-bottom: 4.22mm;
}

.clearfix {
  zoom: 1;
}

.clearfix:before,
.clearfix:after {
  content: '';
  display: table;
}

.clearfix:after {
  clear: both;
}

.exhibit-invest {
  display: block;
  width: calc(50% - 7.12mm);
  border-top: 0.26mm solid #E5E5E5;
  border-bottom: 0.26mm solid #E5E5E5;
}

.exhibit-invest.left-side {
  float: left;
}

.exhibit-invest.right-side {
  float: right;
}

.exhibit-invest-title {
  padding-top: 2.37mm;
  padding-bottom: 2.37mm;
  border-bottom: 0.26mm solid #E5E5E5;
  margin-top: 0;
  margin-bottom: 4.22mm;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 3.69mm;
  text-transform: uppercase;
  color: #000000;
}

.invest-highlight {
  color: #FE0190;
}

.exhibit-invest-info {
  width: 100%;
  margin-bottom: 0.79mm;
  display: table;
}

.exhibit-invest-info-title {
  display: table-cell;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 3.69mm;
  color: #8C8C8C;
}

.exhibit-invest-info-value {
  display: table-cell;
  text-align: right;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 3.69mm;
  color: #000000;
}

.exhibit-info-list {
  width: 100%;
}

.exhibit-info-list .exhibit-invest-info {
  margin-bottom: 2.64mm;
}

.exhibit-info-list .exhibit-invest-info:last-of-type {
  margin-bottom: 0;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.border-page {
  width: 100%;
  height: 6px;
  background: #FF0090;
  margin-bottom: 30px;
}

@media only screen and (max-width: 500px) {
  .initial-sale-document-wrapper {
    padding-right: 25px;
    zoom: 0.6;
    -ms-zoom: 0.6;
  }

  .document-title {
    font-size: 12px;
    line-height: 12px;
  }

  .exhibit-subtitle-wrap {
    text-align: center;

  }

  .exhibit-subtitle {
    font-size: 8px;
    line-height: 8px;
  }
}

</style>