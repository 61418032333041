<template>
  <div class="potential-artist-songs-list-item">

    <div class="potential-artist-songs-item-left">

      <div class="potential-artist-songs-image">
        <button class="potential-artist-songs-image-play">
          <div :class="togglePlayerFlag ?
          'hitparad_button-play hitparad_button-pause'
          : 'hitparad_button-play hitparad_button-play-active'" @click="toggleMusic">
            <div class="hitparad_button-play-progress">
              <canvas width="40" height="40"></canvas>
              <canvas width="40" height="40"></canvas>
            </div>
          </div>
        </button>
        <img v-if="song.albumCover" :src="song.albumCover" alt="Songs image" class="potential-artist-song-photo" loading="lazy">
        <img v-else src="../../assets/img/profile-default-image.png" alt="Default songs image"
             class="potential-artist-song-photo potential-artist-song-photo-default" loading="lazy">
      </div>

    </div>

    <div class="potential-artist-songs-item-right">

      <div class="potential-artist-songs-info">

        <div class="potential-artist-songs-info-main">
          <div class="potential-artist-songs-name" v-text="song.title"></div>
          <div class="potential-artist-name" v-text="artistName"></div>
        </div>

        <div class="potential-artist-songs-info-more desktop" v-if="screenWidthFlag === 'desktop'">
          <div class="audio-player-wrapper">
            <AudioPlayer
                :src="playerSrc"
                :togglePlayerFlag="togglePlayerFlag"
                :width="'152px'"
                v-if="playerSrc"
                :ref="`audioPlayer${identifier}${index}`"
                @audioPause="togglePlayerFlag = true"
                :identifier="identifier"
                :index="index"
            />
          </div>
          <div class="potential-artist-songs-length"></div>
          <div class="potential-artist-songs-genre-list" v-if="song.genreId">
            <div class="potential-artist-songs-genre-list-item" v-text="song.genreId"></div>
          </div>
        </div>

      </div>

<!--      <button class="btn btn-hover potential-artist-wants-btn"-->
<!--              @click="sendEmail"-->
<!--      >I want to buy this song</button>-->

      <button class="potential-artist-wants-btn"
              @click="sendEmail"
              :disabled="disableSending"
      >
        <svg :class="{'disabled-sending': disableSending}"
             width="33" height="29" viewBox="0 0 33 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M29.3909 3.72265C28.6461 2.97745 27.7617 2.3863 26.7883 1.98298C25.815 1.57966 24.7717 1.37207 23.718 1.37207C22.6644 1.37207 21.6211 1.57966 20.6477 1.98298C19.6743 2.3863 18.79 2.97745 18.0451 3.72265L16.4993 5.26848L14.9534 3.72265C13.4489 2.2181 11.4083 1.37285 9.28053 1.37285C7.15277 1.37285 5.11216 2.2181 3.60761 3.72265C2.10306 5.2272 1.25781 7.26781 1.25781 9.39557C1.25781 11.5233 2.10306 13.5639 3.60761 15.0685L5.15344 16.6143L16.4993 27.9601L27.8451 16.6143L29.3909 15.0685C30.1361 14.3236 30.7273 13.4393 31.1306 12.4659C31.5339 11.4925 31.7415 10.4492 31.7415 9.39557C31.7415 8.34194 31.5339 7.29863 31.1306 6.32526C30.7273 5.35188 30.1361 4.4675 29.3909 3.72265V3.72265Z"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>


    </div>

    <div class="potential-artist-songs-item-bottom"  v-if="screenWidthFlag === 'mobile'">

      <div class="potential-artist-songs-info-more">
        <div class="potential-artist-songs-length"></div>
        <div class="potential-artist-songs-genre-list" v-if="song.genreId">
          <div class="potential-artist-songs-genre-list-item" v-text="song.genreId"></div>
        </div>
      </div>

      <div class="audio-player-wrapper">
        <AudioPlayer
            :src="playerSrc"
            :togglePlayerFlag="togglePlayerFlag"
            :width="'152px'"
            v-if="playerSrc"
            :ref="`audioPlayer${identifier}${index}`"
            @audioPause="togglePlayerFlag = true"
            :identifier="identifier"
            :index="index"
        />
      </div>

    </div>

  </div>
</template>

<script>
import AudioPlayer from "@/components/AudioPlayer";

export default {
  name: "ArtistDjookyAwardsProfileList",
  components: {AudioPlayer},
  props: {
    song: {
      type: Object,
      default: () => {}
    },
    logged: Boolean,
    current: String,
    artistName: String,
    identifier: {
      type: String
    },
    index: {
      type: Number
    },
  },
  data() {
    return {
      mp3: '',
      playerSrc: null,
      togglePlayerFlag: true,
      artistId: null,
      songId: null,
      screenWidthFlag: null
    }
  },
  computed: {
    disableSending() {
      return this.song.isSubscribed
    },
  },
  methods: {
    toggleMusic() {
      if(this.togglePlayerFlag) {
        this.togglePlayerFlag = false
        this.$emit('playingMusic', this.mp3)
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      } else {
        this.togglePlayerFlag = true
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      }
    },
    sendEmail() {
      this.artistId = this.song.authorId
      this.songId = this.song.id
      this.$emit('sendEmail', this.artistId, this.songId)
    },
    resizeHandler() {
      let width = window.innerWidth
      if (width >= 768) {
        this.screenWidthFlag = 'desktop'
      } else {
        this.screenWidthFlag = 'mobile'
      }

      console.log(this.screenWidthFlag)
      // if (width < 1200) {
      //   this.screenWidthFlag = 'mobile'
      // }
    },
  },
  watch: {
    'current'(newVal) {
      if (newVal !== this.mp3) {
        this.$refs[`audioPlayer${this.identifier}${this.index}`].refreshSong()
        this.togglePlayerFlag = true
      }
    },
  },
  mounted() {
    this.mp3 = this.song.songFile
    this.playerSrc = this.mp3
    window.addEventListener("resize", this.resizeHandler, false);
    this.resizeHandler()
  },
  beforeDestroy() {
    this.togglePlayerFlag = true
  },
}
</script>

<style scoped>

.potential-artist-songs-list-item {
  display: flex;
  justify-content: space-between;
  background: #FFFFFF;
  box-sizing: border-box;
  margin-bottom: 22px;
}

.potential-artist-songs-list-item:last-child {
  margin-bottom: 0;
}

.potential-artist-songs-item-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.potential-artist-songs-item-right {
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: calc(100% - 114px);
  border-bottom: 1px solid #EAEAEA;
  padding-bottom: 22px;
}

.potential-artist-songs-item-bottom {
  display: none;
}

.potential-artist-wants-btn {
  padding-right: 40px;
}

.potential-artist-wants-btn path {
  stroke: #FF0090;
  fill: #FFFFFF;
  transition: .5s ease;
}

.potential-artist-wants-btn:hover path {
  fill: #FF0090;
  stroke: #FFFFFF;
}

.potential-artist-songs-image {
  position: relative;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  margin-right: 38px;
}

.potential-artist-songs-image-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.potential-artist-song-photo {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.potential-artist-songs-info {
  width: calc(100% - 130px);
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding-top: 8px;
}

.potential-artist-songs-info-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.potential-artist-songs-name {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  color: #272727;
  margin-bottom: 13px;
}

.potential-artist-name {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #727272;
}

.potential-artist-songs-info-more {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}

.potential-artist-songs-info-more.desktop .audio-player-wrapper, .potential-artist-songs-item-bottom .audio-player-wrapper{
  background: none;
  position: relative;
  width: unset;
}

.potential-artist-songs-item-bottom .audio-player-wrapper {
  top: 10px;
}

.potential-artist-songs-info-more.desktop .audio-player-wrap, .potential-artist-songs-item-bottom .audio-player-wrap{
  width: unset;
}

.potential-artist-songs-length {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: #272727;
}

.potential-artist-songs-genre-list {
  display: flex;
  align-items: center;
}

.potential-artist-songs-genre-list-item {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #272727;
  padding: 3px 13px;
  background: #FFFFFF;
  border: 1px solid #272727;
  box-sizing: border-box;
  border-radius: 108px;
}

.potential-artist-songs-list-item .hitparad_button-play {
  width: 40px;
  height: 40px;
}

.disabled-sending path {
  fill: #FF0090;
  stroke: #FFFFFF;
}

.potential-artist-wants-btn:hover .disabled-sending path {
  stroke: #FFFFFF;
}

.audio-player-wrapper {
  color: #000000;
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 992px) {
  .potential-artist-songs-name {
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 190px;
    white-space: nowrap;
  }

  .potential-artist-name {
    text-overflow: ellipsis;
    overflow-x: hidden;
    max-width: 190px;
    white-space: nowrap;
  }

  .potential-artist-songs-info {
    width: calc(100% - 110px);
  }

  .potential-artist-wants-btn {
    padding-right: 10px;
  }

  .potential-artist-wants-btn svg {
    width: 42px;
    height: auto;
  }

  .potential-artist-songs-item-right {
    border-bottom: none;
    padding-bottom: 0;
    width: calc(100% - 67px);
  }

  .potential-artist-songs-list-item {
    padding-bottom: 17px;
    border-bottom: 1px solid #EAEAEA;
  }

  .potential-artist-songs-image {
    width: 55px;
    height: 55px;
    margin-right: 10px;
  }

  .potential-artist-songs-list-item .hitparad_button-play {
    width: 32px;
    height: 32px;
  }

  .hitparad_button-play-progress canvas {
    width: 32px;
    height: 32px;
  }

  .potential-artist-songs-info {
    padding-top: 0;
  }

  .potential-artist-songs-info-main {
    width: calc(100% - 150px);
  }

}

@media only screen and (max-width: 768px) {
  .potential-artist-songs-name {
    max-width: 110px;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 6px;
  }

  .potential-artist-name {
    max-width: 110px;
    font-size: 14px;
    line-height: 17px;
  }

  .potential-artist-songs-list-item {
    flex-wrap: wrap;
  }

  .potential-artist-songs-info-more.desktop {
    display: none;
  }

  .potential-artist-songs-item-bottom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  .potential-artist-songs-item-bottom .potential-artist-songs-info-more {
      flex-direction: row;
      justify-content: flex-start;
      margin-top: 16px;
  }

  .potential-artist-songs-info-main {
    width: 100%;
  }

}

@media only screen and (max-width: 540px) {

}

</style>