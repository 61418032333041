<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>


<script>
import { mapActions } from "vuex";
import concordiumAPI from './helpers/concordium'

concordiumAPI.concordInit();

export default {
  name: "App",
  data() {
    return {
      news: [],
      user: null,
      isLogged: this.$store.state.authentication.status.loggedIn,
    };
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
      setLink: "staticLinks/setLinks",
    }),
  },
  computed: {
    alert() {
      return this.$store.state.alert;
    },
  },
  watch: {
    $route() {
      // clear alert on location change
      this.$store.dispatch("alert/clear");
    },
  },
  mounted() {
    if(this.isLogged) {
      this.setUser()
    }
  },
};
</script>

<style>
.reports__table-wrapper .v-data-table__wrapper {
  border: 1px solid #aeaeae !important;
  border-radius: 5px !important;
  max-height: 900px !important;
}

.reports__table-wrapper
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

.reports__table-wrapper tbody tr {
  position: relative;
}

.reports__table-wrapper tbody tr:after {
  position: absolute;
  content: "";
  height: 1px;
  width: calc(100% - 50px);
  background: #dedede;
  bottom: 0;
  left: 20px;
}

.royalty-wrapper .v-data-table__wrapper table,
.reports__table-wrapper .v-data-table__wrapper table {
  min-width: 700px;
}

.add-song-photo-fields-item .general_select-selected {
  padding-left: 30px !important;
}

.add-song-photo-fields-item .selected-text.placeholder {
  opacity: 1 !important;
  color: #5a5a5a;
}

.add-song-photo-fields-item .general_select-selected + .general_select-arrow {
  right: 30px !important;
}

.audio-player-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(67, 67, 67, 0.5);
  display: flex;
  align-items: center;
  padding: 0 12px;
}

@media only screen and (max-width: 850px) {
  .add-song-photo-fields-item .selected-text.placeholder {
    font-size: 16px;
  }
}

@media only screen and (max-width: 539px) {
  .add-song-photo-fields-item .general_select-selected {
    padding-left: 20px !important;
  }

  .add-song-photo-fields-item .general_select-selected + .general_select-arrow {
    right: 16px !important;
  }
}
</style>
