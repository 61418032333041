<template>
  <div class="admin-document-wrapper" ref="top">
    <!--PAGE #1-->
    <div class="format-a4">


      <div class="djooky-center">
        <div class="djooky-logo">
          <svg width="253" height="39" viewBox="0 0 253 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M137.724 15.1813L150.582 30.345H142.614L129.668 15.2165L142.614 0.0175781H150.582L137.724 15.1813Z" fill="#FE0190"/>
            <path d="M6.68393 24.7334H12.9809C19.7352 24.7334 21.7755 19.5616 21.7755 15.111C21.7755 9.95673 19.3482 5.50609 12.9809 5.50609H6.68393V24.7334ZM13.7196 0C23.5345 0 28.5474 6.84304 28.5474 15.1989C28.5474 23.9946 23.6752 30.3274 13.0161 30.3274H0V0H13.7196Z" fill="#FE0190"/>
            <path d="M46.2949 27.3897C46.5511 30.6305 45.5987 33.8514 43.6213 36.4316C42.2142 38.1908 40.1034 39 36.5856 39H34.8267V33.0716C35.1433 33.0716 35.5478 33.1597 35.8468 33.1597C38.45 33.1597 39.6285 30.9432 39.6285 27.5657V0H46.2949V27.3897Z" fill="#FE0190"/>
            <path d="M70.832 6.24487C65.7311 6.24487 62.354 9.92155 62.354 15.3573C62.354 20.793 65.7311 24.4695 70.832 24.4695C75.9329 24.4695 79.31 20.9513 79.31 15.3573C79.31 9.76323 75.9329 6.24487 70.832 6.24487ZM70.832 30.7672C67.7901 30.7811 64.8124 29.8921 62.2758 28.2127C59.7393 26.5334 57.7579 24.1392 56.5826 21.3331C55.4072 18.527 55.0907 15.4352 55.673 12.4492C56.2554 9.46311 57.7104 6.71701 59.854 4.55838C61.9977 2.39974 64.7334 0.925662 67.7151 0.322729C70.6967 -0.280204 73.7902 0.0150773 76.604 1.17126C79.4177 2.32745 81.8252 4.29255 83.5218 6.81779C85.2184 9.34303 86.1277 12.3149 86.1347 15.3573C86.1848 17.3857 85.8241 19.4033 85.0742 21.2886C84.3242 23.174 83.2006 24.888 81.771 26.3277C80.3413 27.7673 78.6352 28.9029 76.7553 29.6659C74.8754 30.4288 72.8605 30.8034 70.832 30.7672Z" fill="#FE0190"/>
            <path d="M108.825 6.24487C103.724 6.24487 100.347 9.92155 100.347 15.3573C100.347 20.793 103.724 24.4695 108.825 24.4695C113.926 24.4695 117.303 20.9513 117.303 15.3573C117.303 9.76323 113.926 6.24487 108.825 6.24487ZM108.825 30.7672C105.783 30.7811 102.805 29.8921 100.268 28.2127C97.7319 26.5334 95.7506 24.1392 94.5752 21.3331C93.3999 18.527 93.0833 15.4352 93.6657 12.4492C94.248 9.46311 95.7031 6.71701 97.8467 4.55838C99.9903 2.39974 102.726 0.925662 105.708 0.322729C108.689 -0.280204 111.783 0.0150773 114.597 1.17126C117.41 2.32745 119.818 4.29255 121.514 6.81779C123.211 9.34303 124.12 12.3149 124.127 15.3573C124.178 17.3857 123.817 19.4033 123.067 21.2886C122.317 23.174 121.193 24.888 119.764 26.3277C118.334 27.7673 116.628 28.9029 114.748 29.6659C112.868 30.4288 110.853 30.8034 108.825 30.7672Z" fill="#FE0190"/>
            <path d="M166.957 16.1136L156.369 0.0175781H164.46L170.264 9.53448L176.139 0.0175781H184.16L173.641 16.1136V30.345H166.957V16.1136Z" fill="#FE0190"/>
            <path d="M221.273 15.1813L234.131 30.345H226.163L213.235 15.2165L226.163 0.0175781H234.131L221.273 15.1813Z" fill="#FE0190"/>
            <path d="M203.895 15.1813L191.037 30.345H199.005L211.933 15.2165L199.005 0.0175781H191.037L203.895 15.1813Z" fill="#FE0190"/>
          </svg>
        </div>

        <div class="djooky-main-title">MUSIC PUBLISHING <br> ADMINISTRATION AGREEMENT</div>

        <div class="main-small">between</div>

        <div class="djooky-title">Djooky Records Inc.</div>

        <div class="djooky-main-description">
          a Delaware corporation with its registered office at 16192 Coastal Highway, Lewes, Delaware, 19958,
          County of Sussex, represented by its Director Brian Malouf
        </div>

        <div class="main-small">(Company)</div>
      </div>


      <div class="signature-wrap">
        <div class="signature artist">
          <div class="dynamic-value" id="artistName"></div>
          <div class="signature-description" v-text="userTypeCheck"></div>
        </div>
        <div class="signature date">
          <div class="dynamic-value" id="closingDate"></div>
          <div class="signature-description">Effective Date</div>
        </div>
      </div>

      <div class="table">
        <div class="table-head-title">Executive Summary:</div>
        <table>
          <tbody>
          <tr>
            <td>Music Composition</td>
            <td id="songName"> </td>
          </tr>
          <tr>
            <td>Term</td>
            <td>Perpetuity</td>
          </tr>
          <tr>
            <td>Territory</td>
            <td>Worldwide</td>
          </tr>
          <tr>
            <td>Company share</td>
            <!--                <td>5% from the Artist share of gross revenues collected</td>-->
            <td>25% from the Artist share of gross revenues collected from Music Composition</td>
          </tr>
          <tr>
            <td>Reporting and Payment</td>
            <td>Four times per year</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="djooky-title bold">
        In consideration of the mutual obligations as set out in this Agreement, it is agreed as follows
        (binding provisions):
      </div>

      <div class="djooky-subtitle">1. Subject</div>
      <div class="djooky-main-text">
        This music publishing administration agreement (hereinafter – the Agreement), regulates the rights and
        obligations of the Company and the Artist (together - Parties) to each other in connection with the
        commercialization of the Artist’s Music Composition.
      </div>

      <div class="djooky-subtitle">2. Administrative Rights</div>
      <div class="djooky-main-text">
        The Company is granted the exclusive rights in the Territory during the Term to administration of Music
        Composition (i.e., publishing) of the Artist, whether it is written alone or with others. This includes
        (but is not limited to) the following rights:
      </div>


    </div>


    <!--PAGE #2-->
    <div class="format-a4">

      <ul class="djooky-list">
        <li class="list-item">
          the right to reproduce and exploit copies of the Music Composition in any media
          (including audio and audio-visual) that allows the Music Composition to be reproduced
          (either physical or digital), to make Music Composition available to the public by any
          means now known or in created in the future (including as part of a blanket license);
        </li>
        <li class="list-item">
          to make edits, adaptations, translations and additions in or to the Music Composition (including user
          generated content);
        </li>
        <li class="list-item">
          to procure and/or provide perpetual or limited licenses or non-exclusive or exclusive licenses for
          synchronization / sound recording of the Music Composition;
        </li>
        <li class="list-item">
          to exploit the so-called “grand rights” of the Music Composition (dramatic / musical adaptations
          of the Composition for the stage or screen);
        </li>
        <li class="list-item">
          to exploit the Music Composition through rental, streaming or through other facilities
          that give the public access to the Music Composition; and
        </li>
        <li class="list-item">
          to exploit other rights on behalf of the right holder of the Music Composition, both rights and
          exploitation opportunities available now and which arises in the future, regardless of the method used.
        </li>
        <li class="list-item">
          Direct performance rights licensing (if applicable).
        </li>
        <li class="list-item">
          Collect all income generated by the Music Composition (except for where applicable the “writer share”
          of any performance society royalties that are collected directly by writer).
        </li>
      </ul>

      <div class="djooky-main-text">
        The Artist’s consent shall not be required/obtained before the Company makes use of rights
        covered by paragraphs (i) - (vi) above.
      </div>

      <div class="djooky-subtitle">3. Exclusivity</div>
      <div class="djooky-main-text">
        The Company will retain the exclusive rights mentioned in this Agreement during the Term.
      </div>

      <div class="djooky-subtitle">4. Registration of Copyright</div>
      <div class="djooky-main-text">
        If the copyright to Music Composition, or renewal or extension thereof, is contingent on notification,
        registration or any other measures, the Artist grants the Company the right via a power of attorney to provide
        registration (or assignment) and undertake the measures required to ensure copyright protection of the Music
        Composition and with performance rights societies. The Artist shall timely participate in such initiatives if
        required for implementation.
      </div>

      <div class="djooky-subtitle">5. Warrants and Representations.</div>
      <div class="djooky-main-text">
        The Artist warrants that the Artist has the full right, power and authority to enter into this Agreement.
        The Artist has not or never will sign any third party agreement that conflicts with this Agreement.
        The Artist warrants that the Artist holds and / or have full right and authority to transfer all rights
        to the Music Composition under this Agreement and that those granted rights are not in any way limited
        with regard to the ownership or right of disposal of other third parties. The Artist guarantees that Music
        Composition does not violate or is not in violation of someone else’s copyright or contractual rights. The
        Artist warrants that he or she will keep the Company informed about their current and up-to date e-mail,
        mail and bank accounts, as well as its potential tax liabilities.The Artist undertakes to keep the Company
        harmless against any loss, any liability, any damage and any expenses (including, but not limited to, costs
        and reasonable attorney’s fees) caused by the Artist’s breach of the Agreement (and Company is allowed to
        offset from Artist’s royalties if Artist fails to indemnify).
      </div>

      <div class="djooky-subtitle">6. Confidentiality. </div>
      <div class="djooky-main-text">
        The content of this Agreement shall be confidential between the Parties. However, this does not cover
        information for legal or other assistance as deemed necessary to ensure the Parties’ rights.
      </div>

      <div class="border-page"></div>

    </div>


    <!--PAGE #3-->
    <div class="format-a4">

      <div class="djooky-subtitle">7. Jurisdiction. </div>
      <div class="djooky-main-text">
        This Agreement shall be construed in accordance with, and all disputes hereunder shall be governed by, the laws of the state of Delaware.
      </div>

      <div class="djooky-subtitle">8. Legal Assistance. </div>
      <div class="djooky-main-text">
        The Artist acknowledges by signature on this agreement that he/she used expert legal advice before entering into
        this Agreement (or voluntarily waived the right to do so), and fully understands the contents and consequences of this Agreement.
      </div>

      <div class="djooky-subtitle">9. Expenses</div>
      <div class="djooky-main-text">
        Customary and reasonable administrative and exploitation expenses incurred by Company hereunder with respect to
        the Music Composition including, without limitation, copyright registration fees, the costs of transcribing for
        lead sheets, the costs of producing demonstration records, advertising and promotion expenses directly related
        to the Composition, etc. shall be recoupable by Company off the top of the Artist share. The administrative
        and exploitation expenses which reach USD 500 shall be mutually agreed with the Artist.
      </div>

      <div class="djooky-subtitle">10. Name &amp; Likeness</div>
      <div class="djooky-main-text">
        Artist hereby grants to Administrator the right to use and publish and to permit others to use and publish
        Artist’s name and logo, and the names, likenesses and biographical material pertaining to him/her
        (or any collaborators) in connection with the exploitation of the Music Composition during the Term.
      </div>

      <div class="djooky-subtitle">11. Assignment</div>
      <div class="djooky-main-text">
        Administrator shall have the right to assign this Agreement or any of its rights hereunder and to delegate any
        of its obligations hereunder, in whole or in part, to any person, firm, or corporation. Without limiting the
        generality of the foregoing, Administrator shall have the right to enter into subpublishing, collection, print
        or other agreements with respect to the Music Composition with any persons, firms, or corporations. The Artist
        acknowledges that Administrator can designate the sub escrow agent to collect the royalties hereunder.
      </div>

      <div class="djooky-subtitle">12. Entire Agreement</div>
      <div class="djooky-main-text">
        This Agreement constitutes the entire understanding between the Parties and there are no other agreements or
        understandings, written or oral, in effect between the Parties relative to the subject matter hereof.
        This Agreement may not be modified or terminated except by written agreement of both parties. This
        Agreement shall be deemed fully executed once the Artist clicks the accept box below.
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "AdminDocument",
  props: {
    userType: String
  },
  computed: {
    userTypeCheck() {
      return this.userType === 'ARTIST' || this.userType === 'INVESTOR' ? 'Artist' : 'Company'
    }
  }
}
</script>

<style scoped>

/*@page {*/
/*  size: 210mm 297mm;*/
/*  margin: 16.94mm 0 0 0;*/
/*}*/

.admin-document-wrapper {
  padding: 0 15px;
}

.format-a4 {
  position: relative;
  max-width: 487px;
  width: 100%;
  text-align: left;
  margin: 0 auto;
}

.djooky-center {
  text-align: center;
}
.djooky-logo {
  display: inline-block;
  text-align: center;
  margin: 0 auto 10.94mm auto;
}

.djooky-logo svg {
  width: 100px;
  height: auto;
}

.djooky-main-title {
  font-style: normal;
  font-weight: bold;
  font-size: 7.06mm;
  line-height: 10.59mm;
  text-align: center;
  color: #000000;
}
.djooky-title {
  font-style: normal;
  font-weight: 600;
  font-size: 6mm;
  line-height: 8.82mm;
  color: #000000;
  margin-bottom: 1.76mm;
}
.djooky-main-description {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: center;
  color: #272727;
}
.main-small {
  margin: 3.53mm 0;
  font-style: normal;
  font-weight: normal;
  font-size: 3.53mm;
  line-height: 5.29mm;
  text-align: center;
  color: #8C8C8C;
}
.signature-wrap {
  width: 100%;
  display: inline-block;
  margin-top: 10.94mm;
}
.signature {
  display: inline-block;
  text-align: center;
}
.signature.artist {
  float: left;
  max-width: 60%;
  width: 100%;
}
.signature.date {
  float: right;
  max-width: 30%;
  width: 100%;
}
.signature-wrap .dynamic-value {
  max-width: initial;
  width: initial;
  font-style: normal;
  font-weight: normal;
  font-size: 4.24mm;
  line-height: 6mm;
  text-align: center;
  color: #272727;
}
.signature-description {
  border-top: 1px solid #000000;
  padding-top: 2.12mm;
  margin-top: 1mm;
  font-style: normal;
  font-weight: normal;
  font-size: 3.53mm;
  line-height: 5.29mm;
  color: #8C8C8C;
}
.table {
  margin-top: 15.88mm;
}
.table-head-title {
  font-style: normal;
  font-weight: normal;
  font-size: 4.24mm;
  line-height: 6mm;
  color: #272727;
}
.table table {
  margin-top: 3.88mm;
  width: 100%;
  border-radius: 1.76mm;
  border-collapse: collapse;
  border: 0.35mm solid #E5E5E5;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
  font-size: 3.53mm;
  line-height: 6mm;
  color: #272727;
}
.table table tr:nth-child(even) {
  background: #FAFAFA;

}

.table table tr {
  margin-bottom: 0;
  border-bottom: none;
}

.table table tr td {
  padding: 1.76mm 4.94mm;
  border-right: 0.35mm solid #E5E5E5;
  box-sizing: border-box;
  /*border: 0.35mm solid #E5E5E5;*/
  /*border-radius: 1.76mm;*/
}
.djooky-title.bold {
  width: 80%;
  margin: 14.82mm auto 7.06mm;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  color: #000000;
}
.djooky-subtitle {
  margin: 4.59mm 0 2.12mm 0;
  font-style: normal;
  font-weight: 600;
  font-size: 4.24mm;
  line-height: 4.94mm;
  color: #272727;
}
.djooky-main-text {
  font-style: normal;
  font-weight: normal;
  font-size: 3.53mm;
  line-height: 5.65mm;
  color: #8C8C8C;
}
.djooky-list {
  font-style: normal;
  font-weight: normal;
  font-size: 3.53mm;
  line-height: 5.64mm;
  color: #8C8C8C;
}

.djooky-list {
  padding-left: 40px !important;
  margin: 20px 0;
}

.list-item {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #8C8C8C;
}

@media only screen and (max-width: 500px) {
  .admin-document-wrapper {
    padding-right: 25px;
    zoom: 0.6;
    -ms-zoom: 0.6;
  }
}


</style>