const Ajv = require("ajv")
const ajv = new Ajv()
import axios from "axios"
import moment from "moment"
const FormData = require('form-data');

// let CERT_PREFIX = http://34.130.27.131
let CERT_HOST = "https://certificate.v-art.digital"
let CERT_API_URL = "api/certificates"
let CERT_DOWNLOAD_URL = "storage/certificates"


// const certHost = process.env.VUE_APP_CERTIFICATES_HOST
// const certApiUrl = process.env.VUE_APP_CERTIFICATES_API_URL
// const certDownloadUrl = process.env.VUE_APP_CERTIFICATES_DOWNLOAD_URL

const schema = {
    type: "object",
    properties: {
        preview_image: { type: "string" },
        author: { type: "string" },
        name: { type: "string" },
        year: { type: "string" },
        edition: { type: "string" },
        quantity: { type: "integer" },
        url: { type: "string" },
        currency: { enum: ["ethereum", "near"] },
        contract: { type: "string" },
        genre: { type: "string" },
        dimensions: { type: "string" },
        slug_link: { type: "string" },
        copyrights: {
            type: "array",
            items: {
                type: "string",
                enum: [
                    "adaption",
                    "storage",
                    "placement",
                    "publication",
                    "metadata",
                    "demonstration",
                    "personal_use",
                    "advertising"
                ]
            }

        },
        token_id: { type: "string" },
        creation_date: { type: "string" },
    },
    required: ["preview_image", "author", "name", "year", "edition", "quantity", "url",
        "currency", "contract", "genre", "dimensions", "slug_link", "copyrights",
        "token_id", "creation_date"
    ],
    additionalProperties: false
}



const validate = ajv.compile(schema)

class CertificatesApi {

    async downloadCertificate(certificateId) {
        // const pdfUrl = CERT_HOST + "/" + CERT_DOWNLOAD_URL + '/' + certificateId + "/certificate.pdf";
        const pdfUrl = "/cert/" + CERT_DOWNLOAD_URL + '/' + certificateId + "/certificate.pdf";
        // console.log(pdfUrl)
        // const proxyPdfUrl = pdfUrl.replaceAll(CERT_HOST, CERT_PREFIX)
        // return await axios({
        //     url: pdfUrl,
        //     method: "GET",
        //     responseType: "blob",
        // });

        var config = {
            method: 'GET',
            url: pdfUrl,
            responseType: "blob",
            headers: { }
          };

          return await axios(config)
        // let res = await axios(config)
        // console.log(res)
        // return await axios.get({
        //         url: pdfUrl,
        //         method: "GET",
        //         responseType: "blob",
        // })
    }

    async createCertificate(
        token_id,
        preview_image,
        name,
        author,
        year,
        edition,
        quantity,
        url,
        currency,
        contract,
        genre,
        dimensions,
        slug_link,
        copyrights,
        creation_date
    ) {
        let certResolve, certReject;
        let certCreate = new Promise(function (resolve, reject) {
            certResolve = resolve;
            certReject = reject;
        });

        let data = new FormData()
        data.append('preview_image', preview_image)
        data.append('name', name),
        data.append('author', author)
        data.append('year', year)
        data.append('edition', edition)
        data.append('quantity', quantity)
        data.append('url', url)
        data.append('currency', currency)
        data.append('contract', contract)
        data.append('genre', genre)
        data.append('dimensions', dimensions)
        data.append('slug_link', slug_link)
        copyrights.forEach((c, i) => {
            data.append('copyrights[' + i + ']', c)
        })
        data.append('token_id', token_id)
        data.append('creation_date', creation_date)
        
        console.log('CERT URL POST ' + CERT_HOST + '/' + CERT_API_URL);

        await axios.post(
            CERT_HOST + '/' + CERT_API_URL
            , data, {
            maxBodyLength: 'Infinity',
            headers: {
                'Accept': 'application/json',
                'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
            }
        }
        )
            .then(resp => {
                console.log(resp.data);
                certResolve(resp.data)
            })
            .catch(err => {
                certReject(err)
            })
        // certResolve("ok")
        await certCreate
        return certCreate

    }

    validateData(metadata) {
        const cd = moment(metadata.creation_date, 'DD/MM/YYYY HH:mm:ss')
        if (cd.isValid() && validate(metadata)) {
            return true
        } else {
            return false
        }
    }



}

export default new CertificatesApi()