<template>
  <div class="my-song-list-item" v-if="song.ieo === true">
    <div class="pending-ieo-list-item-header">

      <div class="pending-ieo-label" v-if="song.totalPlanned">
        <span class="pending-ieo-label-title">Total Planned:</span>
        <span class="pending-ieo-label-price" v-text="`€ ${totalPlanedWithSeparator}`"></span>
      </div>
      <img v-if="song.songAlbumUrlImage" :src="song.songAlbumUrlImage" alt="Author image"
           class="pending-ieo-list-item-image"
           onerror="this.onerror=null;this.src='https://static.djooky.fun/covers/covers/images_iOGJxAB.jpg';">
      <img v-else src="../../assets/img/default-song-image.jpg" alt="Author image"
           class="pending-ieo-list-item-image artist-list-item-image-default">
<!--      <img :src="song.songAlbumUrlImage" alt="Author image"-->
<!--           class="pending-ieo-list-item-image artist-list-item-image-default">-->
      <div class="pending-ieo-list-item__play-wrap">
        <div :class="togglePlayerFlag ?
                  'hitparad_button-play hitparad_button-pause'
                  : 'hitparad_button-play hitparad_button-play-active'" @click="toggleMusic">
          <div class="hitparad_button-play-progress">
            <canvas width="70" height="70"></canvas>
            <canvas width="70" height="70"></canvas>
          </div>
        </div>
      </div>
      <div class="audio-player-wrapper">
        <AudioPlayer
            :src="playerSrc"
            :togglePlayerFlag="togglePlayerFlag"
            :width="'100%'"
            v-if="playerSrc"
            :ref="`audioPlayer${identifier}${index}`"
            @audioPause="togglePlayerFlag = true"
            :identifier="identifier"
            :index="index"
        />
      </div>
    </div>
    <div class="pending-ieo-list-item-body">
      <div class="pending-ieo-list-item-body-top">
        <div class="song-author-image-wrap">
          <img v-if="song.authorPhotoUrl" :src="song.authorPhotoUrl" alt="song author image" class="song-author-image">
          <svg v-else xmlns="http://www.w3.org/2000/svg" width="30"
               height="30" viewBox="0 0 30 30"
               class="song-author-image">
            <path id="Path_190" data-name="Path 190"
                  d="M17,2A15,15,0,1,0,32,17,15.017,15.017,0,0,0,17,2Zm9.124,23.655a10.176,10.176,0,0,0-18.238.01A12.44,12.44,0,0,1,4.4,17a12.6,12.6,0,1,1,25.2,0,12.442,12.442,0,0,1-3.476,8.655ZM17,8a5.4,5.4,0,1,0,5.4,5.4A5.4,5.4,0,0,0,17,8Z"
                  transform="translate(-2 -2)" fill="#000"/>
          </svg>
        </div>
        <div class="song-author-info-top">
          <router-link :to="{path: `/song/${song.songId}`}" v-if="song.ieo === true"
                       class="pending-ieo-list-item-name-song-link">
                                                    <span class="pending-ieo-list-item-name-song">
                                                        <pre v-text="song.songTitle"></pre>
                                                    </span>
          </router-link>

          <span v-if="song.ieo === false" class="pending-ieo-list-item-name-song-link">
              <span class="pending-ieo-list-item-name-song"><pre v-text="song.songTitle"></pre></span>
            </span>

          <div class="pending-ieo-list-item-info">
            <router-link :to="{path: `/artist/${song.singerUsername}`}"
                         class="pending-ieo-list-item-name-artist-link">
                                                        <span class="pending-ieo-list-item-name-artist"
                                                              v-text="artistFullName"></span>
            </router-link>
          </div>
        </div>
        <div class="my-song-ieo-edit-btn-wrap">
          <div class="pending-ieo-subscribers-wrap" v-if="song.subscribedIeo === true">
            <svg width="22" height="14" viewBox="0 0 22 14"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                    fill="#FF0090"></path>
            </svg>
            <span class="pending-ieo-subscribers-title"
                  v-text="song.subscribers ? song.subscribers : '0'"></span>
          </div>
          <div class="pending-ieo-subscribers-wrap" v-if="song.subscribedIeo === false">
            <svg width="22" height="14" viewBox="0 0 22 14"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                    fill="#D0D0D0"></path>
            </svg>
            <span class="pending-ieo-subscribers-title"
                  v-text="song.subscribers ? song.subscribers : '0'"></span>
          </div>
        </div>

      </div>

      <div>
        <div>
          <div class="pending-ieo-list-item-body-bottom edit-ieo profile-ieo" v-if="song.status === 'NOT_STARTED'">
            <div class="pending-ieo-countdown-wrap" v-if="song.timeIeoStart">
              <span class="pending-ieo-countdown-title">Time to the IS start:</span>
              <div class="flip-wrap">
                <flip-countdown :deadline="dateToStart" v-if="flipFlag && dateToStart" :showSeconds="false"></flip-countdown>
              </div>
            </div>
            <div class="my-song-ieo-edit-btn-wrap">
              <div class="pending-ieo-subscribers-wrap"
                   v-if="song.subscribed == true">
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                        fill="#FF0090"></path>
                </svg>
                <span class="pending-ieo-subscribers-title"
                      v-text="song.subscribers ? song.subscribers : '0'"></span>
              </div>
              <div class="pending-ieo-subscribers-wrap"
                   v-if="song.subscribed == false">
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                        fill="#D0D0D0"></path>
                </svg>
                <span class="pending-ieo-subscribers-title"
                      v-text="song.subscribers ? song.subscribers : '0'"></span>
              </div>
              <!-- <a href="#"  class="pending-ieo-btn btn btn-hover">Edit IEO</a> -->
            </div>
          </div>
          <div class="pending-ieo-list-item-body-bottom failed-ieo" v-if="song.status === 'FAILED'">
            <div class="failed-ieo-price-wrap">
              <div class="collected-ieo-price-title">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M11.5789 0C5.18733 0 0 5.18733 0 11.5789C0 17.9704 5.18733 23.1577 11.5789 23.1577C17.9704 23.1577 23.1577 17.9704 23.1577 11.5789C23.1577 5.18733 17.9704 0 11.5789 0ZM11.5789 12.7367C10.942 12.7367 10.421 12.2157 10.421 11.5789V6.94732C10.421 6.31048 10.942 5.78943 11.5789 5.78943C12.2157 5.78943 12.7367 6.31048 12.7367 6.94732V11.5789C12.7367 12.2157 12.2157 12.7367 11.5789 12.7367ZM12.7367 17.3683H10.421V15.0525H12.7367V17.3683Z"
                        fill="#FFB817"/>
                </svg>
                <span class="winners-music-list-item-body-ieo-info">Initial Sale failed:</span>
              </div>
              <span class="collected-ieo-price">€ 0</span>
            </div>
            <div class="my-song-ieo-edit-btn-wrap">
              <div class="pending-ieo-subscribers-wrap"
                   v-if="song.subscribed == true">
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                        fill="#FF0090"></path>
                </svg>
                <span class="pending-ieo-subscribers-title"
                      v-text="song.subscribers ? song.subscribers : '0'"></span>
              </div>
              <div class="pending-ieo-subscribers-wrap"
                   v-if="song.subscribed == false">
                <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                        fill="#D0D0D0"></path>
                </svg>
                <span class="pending-ieo-subscribers-title"
                      v-text="song.subscribers ? song.subscribers : '0'"></span>
              </div>
            </div>
          </div>
          <div class="pending-ieo-list-item-body-bottom collected-ieo" v-if="song.status === 'SUCCESS'">
            <div class="collected-ieo-price-wrap">
              <div class="collected-ieo-price-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     viewBox="0 0 50 50">
                  <path class="a"
                        d="M25.084,0a25,25,0,1,0,25,25A25,25,0,0,0,25.084,0ZM36.665,18.084,24.69,36.387s-.009.006-.009.009a2.279,2.279,0,0,1-.175.287,2.246,2.246,0,0,1-.375.272c-.031.022-.063.047-.1.069a2.025,2.025,0,0,1-.613.256c-.072.019-.137.037-.212.05a2.11,2.11,0,0,1-.525.006,2.069,2.069,0,0,1-.366-.056,2.212,2.212,0,0,1-.359-.144,2.5,2.5,0,0,1-.3-.119,1.381,1.381,0,0,1-.166-.166c-.025-.022-.056-.028-.081-.05L14.44,30.356a2.187,2.187,0,1,1,2.969-3.213l5.047,4.669L33,15.691a2.188,2.188,0,0,1,3.663,2.394Z"
                        transform="translate(-0.084)"
                        fill="#7ac385"></path>
                </svg>
                <span class="winners-music-list-item-body-ieo-info">Successful:</span>
              </div>
              <span class="collected-ieo-price" v-text="`€ ${money}`"></span>
            </div>
            <div class="pending-ieo-subscribers-wrap"
                 v-if="song.subscribed == true">
              <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                      fill="#FF0090"></path>
              </svg>
              <span class="pending-ieo-subscribers-title"
                    v-text="song.subscribers"></span>
            </div>
            <div class="pending-ieo-subscribers-wrap"
                 v-if="song.subscribed == false">
              <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                      fill="#D0D0D0"></path>
              </svg>
              <span class="pending-ieo-subscribers-title"
                    v-text="song.subscribers"></span>
            </div>
          </div>
          <div class="pending-ieo-list-item-body-bottom collected-ieo" v-if="song.status === 'OPEN'">
            <div class="collected-ieo-price-wrap">
              <div class="collected-ieo-price-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     viewBox="0 0 50 50">
                  <path class="a"
                        d="M25.084,0a25,25,0,1,0,25,25A25,25,0,0,0,25.084,0ZM36.665,18.084,24.69,36.387s-.009.006-.009.009a2.279,2.279,0,0,1-.175.287,2.246,2.246,0,0,1-.375.272c-.031.022-.063.047-.1.069a2.025,2.025,0,0,1-.613.256c-.072.019-.137.037-.212.05a2.11,2.11,0,0,1-.525.006,2.069,2.069,0,0,1-.366-.056,2.212,2.212,0,0,1-.359-.144,2.5,2.5,0,0,1-.3-.119,1.381,1.381,0,0,1-.166-.166c-.025-.022-.056-.028-.081-.05L14.44,30.356a2.187,2.187,0,1,1,2.969-3.213l5.047,4.669L33,15.691a2.188,2.188,0,0,1,3.663,2.394Z"
                        transform="translate(-0.084)"
                        fill="#7ac385"></path>
                </svg>
                <span class="winners-music-list-item-body-ieo-info">Initial Sale open:</span>
              </div>
              <span class="collected-ieo-price" v-text="`€ ${money}`"></span>
            </div>
            <div class="pending-ieo-subscribers-wrap"
                 v-if="song.subscribed == true">
              <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                      fill="#FF0090"></path>
              </svg>
              <span class="pending-ieo-subscribers-title"
                    v-text="song.subscribers"></span>
            </div>
            <div class="pending-ieo-subscribers-wrap"
                 v-if="song.subscribed == false">
              <svg width="22" height="14" viewBox="0 0 22 14" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"
                      fill="#D0D0D0"></path>
              </svg>
              <span class="pending-ieo-subscribers-title"
                    v-text="song.subscribers"></span>
            </div>
          </div>
          <div class="pending-ieo-list-item-body-bottom pay-awaiting" v-if="song.status === 'PAY_AWAITING'">
            <div class="collected-ieo-price-wrap">
              <div class="collected-ieo-price-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                     viewBox="0 0 50 50">
                  <path class="a"
                        d="M25.084,0a25,25,0,1,0,25,25A25,25,0,0,0,25.084,0ZM36.665,18.084,24.69,36.387s-.009.006-.009.009a2.279,2.279,0,0,1-.175.287,2.246,2.246,0,0,1-.375.272c-.031.022-.063.047-.1.069a2.025,2.025,0,0,1-.613.256c-.072.019-.137.037-.212.05a2.11,2.11,0,0,1-.525.006,2.069,2.069,0,0,1-.366-.056,2.212,2.212,0,0,1-.359-.144,2.5,2.5,0,0,1-.3-.119,1.381,1.381,0,0,1-.166-.166c-.025-.022-.056-.028-.081-.05L14.44,30.356a2.187,2.187,0,1,1,2.969-3.213l5.047,4.669L33,15.691a2.188,2.188,0,0,1,3.663,2.394Z"
                        transform="translate(-0.084)"
                        fill="#7ac385"></path>
                </svg>
                <span class="winners-music-list-item-body-ieo-info">Pay awaiting</span>
              </div>
<!--              <span class="collected-ieo-price" v-text="`€ ${song.money}`"></span>-->
            </div>
            <button class="btn btn-hover" @click="$router.push({path: `/profile/ieo-creating/${song.songId}?status=${song.status}`})">Pay</button>
<!--            <div class="pending-ieo-subscribers-wrap"-->
<!--                 v-if="song.subscribed === true">-->
<!--              <svg width="22" height="14" viewBox="0 0 22 14" fill="none"-->
<!--                   xmlns="http://www.w3.org/2000/svg">-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                      d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"-->
<!--                      fill="#FF0090"></path>-->
<!--              </svg>-->
<!--              <span class="pending-ieo-subscribers-title"-->
<!--                    v-text="song.subscribers"></span>-->
<!--            </div>-->
<!--            <div class="pending-ieo-subscribers-wrap"-->
<!--                 v-if="song.subscribed === false">-->
<!--              <svg width="22" height="14" viewBox="0 0 22 14" fill="none"-->
<!--                   xmlns="http://www.w3.org/2000/svg">-->
<!--                <path fill-rule="evenodd" clip-rule="evenodd"-->
<!--                      d="M15 6C16.66 6 17.99 4.66 17.99 3C17.99 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 4.66 13.34 6 15 6ZM7 6C8.66 6 9.99 4.66 9.99 3C9.99 1.34 8.66 0 7 0C5.34 0 4 1.34 4 3C4 4.66 5.34 6 7 6ZM7 8C4.67 8 0 9.17 0 11.5V13C0 13.55 0.45 14 1 14H13C13.55 14 14 13.55 14 13V11.5C14 9.17 9.33 8 7 8ZM15 8C14.71 8 14.38 8.02 14.03 8.05C14.05 8.06 14.06 8.08 14.07 8.09C15.21 8.92 16 10.03 16 11.5V13C16 13.35 15.93 13.69 15.82 14H21C21.55 14 22 13.55 22 13V11.5C22 9.17 17.33 8 15 8Z"-->
<!--                      fill="#D0D0D0"></path>-->
<!--              </svg>-->
<!--              <span class="pending-ieo-subscribers-title"-->
<!--                    v-text="song.subscribers"></span>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlipCountdown from 'vue2-flip-countdown'
import moment from 'moment';
import numberFormattingDecimal from "../../mixins/numberCurrencyConverter";
import AudioPlayer from "@/components/AudioPlayer";

export default {
  name: "IeosList",
  props: {
    song: {
      type: Object
    },
    flipFlag: {
      type: Boolean
    },
    current: {
      type: String
    },
    index: {
      type: Number
    },
    identifier: {
      type: String
    }
  },
  data() {
    return {
      currencyName: process.env.VUE_APP_CURRENCY_NAME_SHORT,
      artistFullName: '',
      dateToStart: null,
      money: null,
      totalPlanedWithSeparator: null,
      togglePlayerFlag: true,
      mp3: '',
      playerSrc: null,
    }
  },
  mixins: [numberFormattingDecimal],
  methods: {
    toggleMusic() {
      if (this.togglePlayerFlag) {
        this.togglePlayerFlag = false
        this.$emit('playingMusic', this.mp3)
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      } else {
        this.togglePlayerFlag = true
        this.$refs[`audioPlayer${this.identifier}${this.index}`].toggleMusic()
      }
    },
    createFullName() {
      this.artistFullName = this.song.singerFirstName + ' ' + this.song.singerLastName
    },
  },
  watch: {
    'current'() {
      if (this.current !== this.mp3) {
        this.$refs[`audioPlayer${this.identifier}${this.index}`].refreshSong()
        this.togglePlayerFlag = true
      }
    }
  },
  mounted() {
    this.createFullName()
    this.mp3 = this.song.songUrlFile
    this.playerSrc = this.mp3
    let convertTimeToLocal = moment.utc(this.song.timeIeoStart).local()
    let resultTimeDate = moment(String(convertTimeToLocal)).format('yyyy-MM-DD HH:mm')
    this.dateToStart = resultTimeDate

    this.totalPlanedWithSeparator = this.numberFormattingDecimal(this.song.totalPlanned)
    this.money = this.numberFormattingDecimal(this.song.money)
  },
  beforeDestroy() {
    this.togglePlayerFlag = true
  },
  components: {FlipCountdown,AudioPlayer}
}
</script>

<style scoped>
.flip-wrap {
  height: 40px;
}
.pending-ieo-list-item-body-bottom.pay-awaiting {
  flex-direction: column;
  align-items: flex-start;
}
.pending-ieo-list-item-body-bottom.pay-awaiting .collected-ieo-price-wrap {
  margin-bottom: 20px;
}
.pending-ieo-list-item-body-bottom.pay-awaiting .btn {
  width: 100%;
}

.audio-player-wrapper {
  height: 45px;
  color: #FFFFFF;
}
</style>