<template>
  <div id="my-content" style="height: 100%">
    <Header/>

    <main id="root" style="height: 100%">
      <div class="profile" :style="{height: '100%', paddingTop: '88px'}">
        <Sidebar v-if="isLogged && showSidebar"/>

        <section
            class="section profile-content"
            style="height: 100%; padding: 0; margin-right: 0; width: 100%"
            :class="{ 'profile-content--full': !showSidebar }"
        >
          <!-- style="height: 110%; padding: 85px 0 0px 0"
          src="https://licensing-testnet.v-art.digital/"
         src="http://localhost:8080/" -->

          <!--iframe
              src="https://nft.djookyx.com/"
              height="100%"
              style="border-width: 0px; margin-left: 0px; width: calc(100%)"
              @load="finishLoading"
          >
          </iframe-->

          <iframe
            :src="iframe"
            height="100%"
            style="border-width: 0px; margin-left: 0px; width: calc(100%)"
            @load="finishLoading"
          >
          </iframe>
        </section>
      </div>
    </main>

    <Footer/>
  </div>
</template>

<script>
import Header from "../components/header/Header";
import vArtNearApi from "./../helpers/v-art-protocol-near-sdk";
import myMixin from "../mixins/vArtNearProtocol";
import Sidebar from "../components/profile/Sidebar.vue";
import Footer from "../components/footer/Footer";
import {mapGetters} from "vuex";
import concordiumAPI from "../helpers/concordium";
import {CIS_CONTRACT_ADDRESS} from "../Constants";

export default {
  components: {
    Header,
    Footer,
    Sidebar,
  },
  mixins: [myMixin],
  data() {
    return {
      account: null,
      showSidebar: false,
      iframe: process.env.VUE_APP_IFRAME_URL,
    };
  },
  created() {
    this.getUserAccount();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.checkRouter(to.path);
    });
  },
  computed: {
    ...mapGetters({
      isLogged: 'authentication/isLogged',
    }),
  },
  methods: {
    sendRouteMessage(name) {
      const params = {
        name,
      };

      if (
          name == "buyer-nft" ||
          name == "buyer-license" ||
          name == "buyer-my-license" ||
          name == "minter-nft" ||
          name == "minter-license-preview"
      ) {
        params.params = this.$route.params;

        console.log(params.params, "params.params Market");
      }

      window.frames[0].postMessage(
          {
            name: "changeRouteIframe",
            params,
          },
          "*"
      );
    },
    async checkRouter(routeName) {

      routeName.includes("nft-marketplace") || routeName.includes("explorer") ? this.showSidebar = false : this.showSidebar = true

      if (!this.account) {
        await this.checkUserAccount();
        if (!this.account)
          return;
      }

      for (let i = 0; i < this.ROUTES_NAMES.length; i++) {
        if (routeName.includes(this.ROUTES_NAMES[i].route_name)) {
          this.sendRouteMessage(this.ROUTES_NAMES[i].iframe_name);
          break;
        }
      }

      // const routes = {
      //   nfts: "minter-nfts",
      //   explorer: "buyer-nft",
      //   offers: "minter-offers",
      //   licenses: "buyer-licenses",
      //   "nft-marketplace": "buyer-explorers",
      // };

      // for (let key in routes) {
      //   if (routeName.includes(key)) {
      //     this.sendRouteMessage(routes[key]);
      //     break;
      //   }
      // }

    },
    finishLoading() {
      console.log("finishLoading 1", this.account);
      setTimeout(() => {
        console.log("finishLoading 2", this.account);
        if (this.account) {
          window.frames[0].postMessage(
                  {
                    name: "signInConfirm",
                    params: {
                      account: localStorage.web3_account,
                      network: localStorage.web3_network,
                      contract: CIS_CONTRACT_ADDRESS.index.toString(),
                    }
                  },
                  "*"
          );

          this.checkRouter(this.$route.path);
        }
      }, 2500)
    },
    async getUserAccount() {
      try {
        await vArtNearApi.initContract(
            "djookyvart.near",
            "nft.djookyvart.near",
            "market.djookyvart.near",
            "license.djookyvart.near",
            "mainnet",
            "https://licensing-djooky-mainnet.v-art.digital:5200"
        );

        await vArtNearApi.initGraph(
            "https://api.thegraph.com/subgraphs/name/ctosoftuup/djookyx-mainnet"
        );

        await vArtNearApi.initIpfs(
            "544fa186c3fd4aa39e8e",
            "7f1d4747551777aa7428a6cf29a0a6e582e827db7bbb3eed1e8aced8d18cc82d",
            "https://vart.mypinata.cloud/ipfs/"
        );

        // await vArtNearApi.initContract(
        //   "qavart.testnet",
        //   "nft.qavart.testnet",
        //   "market.qavart.testnet",
        //   "license.qavart.testnet",
        //   "testnet",
        //   "https://licensing-djooky-testnet.v-art.digital:3000"
        // );

        // await vArtNearApi.initGraph(
        //   "https://api.thegraph.com/subgraphs/name/ctosoftuup/qavartnear"
        // );

        // await vArtNearApi.initIpfs(
        //   "544fa186c3fd4aa39e8e",
        //   "7f1d4747551777aa7428a6cf29a0a6e582e827db7bbb3eed1e8aced8d18cc82d",
        //   "https://vart.mypinata.cloud/ipfs/"
        // );

        await this.checkUserAccount();

      } catch (error) {
        console.log("Initialization error: ", error);
      }
    },
    async checkUserAccount() {
      try {
        let account;
        if(localStorage.web3_network && localStorage.web3_network == "near") {
          account = vArtNearApi.getAccount();
          localStorage.web3_account = account;
        }
        else if(localStorage.web3_network && localStorage.web3_network == "concordium") {
          await concordiumAPI.concordConnect();
          account = await concordiumAPI.concordGetConnectedAccount();
          localStorage.web3_account = account;
        }else{
          localStorage.web3_account = "";
          localStorage.web3_network = "";
        }

        console.log("getUserAccount", account);

        if (account) this.account = account;
      } catch (error) {
        console.log("checkUserAccount error: ", error);
      }
    },
  },
  mounted() {

  }
};
</script>
