export default function (instance) {
  return {
    getPrimary(params) {
      return instance.get('/api/v2/contract/pm', {params})
    },
    getSecondary(params) {
      return instance.get('/api/v2/contract/sm', {params})
    },
    initialContract(params) {
      return instance.post('/api/v2/contract/initialContract', null, {params})
    },
    getContractData(params) {
      return instance.get('/api/v2/contract/data', {params})
    },
    getContractType(params) {
      return instance.get(`/api/v2/contract/type/${params}`)
    },
  }
}