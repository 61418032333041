export default function(instance) {
  return {
    signIn(payload) {
      return instance.post('/api/v2/login', payload)
    },
    signInWithDjkAwards(payload) {
      return instance.post('api/v2/login/djookyawards/profile', payload)
    },
    signUp(payload) {
      return instance.post('/api/v2/sign-up', payload)
    },
    logout() {
      return instance.post('/api/v2/logout')
    },
    confirm(body) {
      return instance.post('/api/v2/confirm', body)
    },
    dispatch(body) {
      return instance.post('/api/v2/dispatch', body)
    },
    // getCountries() {
    //   return instance.get('/api/v2/countries')
    // }
  }
}